<template>
  <div class="candidate-profile">
    <LoadingOverlay v-if="!isGDPRStatement" />
    <div v-else class="GDPR-Statement">
      <div v-if="cardError"></div>
      <div class="pb-1 cardError" :class="cardHasError ? 'card-error-msg' : ''">
        {{ cardHasError }}
      </div>
      <CCard
        class="mt-2 has-loading-overlay"
        :class="cardHasError ? 'card-has-error' : ''"
      >
        <CCardBody>
          <CRow>
            <div class="col-md-12">
              <div class="d-flex justify-content-between">
                <div class="mt-2 ml-2">
                  <h5 class="main-title pb-3">Privacy & Terms</h5>
                </div>
                <div class="d-flex justify-content-end">
                  <button
                    name="save"
                    class="hand-icon action-button"
                    v-c-tooltip="'Save'"
                    @click="updateGDPRStatement"
                  >
                    <CIcon
                      name="cil-save"
                      class="active"
                      style="margin-right: 20px"
                    />
                  </button>
                  <button
                    name="cancel"
                    class="hand-icon action-button ml-2"
                    v-if="getMultiFormEdited['personalInformation']"
                    v-c-tooltip="'Cancel'"
                    @click="resetGDPRStatementData(), clearFields()"
                  >
                    <CIcon name="cil-x-circle" style="margin-right: 20px" />
                  </button>
                </div>
              </div>
              <ValidationObserver ref="gdprStatement">
                <form id="gdprStatement" name="gdprStatement">
                  <fieldset>
                    <CRow>
                      <CCol lg="6">
                        <CRow class="mb-3">
                          <label
                            name="lbl_subscription_preferences"
                            class="col-lg-12 col-md-12 text-primary required"
                            >Subscription Preferences</label
                          >
                          <div
                            class="col-lg-12 col-md-12 col-sm-12 mt-2 mb-2 checkbox-group required"
                            style="font-weight: 500"
                          >
                            <ValidationProvider
                              rules="required"
                            >
                              <input
                                name="subscription_preferences"
                                type="checkbox"
                                :checked="profile.subscription_preferences"
                                @change="
                                  handleChangeCheck(
                                    'subscription_preferences',
                                    $event
                                  )
                                "
                                class="pr-1 scale-1_25"
                              />
                              Yes, you may send me email, Whatsapp and SMS
                              messages with the following content :
                            </ValidationProvider>
                          </div>
                        </CRow>
                        <div class="mb-3">
                          <CRow class="border-top border-bottom pt-1">
                            <label class="col-8">New Job Opportunities</label>
                            <div class="col-4">
                              <CSwitch
                                name="new_job_opportunities"
                                color="success"
                                :checked="profile.new_job_opportunities"
                                :disabled="!isSubscriptionChecked"
                                shape="pill"
                                variant="3d"
                                @change.native="
                                  handleChangeCheck(
                                    'new_job_opportunities',
                                    $event
                                  )
                                "
                              />
                            </div>
                          </CRow>
                          <CRow class="border-bottom pt-1">
                            <label class="col-8"
                              >Urgent Messages and Alerts</label
                            >
                            <div class="col-4">
                              <CSwitch
                                name="urgent_messages_and_alerts"
                                color="success"
                                :checked="profile.urgent_messages_and_alerts"
                                :disabled="!isSubscriptionChecked"
                                shape="pill"
                                variant="3d"
                                @change.native="
                                  handleChangeCheck(
                                    'urgent_messages_and_alerts',
                                    $event
                                  )
                                "
                              />
                            </div>
                          </CRow>
                          <CRow class="border-bottom pt-1">
                            <label class="col-8"
                              >Invitations relating to Jobs</label
                            >
                            <div class="col-4">
                              <CSwitch
                                name="invitations_relating_to_jobs"
                                color="success"
                                :checked="profile.invitations_relating_to_jobs"
                                :disabled="!isSubscriptionChecked"
                                shape="pill"
                                variant="3d"
                                @change.native="
                                  handleChangeCheck(
                                    'invitations_relating_to_jobs',
                                    $event
                                  )
                                "
                              />
                            </div>
                          </CRow>
                          <CRow class="border-bottom pt-1">
                            <label class="col-8"
                              >Promotions & Press Releases</label
                            >
                            <div class="col-4">
                              <CSwitch
                                name="promotions_and_press_releases"
                                color="success"
                                :checked="profile.promotions_and_press_releases"
                                :disabled="!isSubscriptionChecked"
                                shape="pill"
                                variant="3d"
                                @change.native="
                                  handleChangeCheck(
                                    'promotions_and_press_releases',
                                    $event
                                  )
                                "
                              />
                            </div>
                          </CRow>
                        </div>
                      </CCol>
                      <CCol lg="6">
                        <CRow class="mb-3">
                          <label
                            name="lbl_display_data"
                            class="col-lg-12 col-sm-12 col-md-12"
                            >How did you hear about us?</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <Select
                              name="display_data_id"
                              :value="profile.display_data_id"
                              @change="handleChangeSelect"
                              :options="
                                options && options['displayData']
                                  ? options['displayData']
                                  : []
                              "
                              :taggable="false"
                              :multiple="false"
                              :clearable="true"
                            />
                          </div>
                        </CRow>
                        <CRow class="mb-3">
                          <label
                            name="lbl_display_data"
                            class="col-lg-12 col-sm-12 col-md-12"
                            >How did we do?</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <Rating
                              name="our_rating"
                              :isHalfstar="true"
                              :value="profile.our_rating"
                              @change="handleChangeRating"
                            ></Rating>
                          </div>
                        </CRow>
                        <CRow class="mb-3">
                          <label
                            name="lbl_display_data"
                            class="col-lg-12 col-sm-12 col-md-12"
                            >What could we do better?</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <TextInput
                              name="do_better"
                              :value="profile.do_better"
                              @input="handleInput"
                            />
                          </div>
                        </CRow>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol lg="6">
                        <CRow class="mb-3">
                          <label
                            name="lbl_applied_to_other_agencies"
                            class="col-lg-7 col-md-12"
                            >Applied to other agencies</label
                          >
                          <div class="col-lg-5 col-md-12 col-sm-12">
                            <RadioButton
                              name="applied_to_other_agencies"
                              :value="profile.applied_to_other_agencies"
                              :options="
                                options && options['applied_to_other_agencies']
                                  ? options['applied_to_other_agencies']
                                  : []
                              "
                              @change="handleChangeRadio"
                            />
                          </div>
                        </CRow>
                      </CCol>
                      <CCol lg="6"> </CCol>
                    </CRow>
                    <CRow>
                      <CCol md="6">
                        <CRow class="mb-3">
                          <label name="lbl_subscribe" class="col-7"
                            >UnSubscribe</label
                          >
                          <div class="col-5">
                            <CSwitch
                              name="subscribed"
                              color="success"
                              :checked="profile.subscribed"
                              shape="pill"
                              variant="3d"
                              @change.native="
                                handleChangeCheck('subscribed', $event)
                              "
                            />
                          </div>
                        </CRow>
                      </CCol>
                      <CCol md="6">
                        <CRow class="mb-3">
                          <label name="lbl_marketing" class="col-lg-6 col-md-12"
                            >Delete My Account</label
                          >
                          <div class="col-lg-6 col-md-12 col-sm-12">
                            <RadioButton
                              name="delete_my_account"
                              :value="profile.delete_my_account"
                              :options="
                                options && options['deleteAccount']
                                  ? options['deleteAccount']
                                  : []
                              "
                              label="delete_my_account"
                              @change="handleChangeRadio"
                            />
                          </div>
                        </CRow>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol lg="6">
                        <a href="javascript:void(0)" @click="showTermPopup"
                          >Privacy Statement</a
                        >
                      </CCol>
                    </CRow>
                  </fieldset>
                </form>
              </ValidationObserver>
            </div>
          </CRow>
        </CCardBody>
      </CCard>
      <PreviewModal
        :modalTitle="previewModal.modalTitle"
        :modalColor="previewModal.modalColor"
        :isShowPopup="previewModal.isShowPopup"
        :buttons="previewModal.buttons"
        :modalCallBack="modalCallBack"
        :isPreview="previewModal.isPreview"
        :iframe="iframe"
        :size="previewModal.size"
        :closeOnBackdrop="previewModal.closeOnBackdrop"
      />
    </div>
  </div>
</template>
<script>
import ProfileSection from "./ProfileSection";
import { mapActions, mapGetters } from "vuex";
import Vue from "vue";
import PreviewModal from "@/components/reusable/PreviewModal";
import pdf from "vue-pdf";
import Rating from "@/components/reusable/Fields/Rating";
export default {
  mixins: [ProfileSection],
  components: {
    PreviewModal,
    Rating,
  },
  data() {
    return {
      payload: {},
      profile: {},
      previewModal: {
        modalColor: "primary",
        modalTitle: "Terms and Privacy Policy",
        isShowPopup: false,
        buttons: ["Close"],
        isPreview: false,
        size: "lg",
        closeOnBackdrop: false,
      },
      iframe: {
        src: "",
        loaded: false,
        isImg: false,
        pdf: {
          ispdf: true,
          src: "",
          numPages: undefined,
          addContentClasses: "",
        },
      },
      errors: [],
      cardHasError: "",
      errormsg: false,
      onMount: false
    };
  },
  computed: {
    ...mapGetters(["candidateProfile"]),
    isSubscriptionChecked() {
      return this.profile.subscription_preferences
    },
    isGDPRStatement() {
      if (Object.keys(this.candidateProfile).length) {
        return this.setGDPRStatementData(this.candidateProfile);
      }
      return false;
    },
    cardError() {
      if (this.errormsg && this.getMultiFormEdited.showError) {
        this.cardHasError = "Please fill Subscription Preferences";
        let el = this.$el.getElementsByClassName("cardError")[0]
        el.scrollIntoView({block: 'center'})
        return true;
      }
      if (this.errormsg && this.getMultiFormEdited.showError ) {
        let el = this.$el.getElementsByClassName("cardError")[0]
        el.scrollIntoView({block: 'center'})
        this.errormsg = false
        return true;
      }
      if (
        this.getMultiFormEdited.showError &&
        this.getMultiFormEdited.personalInformation &&
        this.onMount
      ) {
        this.cardHasError = null;
        this.onMount = false;
        return false;
      }
      if (
        this.getMultiFormEdited.showError &&
        this.getMultiFormEdited.personalInformation &&
        !this.onMount
      ) {
        this.cardHasError = "Please Save or Delete";
        let el = this.$el.getElementsByClassName("cardError")[0];
        // el.scrollIntoView(true);
        el.scrollIntoView({block: 'center'})
        // window.scrollBy(0, -200);
        return true;
      }
      return false;
    },
    options() {
      return {
        applied_to_other_agencies: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
        displayData: this.getDisplayData || [],
        subscribed: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
          { id: null, label: "N/A" },
        ],
        deleteAccount: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
      };
    },
  },
  watch: {
    "profile.subscription_preferences"() {
      if (!this.profile.subscription_preferences) {
        this.errormsg = true;
      } else {
        this.errormsg = false;
      }
    },
    "profile.new_job_opportunities"() {
      this.checkAllSubcriptions()
    },
    "profile.urgent_messages_and_alerts"() {
      this.checkAllSubcriptions()
    },
    "profile.invitations_relating_to_jobs"() {
      this.checkAllSubcriptions()
    },
    "profile.promotions_and_press_releases"() {
      this.checkAllSubcriptions()
    }
  },
  mounted() {
    if(!this.profile.subscription_preferences) {
      this.onMount= true;
    } else this.onMount = false;
  },
  updated() {
    if (!this.profile.subscription_preferences) {
      const isValid = this.$refs.gdprStatement.validate().then((res) => {
        res ? null : this.multiFormEdited({ fieldValidateError: true });
      });
    } else {
      this.multiFormEdited({ fieldValidateError: false })
    }
  },
  methods: {
    ...mapActions(["updateCandidate", "fetchUser"]),
    checkAllSubcriptions() {
      if (!this.profile.new_job_opportunities 
      && !this.profile.urgent_messages_and_alerts 
      && !this.profile.invitations_relating_to_jobs 
      && !this.profile.promotions_and_press_releases) {
        this.profile.subscription_preferences = false;
      }
    },
    setGDPRStatementData(profileData) {
      this.profile = {
        customer_uid: profileData?.customer_uid,
        candidate_uid: profileData?.candidate_uid,
        subscribed: profileData?.subscribed || false,
        marketing: profileData?.marketing,
        applied_to_other_agencies: profileData?.applied_to_other_agencies,
        display_data_id:
          profileData.display_data_id != null
            ? {
                code: profileData.display_data.display_data_id,
                label: profileData.display_data.display_data,
              }
            : null,
        subscription_preferences: profileData.subscription_preferences,
        new_job_opportunities: profileData.new_job_opportunities,
        urgent_messages_and_alerts: profileData.urgent_messages_and_alerts,
        invitations_relating_to_jobs: profileData.invitations_relating_to_jobs,
        promotions_and_press_releases:
          profileData.promotions_and_press_releases,
        our_rating: profileData.our_rating,
        do_better: profileData.do_better,
        delete_my_account: profileData.delete_my_account || false,
        privacy_acceptance: profileData.privacy_acceptance,
      };
      return this.profile;
    },
    async updateGDPRStatement() {
      this.$emit("tooltipHide");
      if (!this.profile.subscription_preferences) {
        this.cardHasError = "Please fill Subscription Preferences";
        this.errormsg = true;
        return;
      }
      let payload = {
        customer_uid: this.profile?.customer_uid,
        candidate_uid: this.profile?.candidate_uid,
        subscribed: this.profile?.subscribed,
        marketing: this.profile?.marketing,
        applied_to_other_agencies: this.profile?.applied_to_other_agencies,
        display_data_id: this.profile.display_data_id?.code,
        subscription_preferences: this.profile.subscription_preferences,
        new_job_opportunities: this.profile.new_job_opportunities,
        urgent_messages_and_alerts: this.profile.urgent_messages_and_alerts,
        invitations_relating_to_jobs: this.profile.invitations_relating_to_jobs,
        promotions_and_press_releases: this.profile
          .promotions_and_press_releases,
        our_rating: this.profile.our_rating,
        do_better: this.profile.do_better,
        delete_my_account: this.profile.delete_my_account,
        privacy_acceptance: this.profile.privacy_acceptance,
      };
      this.saveGDPRStatement(payload);
    },
    saveGDPRStatement(data) {
      const { customer_uid } = this.profile;
      let appendAction = this.updateCandidate({ data, customer_uid });
      Promise.all([appendAction]).then((res) => {
        this.fetchUser();
        this.clearFields();
        let el = this.$parent.$el.getElementsByClassName("border-top")[0]
        el.scrollIntoView({iniline: 'end'})
      });
    },
    handleChangeCheck(name, $event) {
      const checked = $event.target.checked;
      Vue.set(this.profile, name, checked);
      this.payload = {
        ...this.payload,
        [name]: checked,
      };
      this.multiFormEdited({ personalInformation: true });
      if(name == 'subscription_preferences' && checked) {
        if (this.profile.subscription_preferences === true) {
          this.profile.subscription_preferences = this.profile.new_job_opportunities = this.profile.urgent_messages_and_alerts  = this.profile.promotions_and_press_releases = this.profile.invitations_relating_to_jobs = true;
        }
      } else if(name == 'subscription_preferences' && !checked) {
        if (this.profile.subscription_preferences === false) {
          this.profile.subscription_preferences = this.profile.new_job_opportunities = this.profile.urgent_messages_and_alerts  = this.profile.promotions_and_press_releases = this.profile.invitations_relating_to_jobs = false;
        } 
      }
    },
    showTermPopup() {
      let data = {
        document_ext: "PDF",
        link: "/doc/Talentfind_privacy_policy.pdf",
      };
      if (data.document_ext === "PDF") {
        this.iframe.isImg = false;
        this.iframe.loaded = false;
        this.iframe.pdf.ispdf = true;
        this.iframe.pdf.src = pdf.createLoadingTask(data.link);
        setTimeout(() => {
          this.iframe.pdf.src.promise.then((pdf) => {
            this.iframe.pdf.numPages = pdf.numPages;
          });
        }, 100);
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      }
    },
    modalCallBack(action) {
      this.previewModal.isPreview = false;
      this.previewModal.isShowPopup = false;
    },
    handleChangeRating(name, value) {
      Vue.set(this.profile, name, value);
      this.payload = {
        ...this.payload,
        [name]: value,
      };
      this.multiFormEdited({ personalInformation: true });
    },
    clearFields() {
      this.$nextTick(() => {
        this.$refs.gdprStatement?.errors.clear;
        this.$refs.gdprStatement?.reset();
      });
      this.cardHasError = "";
      this.multiFormEdited({ personalInformation: false });
      this.multiFormEdited({ showError: false });
      this.$emit("tooltipHide");
    },
    resetGDPRStatementData() {
      this.setGDPRStatementData(this.candidateProfile);
    },
  },
};
</script>

<template>
  <div class="candidate-profile">
    <div class="Specialization-info">
      <div v-if="cardError"></div>
      <div class="pb-1" :class="cardHasError ? 'card-error-msg' : ''">
        {{ cardHasError }}
      </div>
      <CCard
        class="mt-2 has-loading-overlay"
        :class="cardHasError ? 'card-has-error' : ''"
      >
        <CCardBody>
          <CRow>
            <div class="col-md-12">
              <div class="d-flex justify-content-between">
                <div class="mt-2">
                  <h5 class="main-title pb-3">
                    Specialization<span class="h6">
                      [{{ nurseSpeciality.length || "0" }} record{{
                        nurseSpeciality.length > 1 ? "s" : ""
                      }}]</span
                    >
                  </h5>
                </div>
              </div>
              <ValidationObserver
                ref="specialization"
                v-slot="{ handleSubmit }"
              >
                <form id="specialization" @submit.prevent="handleSubmit()">
                  <fieldset :disabled="isDisabled">
                    <CRow>
                      <CCol md="6">
                        <CRow class="row mb-3">
                          <label
                            name="lbl_speciality_type_id"
                            class="col-lg-12 col-md-12 required"
                            >{{
                              isNurse
                                ? `Other Areas of Expertise`
                                : isAHP && edit_speciality_id
                                  ? `Speciality`
                                  : `Additional Specialities`
                            }}</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <Select
                                name="speciality_id"
                                :value="profile.speciality_id"
                                @input="handleChangeSelect"
                                :options="
                                  options && options['speciality_id']
                                    ? options['speciality_id']
                                    : []
                                "
                                :taggable="false"
                                :multiple="false"
                                :clearable="false"
                                :disabled="isDisabled"
                                :error="errors[0]"
                              />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                      <CCol lg="6">
                        <CRow class="row mb-3">
                          <label
                            name="lbl_nurse_yrs_exp"
                            class="col-lg-12 col-md-12 required"
                            >Number of Years Experience({{
                              profile.experience_in_years || 0
                            }}
                            yrs)</label
                          >
                          <div
                            class="col-lg-12 col-md-12 col-sm-12 experience_in_years"
                          >
                            <v-slider
                              name="experience_in_years"
                              :min="0"
                              :max="20"
                              :value="profile.experience_in_years"
                              v-model="profile.experience_in_years"
                              @change="handleSliderYrsExp"
                            ></v-slider>
                          </div>
                        </CRow>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol md="6">
                        <CRow
                          class="row mb-3"
                          v-if="
                            profile.speciality_id
                              ? profile.speciality_id.label ==
                                'Other - not listed'
                                ? true
                                : false
                              : false
                          "
                        >
                          <label
                            name="lbl_other_speciality"
                            class="col-lg-12 col-md-12"
                            >Name Speciality</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <TextInput
                              name="other_speciality"
                              :value="profile.other_speciality"
                              @change="handleInput"
                            />
                          </div>
                        </CRow>
                      </CCol>
                    </CRow>
                    <CRow v-if="isAHP && options['sub_speciality_id'].length">
                      <CCol md="6">
                        <CRow class="row mb-3">
                          <label class="col-lg-12 col-md-12"
                            >Sub Type / License</label
                          >
                          <div class="col infoicon-multi-select">
                            <Select
                              name="sub_speciality_id"
                              @input="handleChangeSelect"
                              :value="profile.sub_speciality_id"
                              :options="
                                options && options['sub_speciality_id']
                                  ? options['sub_speciality_id']
                                  : []
                              "
                              option_label="label"
                              :taggable="false"
                              :multiple="false"
                              :clearable="true"
                            />
                          </div>
                        </CRow>
                      </CCol>
                    </CRow>
                    <CRow class="mb-3">
                      <CCol md="6">
                        <CRow class="row mb-3">
                          <label name="lbl_is_main" class="col-lg-12 col-md-12"
                            >Is this your main Speciality?</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <RadioButton
                              name="main"
                              :value="profile.main"
                              :options="
                                options && options['boolean']
                                  ? options['boolean']
                                  : []
                              "
                              @change="handleChangeRadio"
                              :disabled="profile.is_main"
                              :error="errors[0]"
                            />
                          </div>
                        </CRow>
                      </CCol>
                      <!-- <CCol md="6">
                        <CRow class="row mb-3">
                          <label
                            name="lbl_is_current"
                            class="col-lg-12 col-md-12"
                            >Is this your current job?</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <RadioButton
                              name="is_current"
                              :value="profile.is_current"
                              :options="
                                options && options['boolean']
                                  ? options['boolean']
                                  : []
                              "
                              @change="handleChangeRadio"
                              :error="errors[0]"
                            />
                          </div>
                        </CRow>
                      </CCol> -->
                    </CRow>
                    <!-- <CRow>
                      <CCol md="6"></CCol>
                      <CCol md="6" v-if="!profile.is_current">
                        <CRow>
                          <label class="col-lg-12 col-md-12">
                            Date Last Practice
                          </label>
                          <div class="col-lg-12 col-md-126 col-sm-12">
                            <ValidationProvider
                              name="dob"
                              rules="date_validate|no_future_date"
                              v-slot="{ errors }"
                            >
                              <DateInput
                                :onlyMonthAndYear="true"
                                name="last_practiced"
                                :value="profile.last_practiced"
                                @input="handleDatePickerChange"
                                :error="errors[0]"
                              />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                    </CRow> -->

                    <!-- <CButton
                      v-if="profile.speciality_id"
                      type="button"
                      class="btn-primary rounded-0 small"
                      @click="showQualificationModal()"
                    >
                      Link Qualification
                    </CButton> -->
                    <CRow class="mt-2">
                      <CCol md="6"></CCol>
                      <CCol md="6">
                        <div class="float-right mb-2">
                          <CButton
                            v-if="
                              !edit_speciality_id &&
                              getMultiFormEdited['specializationInformation']
                            "
                            class="btn-primary small mr-2"
                            style="height: 28px"
                            @click="updateSpecializationInfo"
                            v-c-tooltip="
                              'Click to Add multiple records!'
                            "
                            >Add</CButton
                          >
                          <CButton
                            class="btn-primary small mr-1"
                            style="height: 28px"
                            @click="updateSpecializationInfo"
                            v-if="edit_speciality_id"
                            v-c-tooltip="
                              'Click to update the record!'
                            "
                            >Update</CButton
                          >
                          <button
                            name="cancel"
                            class="hand-icon action-button"
                            v-c-tooltip="'Cancel'"
                            v-if="
                              edit_speciality_id ||
                              getMultiFormEdited['specializationInformation']
                            "
                            @click="discard()"
                          >
                            <CIcon
                              name="cil-x-circle"
                              style="margin-right: 20px"
                            />
                          </button>
                        </div>
                      </CCol>
                    </CRow>
                    <div class="multi-records mt-2">
                      <div
                        class="top-border d-flex justify-content-between"
                        v-for="(data, index) in nurseSpeciality"
                        :key="index"
                        :class="{ 'border border-primary': data.main }"
                      >
                        <div class="ml-2 mt-1 record">
                          <div class="widget-content-actions hide-large-screen">
                            <CButton
                              class="icon icon-edit mr-2"
                              v-if="data && data.speciality_id"
                              @click="editSpeciality(data)"
                            >
                            </CButton>
                            <CButton
                              class="icon mr-1"
                              v-if="data && data.speciality_id && !data.main"
                              @click="deleteSpeciality(data)"
                            >
                              <i class="fas fa-trash-alt" />
                            </CButton>
                          </div>
                          <CRow
                            class="d-flex"
                            style="
                              justify-content: space-between;
                              margin-left: 5px;
                            "
                          >
                            <div class="table col col-4">
                              <p class="head">
                                {{
                                  data.main
                                    ? `Main Speciality`
                                    : `Other Speciality`
                                }}
                              </p>
                              <p class="data">
                                {{ getSpeciality(data) || "--" }}
                              </p>
                            </div>
                            <div class="table col col-4">
                              <p class="head">Years of Experience</p>
                              <p class="data">
                                {{ data.experience_in_years || "--" }}
                              </p>
                            </div>
                            <div class="table col col-4" v-if="isAHP">
                              <p class="head">Sub Type / License</p>
                              <p class="data">
                                {{
                                  getSubSpeciality(data.sub_speciality) || "--"
                                }}
                              </p>
                            </div>
                            <!-- <div class="table col col-4">
                              <span class="head"
                                >Associated Qualifications/Training
                                <span>
                                  <a
                                    class="data text-primary link-qualification"
                                    @click="showQualificationModal(data)"
                                    >(Click here to Link)</a
                                  ></span
                                >
                              </span>
                              <p class="data">No Qualifications Linked</p>
                            </div> -->
                          </CRow>
                        </div>
                        <div class="hide-small-screen">
                          <div
                            class="widget-content-actions d-flex flex-column justify-content-center"
                          >
                            <CButton
                              class="icon icon-edit mb-2"
                              v-if="data && data.speciality_id"
                              @click="editSpeciality(data)"
                            >
                            </CButton>
                            <CButton
                              class="icon"
                              v-if="data && data.speciality_id && !data.main"
                              @click="deleteSpeciality(data)"
                            >
                              <i class="fas fa-trash-alt" />
                            </CButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </ValidationObserver>
            </div>
          </CRow>
        </CCardBody>
      </CCard>
    </div>
    <Modal
      :modalTitle="deleteConfirmModal.modalTitle"
      :modalColor="deleteConfirmModal.modalColor"
      :modalContent="deleteConfirmModal.modalContent"
      :isShowPopup="deleteConfirmModal.isShowPopup"
      :buttons="deleteConfirmModal.buttons"
      :modalCallBack="deletemodalCallBack"
      :closeOnBackdrop="false"
    />
    <Modal
      :modalTitle="currentConfirmModal.modalTitle"
      :modalColor="currentConfirmModal.modalColor"
      :modalContent="currentConfirmModal.modalContent"
      :isShowPopup="currentConfirmModal.isShowPopup"
      :buttons="currentConfirmModal.buttons"
      :modalCallBack="currentmodalCallBack"
      :closeOnBackdrop="false"
    />
  </div>
</template>

<script>
import Vue from "vue";
import ProfileSection from "./ProfileSection";
import { mapActions, mapGetters } from "vuex";
import { extend, validate } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", { ...required, message: "This field is required" });
const debounce = require("lodash.debounce");
import m from "moment";
import Modal from "@/components/reusable/Modal";
extend("date_validate", (value) => {
  if (m(value).isValid()) {
    return true;
  }
  return "Invalid date! Enter a valid date";
});
extend("no_future_date", (value) => {
  let input_date = new Date(value);
  let today_date = new Date();
  if (input_date > today_date) {
    return "Given date should not be greater than today!";
  }
  return true;
});
export default {
  mixins: [ProfileSection],
  props: {
    candidateTypeId: {
      type: Number,
      default: null,
    },
    cancel: {
      type: Boolean,
      default: false,
    },
    currentJob: {
      type: String,
      default: null,
    },
  },
  components: {
    Modal,
  },
  data() {
    return {
      isDisabled: false,
      payload: {},
      profile: {},
      errors: [],
      cardHasError: "",
      edit_speciality_id: null,
      deleteConfirmModal: {
        modalColor: "danger",
        modalTitle: "Delete Document",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
      },
      currentConfirmModal: {
        modalColor: "danger",
        modalTitle: "Change Current",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
        data: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      "uploaded",
      "candidateProfile",
      "specialityByTypeAndCountry",
      "getCandidateSpecialInterest",
      "specialInterestFilterAHP",
    ]),
    options() {
      return {
        candidateType: this.candidateTypeReOrder || [],
        speciality_id:
          this.specialityByTypeAndCountry({
            candidateType: this.candidateTypeId,
            country_id:
              this.candidateProfile.highest_qualification_country_id || null,
          }) || [],
        sub_speciality_id:
          this.subSpecialityFilterBySpeciality({
            speciality: this.profile?.speciality_id?.code,
          }) || [],
        boolean: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
      };
    },
    candidateTypeReOrder() {
      let sortBy = [1, 2, 8].reverse();
      return (
        this.candidateType &&
        this.candidateType.length &&
        this.candidateType.sort((a, b) => {
          return -sortBy.indexOf(a.code) - -sortBy.indexOf(b.code);
        })
      );
    },
    cardError() {
      if (
        this.getMultiFormEdited.showError &&
        this.getMultiFormEdited.specializationInformation
      ) {
        this.cardHasError = "Please Save or Delete";
        if (this.getMultiFormEdited.fieldValidateError) {
          this.cardHasError = "Please fill mandatory fields";
        }
        let el = this.$el.getElementsByClassName("Specialization-info")[0];
        el.scrollIntoView(true);
        el.scrollIntoView({ block: "center" });
        window.scrollBy(0, -200);
        return true;
      }
      return false;
    },
    isNurse() {
      return this.candidateTypeId === 2 || false;
    },
    isAHP() {
      return this.candidateTypeId === 3 || false;
    },
    nurseSpeciality() {
      return (
        (this.getCandidateSpecialInterest &&
          this.getCandidateSpecialInterest
            .filter(
              (val) =>
                (val.speciality_id || val.other_speciality) &&
                val.special_interest_id === null &&
                val.other_special_interest === null
            )
            .sort((a, b) => b.main - a.main)) ||
        []
      );
    },
    isAnyMainAvl() {
      return this.nurseSpeciality.some(({ main }) => main) || false;
    },
  },
  methods: {
    ...mapActions([
      "uploadSpecialInterest",
      "updateSplInterest",
      "showToast",
      "fetchspecialInterest",
      "deleteCandidateSpecialInterest",
    ]),
    async updateSpecializationInfo() {
      this.$emit("tooltipHide");
      const isValid = await this.$refs.specialization.validate();
      if (!isValid) {
        this.cardHasError = "Please fill mandatory fields";
        return;
      } else if (!this.profile?.experience_in_years) {
        this.cardHasError = "Please fill Number of years Experience!";
        return;
      } else {
        let hasError = { hasError: false, errorMsg: "" };
        if (this.edit_speciality_id === null) {
          hasError.hasError = this.nurseSpeciality.some(
            (e) => e?.speciality_id === this.profile?.speciality_id?.code
          );
          hasError.errorMsg = hasError.hasError
            ? "Speciality Already Exists!"
            : "";
          if (!hasError.hasError && this.nurseSpeciality.length === 4) {
            hasError = {
              hasError: true,
              errorMsg: "Maximum No of speciality 4",
            };
          }
        } else {
          hasError.hasError = this.nurseSpeciality.some(
            (e) =>
              e?.speciality_id === this.profile?.speciality_id?.code &&
              this.edit_speciality_id !== e.candidate_speciality_id
          );
          hasError.errorMsg = hasError.hasError
            ? "Speciality Already Exists!"
            : "";
        }
        if (!hasError.hasError) {
          let finalPayload = {
            speciality_id:
              this.profile?.speciality_id?.code !== 0
                ? this.profile?.speciality_id?.code
                : null,
            experience_in_years: this.profile?.experience_in_years,
            main: this.profile?.main,
            is_special_interest_update: false,
            other_speciality: this.profile?.other_speciality
              ? this.profile?.other_speciality
              : null,
          };
          if (this.isAHP) {
            finalPayload = {
              ...finalPayload,
              sub_speciality_id: this.profile?.sub_speciality_id?.code || null,
            };
          }
          if (this.edit_speciality_id)
            finalPayload = {
              ...finalPayload,
              candidate_speciality_id: this.edit_speciality_id,
            };
          this.checkCurrentChanged(finalPayload);
        } else {
          this.cardHasError = hasError.errorMsg;
        }
      }
    },
    checkCurrentChanged(data) {
      let hasError = false;
      if (data.main) {
        if (
          (this.edit_speciality_id && !this.profile.is_main) ||
          (!this.edit_speciality_id && this.isAnyMainAvl)
        ) {
          this.currentConfirmModal.modalContent = ` Do you want to make this your Main Speciality ? - ${this.profile?.speciality_id?.label}`;
          this.currentConfirmModal.isShowPopup = true;
          this.currentConfirmModal.data = data;
          hasError = true;
          return;
        }
      }
      if (!hasError) this.saveSpecializationInfo(data);
    },
    saveSpecializationInfo(data) {
      const { customer_uid } = this.profile;
      let appendAction;
      if (this.edit_speciality_id) appendAction = this.updateSplInterest(data);
      else appendAction = this.uploadSpecialInterest(data);
      Promise.all([appendAction]).then((res) => {
        this.discard();
        this.fetchspecialInterest();
      });
    },
    handleMonthYearPickerChange(name, value) {
      Vue.set(this.profile, name, m(value).format("YYYY-MM"));
      Vue.set(this.payload, name, value ? m(value).format("YYYY-MM") : null);
    },
    handleSliderYrsExp: debounce(function (value) {
      Vue.set(this.profile, "experience_in_years", value);
      Vue.set(this.payload, "experience_in_years", value);
      this.multiFormEdited({ specializationInformation: true });
    }, 500),
    editSpeciality(data) {
      let el = this.$el.getElementsByClassName("Specialization-info")[0];
      el.scrollIntoView(true);
      window.scrollBy(0, -200);
      this.profile = {
        speciality_id: data?.speciality_id
          ? {
              code: data?.speciality?.speciality_id,
              label: data?.speciality?.speciality,
            }
          : null,
        experience_in_years: data?.experience_in_years,
        main: data?.main,
        is_main: data?.main,
        other_speciality: data?.other_speciality,
      };
      if (this.isAHP) {
        this.profile = {
          ...this.profile,
          sub_speciality_id:
            data?.sub_speciality_id !== null
              ? {
                  code: data?.sub_speciality?.sub_speciality_id,
                  label: data?.sub_speciality?.sub_speciality,
                }
              : null,
        };
      }
      this.edit_speciality_id = data?.candidate_speciality_id;
    },
    discard() {
      this.profile = {};
      this.edit_speciality_id = null;
      this.clearFields();
    },
    clearFields() {
      this.$nextTick(() => {
        this.$refs.specialization.reset();
        this.$refs.specialization.errors.clear;
      });
      this.cardHasError = "";
      this.multiFormEdited({ specializationInformation: false });
      this.multiFormEdited({ fieldValidateError: false });
      this.multiFormEdited({ showError: false });
      this.$emit("tooltipHide");
    },
    showQualificationModal() {},
    deleteSpeciality(data) {
      this.deleteConfirmModal.modalContent = `Do you want to delete this Speciality Info ? - ${data.speciality.speciality}`;
      this.deleteConfirmModal.candidate_speciality_id =
        data.candidate_speciality_id;
      this.deleteConfirmModal.isShowPopup = true;
    },
    deletemodalCallBack(action) {
      if (action === "Confirm") {
        this.deleteCandidateSpecialInterest(
          this.deleteConfirmModal.candidate_speciality_id
        );
      }
      this.clearFields();
      this.deleteConfirmModal.isShowPopup = false;
      this.fetchspecialInterest();
    },
    currentmodalCallBack(action) {
      if (action === "Confirm") {
        let data = this.currentConfirmModal.data;
        this.saveSpecializationInfo(data);
      }
      this.currentConfirmModal.isShowPopup = false;
    },
    getSubSpeciality(data) {
      return data?.sub_speciality || "--";
    },
    getSpeciality(data) {
      return data?.speciality &&
        data.speciality?.speciality !== "Other - not listed"
        ? data?.speciality?.speciality
        : `${data?.other_speciality}`;
    },
  },
  filters: {
    formatDate(data) {
      if (data != null) return m(data).format("MMM YYYY");
      return "--";
    },
  },
};
</script>
<style lang="scss" scoped>
.link-qualification {
  font-size: 14px;
  cursor: pointer;
}
</style>
<template>
  <div class="candidate-profile">
    <LoadingOverlay v-if="!isProfessionalInfo" />
    <div v-else class="professional-info">
      <div v-if="cardError"></div>
      <div class="pb-1 cardError" :class="cardHasError ? 'card-error-msg' : ''">
        {{ cardHasError }}
      </div>
      <CCard
        class="mt-2 has-loading-overlay"
        :class="cardHasError ? 'card-has-error' : ''"
      >
        <CCardBody>
          <CRow>
            <div class="col-md-12">
              <div class="d-flex justify-content-between">
                <div class="mt-2">
                  <h5 class="main-title pb-3">Professional Information</h5>
                </div>
                <div class="d-flex justify-content-end">
                  <button
                    name="save"
                    class="hand-icon action-button"
                    v-c-tooltip="'Save'"
                    @click="updateProfessionalInfo"
                  >
                    <CIcon
                      name="cil-save"
                      class="active"
                      style="margin-right: 20px"
                    />
                  </button>
                  <button
                    name="cancel"
                    class="hand-icon action-button ml-2"
                    v-if="getMultiFormEdited['personalInformation']"
                    v-c-tooltip="'Cancel'"
                    @click="resetProfessionalData(), clearFields()"
                  >
                    <CIcon name="cil-x-circle" style="margin-right: 20px" />
                  </button>
                </div>
              </div>
              <ValidationObserver ref="professional" v-slot="{ handleSubmit }">
                <form
                  id="professional"
                  @submit.prevent="handleSubmit(onSubmitStep1)"
                >
                  <fieldset :disabled="isDisabled">
                    <CRow>
                      <CCol md="6">
                        <CRow class="row mb-3">
                          <label
                            name="lbl_candidate_type_id"
                            class="required col-lg-12 col-md-12"
                            >Type
                            <!-- <span
                              class="hide-small-screen"
                              style="cursor: pointer; color: #e55353"
                            >
                              <i
                                class="fa fa-exclamation-triangle fa-lg"
                                @click="showConfirmModal()"
                              ></i>
                            </span> -->
                          </label>
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <Select
                                :disabled="true"
                                name="candidate_type_id"
                                :value="profile.candidate_type_id"
                                :options="
                                  options && options['candidateType']
                                    ? options['candidateType']
                                    : []
                                "
                                @input="handleChangeSelect"
                                :taggable="false"
                                :multiple="false"
                                :clearable="false"
                                :error="errors[0]"
                              />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                      <!-- <div class="hide-large-screen">
                        <CCol md="1" class="pl-1 pr-2">
                          <CRow class="row mb-4 pl-1 pt-2">
                            <span
                              class="pt-4 pl-1"
                              style="cursor: pointer; color: #e55353"
                            >
                              <i
                                class="fa fa-exclamation-triangle fa-lg"
                                @click="showConfirmModal()"
                              ></i>
                            </span>
                          </CRow>
                        </CCol>
                      </div> -->
                      <CCol md="6">
                        <CRow class="row mb-3">
                          <label
                            name="lbl_current_job"
                            class="col-lg-12 col-md-12"
                            >Current Job Title</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <TextInput
                              name="current_job"
                              :value="profile.current_job"
                              @change="handleInput"
                            />
                          </div>
                        </CRow>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol md="6" v-if="isNurse">
                        <CRow class="row mb-3">
                          <label
                            name="lbl_nursing_level"
                            class="col-lg-12 col-md-12 required"
                            >{{
                              isNurse ? `Nurse / Midwifery Level` : `Level`
                            }}</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <Select
                                name="level_id"
                                :value="profile.level_id"
                                :options="
                                  options && options['nurseLevel']
                                    ? options['nurseLevel']
                                    : []
                                "
                                @input="handleChangeSelect"
                                :taggable="false"
                                :multiple="false"
                                :clearable="false"
                                :error="errors[0]"
                                :showTooltip="true"
                              />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                      <CCol md="6" v-if="isNurse && isPGQualification">
                        <CRow name="registered_as" class="row mb-3">
                          <label class="col-lg-12 col-md-12 required"
                            >Are you Licensed/Registered as a Speciality Nurse
                            or (Advanced) Nurse Practitioner?</label
                          >
                          <CCol md="12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <RadioButton
                                name="nurse_registration_type"
                                :value="profile['nurse_registration_type']"
                                :options="
                                  options && options['boolean']
                                    ? options['boolean']
                                    : []
                                "
                                @change="handleChangeRadioNurseType"
                                :error="errors[0]"
                              />
                            </ValidationProvider>
                          </CCol>
                        </CRow>
                      </CCol>
                    </CRow>
                  </fieldset>
                </form>
              </ValidationObserver>
            </div>
          </CRow>
        </CCardBody>
      </CCard>
    </div>
    <QualificationInfoNurse v-if="isNurse || isAHP" @tooltipHide="tooltipHide">
    </QualificationInfoNurse>
    <QualificationInfo v-else @tooltipHide="tooltipHide"> </QualificationInfo>
    <SpecializationInfoNurse
      v-if="isNurse || isAHP"
      ref="speciality"
      class="specialization"
      :candidateTypeId="profile.candidate_type_id.code"
      :currentJob="profile.current_job"
      :cancel="cancel"
      @tooltipHide="tooltipHide"
    >
    </SpecializationInfoNurse>
    <SpecializationInfo
      v-else
      ref="speciality"
      class="specialization"
      :candidateTypeId="profile.candidate_type_id.code"
      :currentJob="profile.current_job"
      :cancel="cancel"
      @tooltipHide="tooltipHide"
    >
    </SpecializationInfo>
    <!-- <SpecialInterestInfo v-if="isAHP"> </SpecialInterestInfo> -->
    <!-- <LicenseInfo> </LicenseInfo> -->
    <doh-license-info-talent-find></doh-license-info-talent-find>
    <Modal
      :modalTitle="confirmModal.modalTitle"
      :modalColor="confirmModal.modalColor"
      :modalContent="confirmModal.modalContent"
      :isShowPopup="confirmModal.isShowPopup"
      :buttons="confirmModal.buttons"
      :modalCallBack="confirmModalCallBack"
      :closeOnBackdrop="false"
    />
  </div>
</template>

<script>
import Vue from "vue";
import ProfileSection from "./ProfileSection";
import { mapActions, mapGetters } from "vuex";
import { extend, validate } from "vee-validate";
import QualificationInfo from "@/containers/CandidateProfile/QualificationInfoTalentFind";
import LicenseInfo from "@/containers/CandidateProfile/LicenseInfoTalentfind";
import SpecialInterestInfo from "@/containers/CandidateProfile/SpecialInterestInfoTFind";
import SpecializationInfo from "@/containers/CandidateProfile/SpecializationInfoTalentFind";
import PreferredDepartment from "@/containers/CandidateProfile/PreferredDepartment";
import Modal from "@/components/reusable/Modal";
import { required } from "vee-validate/dist/rules";
import SpecializationInfoNurse from "@/containers/CandidateProfile/SpecializationInfoTalentFindNurse";
import QualificationInfoNurse from "@/containers/CandidateProfile/QualificationInfoTalentFindNurse";
extend("required", { ...required, message: "This field is required" });
import _ from "lodash";
import { isEmptyObjectCheck } from "../../helpers/helper";
import DohLicenseInfoTalentFind from "./DohLicenseInfoTalentFind.vue";

export default {
  mixins: [ProfileSection],
  components: {
    LicenseInfo,
    SpecialInterestInfo,
    SpecializationInfo,
    QualificationInfo,
    Modal,
    PreferredDepartment,
    SpecializationInfoNurse,
    QualificationInfoNurse,
    DohLicenseInfoTalentFind,
  },
  data() {
    return {
      isDisabled: false,
      payload: {},
      profile: {},
      errors: [],
      cardHasError: "",
      cancel: false,
      confirmModal: {
        modalColor: "primary",
        modalTitle: "Alert !!",
        modalContent:
          "Are you sure to update the Profession? This will reset your Qualification and Speciality!",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
      },
      disabled: true,
    };
  },
  computed: {
    ...mapGetters([
      "uploaded",
      "candidateProfile",
      "candidateLevelFilterByTypeCountry",
      "getCandidateQualification",
    ]),
    options() {
      return {
        candidateType: this.candidateTypeReOrder || [],
        speciality_id:
          this.specialityFilterByType({
            candidateType: this.profile?.candidate_type_id?.code,
          }) || [],
        sub_speciality_id:
          this.subSpecialityFilterBySpeciality({
            speciality: this.profile?.speciality_id?.code,
          }) || [],
        nurseLevel:
          this.candidateLevelFilterByTypeCountry({
            candidateType: this.profile?.candidate_type_id?.code,
            country_id: null,
          }) || [],
        boolean: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
      };
    },
    candidateTypeReOrder() {
      let sortBy = [1, 2, 8].reverse();
      return (
        this.candidateType &&
        this.candidateType.length &&
        this.candidateType.sort((a, b) => {
          return -sortBy.indexOf(a.code) - -sortBy.indexOf(b.code);
        })
      );
    },
    isProfessionalInfo() {
      if (Object.keys(this.candidateProfile).length) {
        return this.setProfessionalData(this.candidateProfile);
      }
      return false;
    },
    cardError() {
      if (
        this.getMultiFormEdited.showError &&
        this.getMultiFormEdited.personalInformation
      ) {
        this.cardHasError = "Please Save or Delete";
        if (this.getMultiFormEdited.fieldValidateError) {
          this.cardHasError = "Please fill mandatory fields";
        }
        let el = this.$el.getElementsByClassName("cardError")[0];
        el.scrollIntoView(true);
        el.scrollIntoView({ block: "center" });
        window.scrollBy(0, -200);
        return true;
      }
      return false;
    },
    isNurse() {
      return this.profile?.candidate_type_id?.code
        ? this.profile.candidate_type_id.code === 2
        : false;
    },
    isAHP() {
      return this.profile?.candidate_type_id?.code === 3 || false;
    },
    isPGQualification() {
      return (
        this.getCandidateQualification?.some(
          ({ qualification }) =>
            qualification?.qualification_classification_sort_level >= 100
        ) || false
      );
    },
  },
  mounted() {
    const isValid = this.$refs.professional.validate().then((res) => {
      res ? null : this.multiFormEdited({ fieldValidateError: true });
    });
  },
  methods: {
    ...mapActions(["updateCandidate", "showToast"]),
    setProfessionalData(profileData) {
      this.profile = {
        customer_uid: profileData.customer_uid,
        candidate_uid: profileData.candidate_uid,
        candidate_type_id:
          profileData.candidate_type !== null
            ? {
                code: profileData.candidate_type.candidate_type_id,
                label: profileData.candidate_type.candidate_type,
              }
            : null,
        speciality_id:
          profileData.speciality !== null
            ? {
                code: profileData.speciality.speciality_id,
                label: profileData.speciality.speciality,
              }
            : null,
        sub_speciality_id:
          profileData.sub_speciality_id !== null
            ? {
                code: profileData.sub_speciality.sub_speciality_id
                  ? profileData.sub_speciality.sub_speciality_id
                  : null,
                label: profileData.sub_speciality.sub_speciality
                  ? profileData.sub_speciality.sub_speciality
                  : null,
              }
            : null,
        current_job: profileData.current_job,
      };
      if (profileData.candidate_type_id === 2) {
        this.profile.nurse_registration_type =
          profileData?.nurse_registration_type &&
          profileData?.nurse_registration_type.includes(
            "speciality_nurse_or_nurse_practitioner"
          );
      }
      if (profileData.candidate_type_id === 2) {
        this.profile["level_id"] = profileData?.candidate_level?.level_id
          ? {
              code: profileData?.candidate_level?.level_id,
              label: profileData?.candidate_level?.candidate_level,
            }
          : null;
      }
      return this.profile;
    },
    tooltipHide() {
      setTimeout(() => {
        let x = document.querySelectorAll(".tooltip");
        for (var i = 0; i < x.length; i++) {
          x[i].style.visibility = "hidden";
        }
      }, 100);
    },
    async updateProfessionalInfo() {
      this.$emit("tooltipHide");
      const isValid = await this.$refs.professional.validate();
      if (!isValid) {
        this.cardHasError = "Please fill mandatory fields";
        return;
      }
      this.payload = {
        candidate_uid: this.profile?.candidate_uid,
        candidate_type_id: this.profile?.candidate_type_id?.code,
        volunteer: this.profile?.volunteer,
        current_job: this.profile?.current_job,
      };
      if (this.profile?.candidate_type_id?.code === 2) {
        let nurse_registration_type = [];
        nurse_registration_type =
          this.profile["nurse_registration_type"] === true
            ? ["speciality_nurse_or_nurse_practitioner"]
            : null;
        this.payload = { ...this.payload, nurse_registration_type };
      }
      if (this.profile?.candidate_type_id?.code === 2) {
        this.payload = {
          ...this.payload,
          level_id: this.profile?.level_id?.code,
        };
      }
      this.saveProfessionalInfo(this.payload);
    },
    saveProfessionalInfo(data) {
      if (
        data.candidate_type_id !== 3 &&
        data.candidate_type_id !== 2 &&
        !this.$refs.speciality.profile.speciality_id
      ) {
        let el = this.$el.getElementsByClassName("specialization")[0];
        el.scrollIntoView(true);
      } else {
        const { customer_uid } = this.profile;
        let appendAction = this.updateCandidate({
          data,
          customer_uid,
        });
        Promise.all([appendAction]).then((res) => {
          this.clearFields();
        });
      }
    },
    resetProfessionalData() {
      this.cancel = true;
      this.setProfessionalData(this.candidateProfile);
    },
    clearFields() {
      this.cardHasError = "";
      this.disabled = true;
      this.multiFormEdited({ personalInformation: false });
      this.multiFormEdited({ fieldValidateError: false });
      this.multiFormEdited({ showError: false });
      this.$emit("tooltipHide");
    },
    showConfirmModal() {
      this.confirmModal.isShowPopup = true;
    },
    confirmModalCallBack(action) {
      if (action === "Confirm") {
        this.disabled = false;
      }
      if (action === "Cancel") {
        this.disabled = true;
      }
      this.confirmModal.isShowPopup = false;
    },
    handleChangeRadioNurseType(name, value) {
      let val = [];
      if (value.id || value.code || value === true) {
        val = ["speciality_nurse_or_nurse_practitioner"];
      }
      Vue.set(this.profile, name, value?.id || value?.code || value);
      this.payload = {
        ...this.payload,
        [name]: val,
      };

      this.formEdited(name);
    },
  },
};
</script>
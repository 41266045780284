<template>
  <div>
    <div v-if="isFetching">
      <div class="gray-box">
        <h6 class="pb-1 fw-600">Eligibility Matched</h6>
        <CRow class="pb-2">
          <CCol col="6" class="text-gray">Eligibility Country</CCol>
          <CCol col="6" class="text-black">{{
            pqrLevel.country_name || "--"
          }}</CCol>
        </CRow>
        <CRow class="pb-2">
          <CCol col="6" class="text-gray">Authority</CCol>
          <CCol col="6" class="text-black">{{
            pqrLevel.issue_authority_name || "--"
          }}</CCol>
        </CRow>
        <CRow class="pb-2">
          <CCol col="6" class="text-gray">Eligibility Level</CCol>
          <CCol col="6" class="text-black">{{
            pqrLevel.pqr_detail_description || "--"
          }}</CCol>
        </CRow>
        <CRow class="pb-2">
          <CCol col="6" class="text-gray">Comments</CCol>
          <CCol col="6" class="text-black">{{
            pqrLevel.comments || "--"
          }}</CCol>
        </CRow>
        <CRow class="pb-2" v-if="pqrLevel.potential.length">
          <CCol col="6" class="text-gray">Note</CCol>
          <CCol col="6" class="text-black"
            >{{ pqrLevel.potential[0].pqr_detail_description
            }}{{
              pqrLevel.potential[0].comments
                ? ` (${pqrLevel.potential[0].comments}) `
                : ""
            }}
            in next 6 months</CCol
          >
        </CRow>
        <CRow class="pb-2">
          <CCol col="6" class="text-gray">Date Created</CCol>
          <CCol col="6" class="text-black">{{
            pqrLevel.created_on | moment
          }}</CCol>
        </CRow>
      </div>
      <div v-if="qualificationDetail" class="gray-box">
        <h6 class="pb-1 fw-600 border-top pt-2 pb-2">Qualification Matched</h6>
        <CRow class="pb-2">
          <CCol col="6" class="text-gray">Qualification</CCol>
          <CCol col="6" class="text-black">{{
            qualificationDetail.qualification_name || "--"
          }}</CCol>
        </CRow>
        <CRow class="pb-2">
          <CCol col="6" class="text-gray">Qualification Country</CCol>
          <CCol col="6" class="text-black">{{
            qualificationDetail.country_name || "--"
          }}</CCol>
        </CRow>
        <CRow class="pb-2">
          <CCol col="6" class="text-gray">Speciality</CCol>
          <CCol col="6" class="text-black">{{
            qualificationDetail.speciality_name || "--"
          }}</CCol>
        </CRow>
        <CRow class="pb-2">
          <CCol col="6" class="text-gray">Issue Authority</CCol>
          <CCol col="6" class="text-black">{{
            qualificationDetail.issue_authority_name || "--"
          }}</CCol>
        </CRow>
        <CRow class="pb-2">
          <CCol col="6" class="text-gray">Year Awarded</CCol>
          <CCol col="6" class="text-black">{{
            qualificationDetail.month_year_awarded || "--"
          }}</CCol>
        </CRow>
      </div>
      <div v-if="licenseDetail" class="gray-box">
        <h6 class="pb-1 fw-600 border-top pt-2 pb-2">License Matched</h6>
        <CRow class="pb-2">
          <CCol col="6" class="text-gray">License Country</CCol>
          <CCol col="6" class="text-black">{{
            licenseDetail.country_name || "--"
          }}</CCol>
        </CRow>
        <CRow class="pb-2">
          <CCol col="6" class="text-gray">Licensing Authority</CCol>
          <CCol col="6" class="text-black">{{
            licenseDetail.licensing_authority_name || "--"
          }}</CCol>
        </CRow>
        <CRow class="pb-2">
          <CCol col="6" class="text-gray">Issue Date</CCol>
          <CCol col="6" class="text-black">{{
            licenseDetail.issue_date | moment
          }}</CCol>
        </CRow>
        <CRow class="pb-2">
          <CCol col="6" class="text-gray">Is Specialist Registration?</CCol>
          <CCol col="6" class="text-black">{{
            licenseDetail.specialist_registration | yesOrNo
          }}</CCol>
        </CRow>
        <CRow class="pb-2">
          <CCol col="6" class="text-gray">Is License Current</CCol>
          <CCol col="6" class="text-black">{{
            licenseDetail.current_license || "--"
          }}</CCol>
        </CRow>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";
export default {
  props: ["pqrLevel"],
  computed: {
    isFetching() {
      return isObject(this.pqrLevel) && !isEmptyObjectCheck(this.pqrLevel);
    },
    qualificationDetail() {
      if (this.isFetching) {
        if (
          isObject(this.pqrLevel.candidate_qualification) &&
          !isEmptyObjectCheck(this.pqrLevel.candidate_qualification)
        ) {
          let {
            qualification_name,
            country_name,
            speciality_name,
            issue_authority_name,
            month_awarded,
            year_awarded,
          } = this.pqrLevel.candidate_qualification;
          return {
            qualification_name,
            country_name,
            speciality_name,
            issue_authority_name,
            month_year_awarded: `${this.getMonth(
              month_awarded
            )} ${year_awarded}`,
          };
        }
      }
      return false;
    },
    licenseDetail() {
      if (this.isFetching) {
        if (
          isObject(this.pqrLevel.candidate_license) &&
          !isEmptyObjectCheck(this.pqrLevel.candidate_license)
        ) {
          let {
            country_name,
            licensing_authority_name,
            issue_date,
            specialist_registration,
            current_license,
          } = this.pqrLevel.candidate_license;
          return {
            country_name,
            licensing_authority_name,
            issue_date,
            specialist_registration,
            current_license,
          };
        }
      }
      return false;
    },
  },
  filters: {
    moment: function (date) {
      if (date) return moment(date).format("DD-MMM-YYYY");
      return "--";
    },
    yesOrNo: function (data) {
      if (data != null && typeof data === "boolean") return data ? "Yes" : "No";
      return "--";
    },
  },
  methods: {
    getMonth(date) {
      if (date) return moment.monthsShort()[date - 1];
      return "";
    },
  },
};
</script>
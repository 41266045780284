<template>
  <div class="qualification-info">
    <div class="qualificationInfo">
      <div v-if="cardError"></div>
      <div class="pb-1 cardError" :class="cardHasError ? 'card-error-msg' : ''">
        {{ cardHasError }}
      </div>
      <CCard class="mt-2" :class="cardHasError ? 'card-has-error' : ''">
        <CCardBody>
          <div class="d-flex align-items-center justify-content-between">
            <div class="mr-2">
              <h5 class="main-title pb-3">
                Medical Qualifications<span class="h6">
                  [{{ SortedQualifications.length || "0" }} record{{
                    SortedQualifications.length > 1 ? "s" : ""
                  }}]</span
                ><span class="ml-1 h6"
                  >[Add Additional Qualifications Below]</span
                >
              </h5>
            </div>
          </div>
          <ValidationObserver ref="qualification" v-slot="{ handleSubmit }">
            <form id="qualification" @submit.prevent="handleSubmit()">
              <fieldset>
                <CRow>
                  <CCol md="6">
                    <CRow class="row mb-3">
                      <label
                        name="lbl_country"
                        class="required col-lg-12 col-md-12"
                        >{{
                          edit_Qualification_uuid
                            ? "Medical Qualification Obtained In"
                            : "Additional Medical Qualification Obtained In"
                        }}</label
                      >
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <Select
                            name="country_id"
                            :value="profile.country_id"
                            @input="handleChangeSelect"
                            :options="
                              options && options['country']
                                ? options['country']
                                : []
                            "
                            :taggable="false"
                            :multiple="false"
                            :clearable="false"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                  <CCol md="6">
                    <CRow class="row mb-3">
                      <label
                        name="lbl_main_qualification_id"
                        class="required col-lg-12 col-md-12"
                        >Qualification</label
                      >
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <Select
                            name="main_qualification_id"
                            :value="profile.main_qualification_id"
                            @input="handleChangeSelect"
                            :options="
                              options && options['main_qualification_id']
                                ? options['main_qualification_id']
                                : []
                            "
                            :taggable="false"
                            :multiple="false"
                            :clearable="false"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                </CRow>
                <CRow v-if="mainQualificationIsOther">
                  <CCol lg="6"> </CCol>
                  <CCol lg="6">
                    <CRow name="other_qualification_name" class="row mb-3">
                      <label class="required col-lg-12 col-md-12"
                        >Other Qualification (Level / Subject)</label
                      >
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <TextInput
                            name="other_qualification_name"
                            :value="profile.other_qualification_name"
                            @input="handleInput"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                </CRow>
                <CRow
                  v-if="
                    profile.main_qualification_id
                      ? profile.main_qualification_id.by_exam
                      : false
                  "
                >
                  <CCol md="6"></CCol>
                  <CCol md="6">
                    <CRow class="row mb-3">
                      <label
                        name="lbl_by_exam"
                        class="required col-lg-12 col-md-12"
                        >Is This By Exam?</label
                      >
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <RadioButton
                            name="by_exam"
                            :value="profile.by_exam"
                            :options="
                              options && options['byExam']
                                ? options['byExam']
                                : []
                            "
                            @change="handleChangeRadio"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                </CRow>
                <CRow>
                  <!-- <CCol
                    md="6"
                    v-if="
                      profile.main_qualification_id &&
                      profile.main_qualification_id.allow_subject_input == true
                    "
                  > -->
                  <CCol
                    md="6"
                    v-if="
                      profile.main_qualification_id &&
                      profile.main_qualification_id.allow_subject_input == true
                    "
                  >
                    <CRow name="qualification_subject_name" class="mb-3">
                      <label class="col-lg-12 col-md-12"
                        >Qualification Subject</label
                      >
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <TextInput
                          name="description"
                          :value="profile.description"
                          @input="handleInput"
                        />
                      </div>
                    </CRow>
                  </CCol>
                  <CCol md="6">
                    <CRow class="row mb-3">
                      <label
                        name="lbl_main_qualification_year"
                        class="required col-lg-12 col-md-12"
                        >Qualification Month / Year Awarded</label
                      >
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <ValidationProvider name="dob" v-slot="{ errors }">
                          <TextInput
                            hidden
                            name="dob"
                            :value="profile.dob"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                        <ValidationProvider
                          rules="QualifiedDate:@dob|date_validate|required"
                          v-slot="{ errors }"
                        >
                          <DateInput
                            :onlyMonthAndYear="true"
                            name="qualified_date"
                            :value="profile.qualified_date"
                            @change="handleDatePickerChange"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                </CRow>
                <CRow
                  v-if="profile.issued_by && profile.issued_by.code == 'Others'"
                >
                  <CCol md="6">
                    <CRow name="other_issue_authority" class="row mb-3">
                      <label class="col-lg-12 col-md-12"
                        >Other Issued Authority</label
                      >
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <TextInput
                          name="other_issue_authority"
                          :value="profile.other_issue_authority"
                          @input="handleInput"
                        />
                      </div>
                    </CRow>
                  </CCol>
                </CRow>
                <!-- <CButton
                  v-if="profile.main_qualification_id"
                  type="button"
                  class="btn-primary rounded-0 small"
                  @click="showLinkDocument()"
                >
                  Link Document
                </CButton> -->

                <CRow>
                  <CCol md="6"></CCol>
                  <CCol md="6">
                    <div class="float-right mb-2">
                      <CButton
                        v-if="
                          !edit_Qualification_uuid &&
                          getMultiFormEdited['qualification']
                        "
                        class="btn-primary small mr-2"
                        style="height: 28px"
                        @click="updateQualificationInfo"
                        v-c-tooltip="'Click to Add multiple records!'"
                        >Add</CButton
                      >
                      <CButton
                        class="btn-primary small mr-1"
                        style="height: 28px"
                        @click="updateQualificationInfo"
                        v-if="edit_Qualification_uuid"
                        v-c-tooltip="'Click to update the record!'"
                        >Update</CButton
                      >
                      <button
                        name="cancel"
                        class="hand-icon action-button"
                        v-c-tooltip="'Cancel'"
                        v-if="
                          edit_Qualification_uuid ||
                          getMultiFormEdited['qualification']
                        "
                        @click="discard()"
                      >
                        <CIcon name="cil-x-circle" style="margin-right: 20px" />
                      </button>
                    </div>
                  </CCol>
                </CRow>
                <div v-if="SortedQualifications" class="multi-records">
                  <div
                    class="top-border d-flex justify-content-between"
                    v-for="(data, index) in SortedQualifications"
                    :key="index"
                  >
                    <div class="ml-2 mt-1 record">
                      <div class="widget-content-actions hide-large-screen">
                        <CButton
                          class="icon icon-edit mr-2"
                          v-if="data && data.candidate_qualification_id"
                          @click="editQualification(data)"
                        >
                        </CButton>
                        <CButton
                          class="icon mr-1"
                          @click="deleteQualification(data)"
                        >
                          <i class="fas fa-trash-alt" />
                        </CButton>
                      </div>
                      <CRow
                        class="d-flex"
                        style="justify-content: space-between; margin-left: 5px"
                      >
                        <div class="table col col-5">
                          <p class="head">Qualification</p>
                          <p class="data">{{ data | qualificationName }}</p>
                        </div>
                        <div class="table col col-3">
                          <p class="head">Country</p>
                          <p class="data">
                            {{ data.country_name }}
                          </p>
                        </div>
                        <div class="table col col-3">
                          <p class="head">Month / Year</p>
                          <p class="data">
                            {{
                              formatDate(data.month_awarded, data.year_awarded)
                            }}
                          </p>
                        </div>
                        <div
                          v-if="data.qualification.allow_subject_input"
                          class="table col col-6"
                        >
                          <p class="head">Subject</p>
                          <p class="data">
                            {{ data.description ? data.description : "--" }}
                          </p>
                        </div>
                        <!-- <div class="table col col-6">
                          <p class="head">
                            Linked Documents
                            <a
                              v-if="!edit_Qualification_id"
                              class="data text-primary"
                              :style="{
                                'font-size': '14px',
                                cursor: 'pointer',
                              }"
                              @click="showDocumentModal(data)"
                              >(Click here to Link)</a
                            >
                          </p>
                          <p
                            class="data"
                            v-if="data.document_ids && data.document_ids.length"
                          >
                            <span
                              ><a
                                class="text-primary"
                                style="cursor: pointer"
                                v-for="(i, index) in data.documents"
                                :key="index"
                                @click="preview(i)"
                                >{{
                                  index == 0
                                    ? i.document_name
                                    : `, ${i.document_name}`
                                }}</a
                              ></span
                            >
                          </p>
                          <p class="data" v-else>No Documents Linked</p>
                        </div> -->
                      </CRow>
                    </div>
                    <div class="hide-small-screen">
                      <div
                        class="
                          widget-content-actions
                          d-flex
                          flex-column
                          justify-content-center
                        "
                      >
                        <CButton
                          class="icon icon-edit mb-2"
                          v-if="data && data.candidate_qualification_id"
                          @click="editQualification(data)"
                        >
                        </CButton>
                        <CButton
                          class="icon"
                          @click="deleteQualification(data)"
                        >
                          <i class="fas fa-trash-alt" />
                        </CButton>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </form>
          </ValidationObserver>
        </CCardBody>
      </CCard>
      <Modal
        :modalTitle="deleteConfirmModal.modalTitle"
        :modalColor="deleteConfirmModal.modalColor"
        :modalContent="deleteConfirmModal.modalContent"
        :isShowPopup="deleteConfirmModal.isShowPopup"
        :buttons="deleteConfirmModal.buttons"
        :modalCallBack="deletemodalCallBack"
        :closeOnBackdrop="false"
      />
      <document-mapping-modal
        :isShowPopup="linkDocument.isShowPopup"
        :document_type_id="12"
        :label="linkDocument.label"
        :candidate_qualification_id="linkDocument.candidate_qualification_id"
        :document_ids="linkDocument.document_ids"
        @modalCallBack="linkModalCallBack"
      />
      <preview-modal
        modalTitle="Document Preview"
        modalColor="primary"
        :buttons="['Ok']"
        size="lg"
        :isShowPopup="previewModal.isShowPopup"
        :modalCallBack="previewModalCallBack"
        :isPreview="previewModal.isPreview"
        :iframe="iframe"
        :closeOnBackdrop="false"
      />
    </div>
  </div>
</template>

<script>
const debounce = require("lodash.debounce");
import Vue from "vue";
import m from "moment";
import { mapActions, mapGetters } from "vuex";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", { ...required, message: "This field is required" });
extend("date_validate", (value) => {
  if (m(value).isValid()) {
    return true;
  }
  return "Invalid date! Enter a valid date";
});
extend("QualifiedDate", {
  params: ["dob"],
  validate(value, { dob }) {
    let birth_date = new Date(dob);
    let input_date = new Date(value);
    let today_date = new Date();
    if (input_date > birth_date && input_date < today_date) {
      return true;
    }
  },
  message: "Given date should be between birthdate & today!",
});
import pdf from "vue-pdf";
import ProfileSection from "./ProfileSection";
import Modal from "@/components/reusable/Modal";
import DocumentMappingModal from "./DocumentMappingModal.vue";
import PreviewModal from "../../components/reusable/PreviewModal.vue";

export default {
  mixins: [ProfileSection],
  components: {
    Modal,
    DocumentMappingModal,
    PreviewModal,
  },
  data() {
    return {
      isDisabled: false,
      profile: {
        main_Qualification: false,
      },
      payload: {},
      candidate_qualification_id: 0,
      deleteConfirmModal: {
        modalColor: "danger",
        modalTitle: "Delete Document",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
      },
      deleteMainqualification: false,
      errors: [],
      edit_Qualification_uuid: null,
      _index: null,
      delete_Qualification: false,
      cardHasError: "",
      linkDocument: {
        isShowPopup: false,
        label: "",
        candidate_qualification_id: null,
        document_ids: [],
      },
      previewModal: {
        isShowPopup: false,
        isPreview: false,
      },
      iframe: {
        src: "",
        loaded: false,
        isImg: false,
        pdf: {
          ispdf: false,
          src: "",
          numPages: undefined,
          addContentClasses: "",
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      "uploaded",
      "getCandidateQualification",
      "candidateProfile",
      "issueAuthorityByQualification",
      "qualificationByCountryAndCandidateType",
      "higherQualificationByCountryAndCandidateType",
      "firstQualificationByCountryAndCandidateType",
    ]),
    options() {
      return {
        main_qualification_id:
          this.qualificationByCountryAndCandidateType || [],
        issued_by:
          this.issueAuthorityByQualification({
            country_id: this.profile?.country_id?.code,
            qualification_id: this.profile?.main_qualification_id?.code,
          }) || [],
        distance_learning: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
          { id: null, label: "N/A" },
        ],
        country: this.locationCountryInfo || [],
        byExam: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
      };
    },
    Qualifications() {
      if (Object.keys(this.candidateProfile).length) {
        this.profile.dob = this.candidateProfile.dob;
      }
      if (this.getCandidateQualification) {
        return (
          this.getCandidateQualification?.length &&
          this.getCandidateQualification.map(
            ({
              candidate_qualification_uuid,
              candidate_qualification_id,
              qualification_id,
              qualification_name,
              qualification,
              country_id,
              country_name,
              year_awarded,
              month_awarded,
              issue_authority_id,
              issue_authority_name,
              course_duration,
              distance_learning,
              qualification: { qualification_with_acronym },
              other_issue_authority,
              by_exam,
              other_qualification_name,
              description,
              document_ids,
              documents,
              main,
            }) => {
              return {
                candidate_qualification_uuid,
                candidate_qualification_id:
                  candidate_qualification_id !== null
                    ? candidate_qualification_id
                    : "--",
                qualification_id:
                  qualification_id !== null ? qualification_id : "--",
                qualification_name:
                  qualification_name !== null ? qualification_name : "--",
                qualification: qualification,
                country_id: country_id !== null ? country_id : "--",
                country_name: country_name !== null ? country_name : "--",
                year_awarded: year_awarded !== null ? year_awarded : null,
                month_awarded: month_awarded !== null ? month_awarded : null,
                issue_authority_id:
                  issue_authority_id !== null ? issue_authority_id : "Others",
                issue_authority_name:
                  issue_authority_name !== null ? issue_authority_name : "--",
                other_issue_authority:
                  other_issue_authority !== null ? other_issue_authority : "--",
                course_duration:
                  course_duration !== null ? course_duration : "--",
                distance_learning:
                  distance_learning !== null ? distance_learning : "--",
                qualification_with_acronym:
                  qualification_with_acronym !== null
                    ? qualification_with_acronym
                    : null,
                by_exam: by_exam !== null ? by_exam : "--",
                other_qualification_name: other_qualification_name,
                description: description !== null ? description : "--",
                document_ids: document_ids,
                documents: documents,
                main: main,
              };
            }
          )
        );
      } else {
        return false;
      }
    },
    SortedQualifications() {
      if (this.Qualifications) {
        return this.Qualifications.sort((a, b) => {
          return b.qualification_name - a.qualification_name;
        })
          .sort((a, b) => {
            return b.year_awarded - a.year_awarded;
          })
          .sort((a, b) => (a.main === b.main ? 0 : a.main ? 1 : -1));
      }
      return false;
    },
    cardError() {
      if (
        this.getMultiFormEdited.showError &&
        this.getMultiFormEdited.qualification
      ) {
        this.cardHasError = "Please Save or Delete";
        let el = this.$el.getElementsByClassName("cardError")[0];
        el.scrollIntoView(true);
        el.scrollIntoView({ block: "center" });
        window.scrollBy(0, -200);
        return true;
      }
      return false;
    },
    mainQualificationIsOther() {
      return (
        this.profile?.main_qualification_id?.label.includes(
          "Other - not listed"
        ) || false
      );
    },
    isAHP() {
      return this.candidateProfile?.candidate_type_id === 3 || false;
    },
  },
  watch: {
    uploaded() {
      if (this.uploaded) {
        this.fetchQualification();
      }
    },
    getCandidateQualification(newVal, oldVal) {
      if (oldVal.length) {
        this.checkHasPGQualification();
      }
    },
  },
  mounted() {
    this.fetchQualificationByCountryAndCandidateType();
    this.fetchIssueAuthorityByQualification();
  },
  methods: {
    ...mapActions([
      "fetchQualification",
      "uploadQualification",
      "deleteCandidateQualification",
      "showToast",
      "updateQualification",
      "fetchUploadedDocument",
      "fetchHigherQualificationByCountryAndCandidateType",
      "fetchFirstQualificationByCountryAndCandidateType",
      "fetchQualificationByCountryAndCandidateType",
      "updateCandidate",
    ]),
    async updateQualificationInfo() {
      this.tooltipHide();
      const isValid = await this.$refs.qualification.validate();
      this.errors = Object.values(this.$refs.qualification.errors).flat();
      if (!isValid) {
        let el = this.$el.getElementsByClassName("has-error")[0];
        el.scrollIntoView(true);
        window.scrollBy(0, -60);
        this.cardHasError = this.errors[0] || "Please fill mandatory fields!";
        return;
      } else if (
        this.profile.main_qualification_id.course_duration_reqd &&
        !this.profile.course_duration
      ) {
        let el = this.$el.getElementsByClassName("course_duration")[0];
        el.scrollIntoView(true);
        window.scrollBy(0, -140);
        this.cardHasError =
          "Qualification Course Duration can't be less than one year!";
      } else {
        if (this.edit_Qualification_uuid !== null) {
          let _count = 0;
          _.forEach(
            this.getCandidateQualification?.length
              ? this.getCandidateQualification
              : [],
            (qual, i) => {
              let qual_year = m(this.profile?.qualified_date).year();
              if (
                qual.qualification_id ===
                  this.profile.main_qualification_id.code &&
                m(qual.year_awarded).isSame(qual_year)
              ) {
                this._index = i;
                _count = _count + 1;
              }
            }
          );
          if (
            _count >= 1 &&
            this.getCandidateQualification[this._index]
              .candidate_qualification_id !== this.edit_Qualification_uuid
          ) {
            this.cardHasError = "Qualification Already Exists!";
          } else {
            this.saveQualification();
          }
        } else {
          if (
            _.findIndex(
              this.getCandidateQualification?.length
                ? this.getCandidateQualification
                : [],
              (qual) => {
                let qual_year = m(this.profile?.qualified_date).year();
                return (
                  qual.qualification_id ===
                    this.profile.main_qualification_id.code &&
                  m(qual.year_awarded).isSame(qual_year)
                );
              }
            ) >= 0
          ) {
            this.cardHasError = "Qualification Already Exists!";
          } else {
            this.saveQualification();
          }
        }
      }
    },
    saveQualification() {
      this.payload = {
        country_id:
          this.profile.country_id !== null
            ? this.profile.country_id.code
            : null,
        qualification_id:
          this.profile.main_qualification_id !== null
            ? this.profile.main_qualification_id.code
            : null,
        year_awarded:
          this.profile.qualified_date !== null
            ? m(this.profile.qualified_date).year()
            : null,
        month_awarded:
          this.profile.qualified_date !== null
            ? m(this.profile.qualified_date).month() + 1
            : null,
        speciality_id: this.candidateProfile.speciality_id,
        sub_speciality_id: this.candidateProfile.sub_speciality_id,
        by_exam: this.profile.by_exam !== null ? this.profile.by_exam : null,
        other_qualification_name: this.mainQualificationIsOther
          ? this.profile.other_qualification_name
          : null,
        description: this.profile.description
          ? // && this.profile.main_qualification_id.allow_subject_input
            this.profile.description
          : null,
        document_ids: this.profile.document_ids?.length
          ? this.profile.document_ids
          : null,
      };
      if (this.payload) {
        this.createQualification();
      }
    },
    confirmMain() {
      this.deleteMainqualification = true;
      this.deleteConfirmModal.modalContent =
        "You already have a main qualification. Please delete that to add a new main qualification";
      this.deleteConfirmModal.modalColor = "primary";
      this.deleteConfirmModal.modalTitle = "Confirmation";
      this.deleteConfirmModal.buttons = ["Ok"];
      this.deleteConfirmModal.isShowPopup = true;
    },
    createQualification() {
      let appendAction;
      if (this.edit_Qualification_uuid == null) {
        this.payload = {
          ...this.payload,
          candidate_uid: this.profile.candidate_uid,
          candidate_uuid: this.profile.candidate_uuid,
        };
        appendAction = this.uploadQualification(this.payload);
      } else {
        let candidate_qualification_uuid = this.edit_Qualification_uuid;
        let qualificationPayload = this.payload;
        appendAction = this.updateQualification({
          candidate_qualification_uuid,
          qualificationPayload,
        });
      }
      Promise.all([appendAction]).then((res) => {
        this.discard();
      });
    },
    checkHasPGQualification() {
      const isPGQualification =
        this.getCandidateQualification?.some(
          ({ qualification }) =>
            qualification?.qualification_classification_sort_level >= 100
        ) || false;
        const { customer_uid, nurse_registration_type } = this.candidateProfile;
      if (!isPGQualification && nurse_registration_type?.length) {
        const data = {
          customer_uid: this.candidateProfile?.customer_uid,
          candidate_uid: this.candidateProfile?.candidate_uid,
          nurse_registration_type: null,
        };
        this.updateCandidate({
          data,
          customer_uid,
        });
      }
    },
    clearFields() {
      this.$nextTick(() => {
        this.$refs.qualification.reset();
        this.$refs.qualification.errors.clear;
      });
      this.cardHasError = "";
      this.tooltipHide();
      this.multiFormEdited({ qualification: false });
      this.multiFormEdited({ showError: false });
    },
    deleteQualification(data) {
      if (
        this.getCandidateQualification &&
        this.getCandidateQualification.length === 1
      ) {
        this.tooltipHide();
        (this.deleteConfirmModal.modalTitle = "Can't be Deleted"),
          (this.deleteConfirmModal.buttons = ["OK"]);
        this.deleteConfirmModal.modalContent =
          "Please add another Qualification to delete the selected entry!";
        this.deleteConfirmModal.isShowPopup = true;
      } else if (
        this.getCandidateQualification.filter(({ main }) => main === true)[0]
      ) {
        let main_id = this.getCandidateQualification.filter(
          ({ main }) => main === true
        )[0].qualification_id;
        let delete_id = data.qualification_id;
        if (main_id === delete_id) {
          this.tooltipHide();
          (this.deleteConfirmModal.modalTitle = "Alert !!"),
            (this.deleteConfirmModal.buttons = ["Cancel", "Confirm"]);
          this.deleteConfirmModal.modalContent =
            "Are you sure to delete this main qualification? This will reset Speciality and Sub Speciality!";
          this.deleteConfirmModal.isShowPopup = true;
          this.candidate_qualification_id = data.candidate_qualification_uuid;
        } else {
          this.tooltipHide();
          this.delete_Qualification = true;
          (this.deleteConfirmModal.modalTitle = "Delete Qualification"),
            (this.deleteConfirmModal.buttons = ["Cancel", "Confirm"]);
          this.deleteConfirmModal.modalContent = `Do you want to delete qualification - ${
            data.other_qualification_name
              ? `${data.other_qualification_name}`
              : `${data.qualification_name}`
          } ?`;
          this.candidate_qualification_id = data.candidate_qualification_uuid;
          this.deleteConfirmModal.isShowPopup = true;
        }
      }
    },
    async deletemodalCallBack(action) {
      if (action === "Confirm") {
        if (this.deleteMainqualification === true) {
          this.deleteMainqualification = false;
        } else {
          this.deleteCandidateQualification(this.candidate_qualification_id);
        }
      }
      this.candidate_qualification_id = "";
      this.discard();
      this.deleteConfirmModal.isShowPopup = false;
      this.delete_Qualification = false;
    },
    editQualification(data) {
      let el = this.$el.getElementsByClassName("qualificationInfo")[0];
      el.scrollIntoView(true);
      window.scrollBy(0, -200);
      this.profile = {
        ...this.profile,
        country_id:
          data.country_id != "--"
            ? {
                code: data.country_id,
                label: data.country_name,
              }
            : null,
        main_qualification_id: {
          code: data.qualification_id,
          label: data.qualification_with_acronym,
          by_exam: data.qualification.by_exam,
          allow_subject_input: data.qualification.allow_subject_input,
        },
        qualified_date:
          data.year_awarded != null && data.month_awarded != null
            ? data.month_awarded < 10
              ? `${data.year_awarded}-0${data.month_awarded}`
              : `${data.year_awarded}-${data.month_awarded}`
            : null,
        issued_by:
          data.issue_authority_name != "--" &&
          data.other_issue_authority != "--"
            ? {
                code:
                  data.issue_authority_id !== "Others"
                    ? data.issue_authority_id
                    : "Others",
                label:
                  data.issue_authority_name !== "Others"
                    ? data.issue_authority_name
                    : "Others - not listed",
              }
            : null,
        other_issue_authority:
          data.other_issue_authority !== "--"
            ? data.other_issue_authority
            : null,
        course_duration:
          data.course_duration !== "--" ? data.course_duration : null,
        distance_learning:
          data.distance_learning !== "--" ? data.distance_learning : null,
        by_exam: data.by_exam !== "--" ? data.by_exam : null,
        other_qualification_name: data.other_qualification_name,
        description: data.description !== "--" ? data.description : null,
        document_ids: data.document_ids,
      };
      !this.delete_Qualification
        ? (this.edit_Qualification_uuid = data.candidate_qualification_uuid)
        : null;
      this.fetchIssueAuthorityByQualification({
        qualification_id: data.qualification_id,
      });
      this.fetchQualificationByCountryAndCandidateType({
        country_id: data.country_id,
        candidate_type_id: this.candidateProfile?.candidate_type_id,
      });
    },
    handleSlider: debounce(function (value) {
      Vue.set(this.profile, "course_duration", value);
      Vue.set(this.payload, "course_duration", value);
      this.multiFormEdited["qualification"] = true;
    }, 500),
    tooltipHide() {
      this.$emit("tooltipHide");
    },
    formatDate(month, year) {
      if (month && year) {
        let date = month ? new Date(`${year},${month},01`) : "--";
        return m(date).format("MMM-YYYY");
      }
      return "--";
    },
    discard() {
      this.profile = {};
      this.edit_Qualification_id = null;
      this.clearFields();
      this.fetchQualificationByCountryAndCandidateType();
      this.fetchIssueAuthorityByQualification();
    },
    showLinkDocument() {
      this.linkDocument.candidate_qualification_id = this
        .edit_Qualification_uuid
        ? this.edit_Qualification_uuid
        : null;
      this.linkDocument.document_ids = this.profile.document_ids || [];
      this.linkDocument.label = this.profile.main_qualification_id.label;
      this.linkDocument.isShowPopup = true;
    },
    showDocumentModal(data) {
      this.linkDocument.candidate_qualification_id =
        data.candidate_qualification_id
          ? data.candidate_qualification_id
          : null;
      this.linkDocument.document_ids = data.document_ids || [];
      this.linkDocument.label = data.qualification_with_acronym;
      this.linkDocument.isShowPopup = true;
    },
    linkModalCallBack(action, value, candidate_qual_id) {
      if (action == true) {
        let qualificationPayload = {
          document_ids: value?.length ? value : null,
        };
        if (!candidate_qual_id) {
          this.profile.document_ids = value;
        } else {
          this.profile.document_ids = value;
          this.updateQualification({
            candidate_qualification_uuid: candidate_qual_id,
            qualificationPayload,
          }).then((res) => {
            this.fetchQualification();
            this.fetchUploadedDocument();
          });
        }
      }
      this.linkDocument.candidate_qual_id = null;
      this.linkDocument.isShowPopup = false;
    },
    preview(data) {
      let document_ext = data.document_ext?.document_ext;
      if (
        document_ext === "PNG" ||
        document_ext === "JPG" ||
        document_ext === "JPEG" ||
        document_ext === "GIF"
      ) {
        this.iframe.loaded = false;
        this.iframe.ispdf = false;
        this.iframe.isImg = true;
        this.iframe.src = "https://" + window.location.host + data.link_to_doc;
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (document_ext === "PDF") {
        this.iframe.isImg = false;
        this.iframe.loaded = false;
        this.iframe.pdf.ispdf = true;
        this.iframe.pdf.src = pdf.createLoadingTask(
          "https://" + window.location.host + data.link_to_doc
        );
        setTimeout(() => {
          this.iframe.pdf.src.promise.then((pdf) => {
            this.iframe.pdf.numPages = pdf.numPages;
          });
        }, 100);
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (
        document_ext === "TXT" ||
        document_ext === "EPS" ||
        document_ext === "TIF" ||
        document_ext === "TIFF" ||
        document_ext === "RAW"
      ) {
        this.showToast({
          class: "bg-danger text-white",
          message: "The selected file type is not available for Preview.",
        });
        this.iframe.src = "";
        this.iframe.loaded = false;
        this.iframe.isImg = false;
        this.iframe.pdf.ispdf = false;
        this.previewModal.isPreview = false;
        this.previewModal.isShowPopup = false;
      } else {
        this.iframe.isImg = false;
        this.iframe.ispdf = false;
        this.iframe.loaded = true;
        this.iframe.src =
          "https://view.officeapps.live.com/op/embed.aspx?src=https://" +
          window.location.host +
          data.link_to_doc +
          "";
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      }
    },
    previewModalCallBack(action) {
      this.previewModal.isPreview = false;
      this.previewModal.isShowPopup = false;
      this.iframe.src = "";
      this.iframe.loaded = false;
      this.iframe.isImg = false;
      this.iframe.pdf.ispdf = false;
    },
  },
  filters: {
    qualificationName(data) {
      if (data !== null)
        return data.qualification_name.includes("Other - not listed")
          ? `Other${
              data.other_qualification_name
                ? " - " + data.other_qualification_name
                : ""
            }`
          : data.qualification_with_acronym;
      return "--";
    },
  },
};
</script>

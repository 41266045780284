<template>
  <div class="uploadPhoto">
    <!-- Image previewer -->
    <CModal
      class="text-black popupfnt imageModel"
      :show.sync="darkModal"
      :close-on-backdrop="false"
      size="lg"
    >
      <!-- Cropper container -->
      <div v-if="imageSrc">
        <CRow>
          <CCol md="7" sm="12">
            <vue-cropper
              class="mr-2 w-100"
              ref="cropper"
              :guides="true"
              :aspect-ratio="aspectRation"
              :src="imageSrc"
              :modal="true"
              :minContainerWidth="300"
              :minContainerHeight="300"
              :viewMode="1"
            />
          </CCol>
          <CCol md="5" sm="12" class="cropped-image">
            <img class="ml-2 w-100 border" :src="croppedImageSrc" />
          </CCol>
        </CRow>
      </div>
      <!-- Cropped image previewer -->
      <template #header>
        <h6 class="modal-title">Upload Photo</h6>
        <CButtonClose @click="closeModal" class="text-black" />
      </template>
      <template #footer-wrapper>
        <div class="border-top d-flex py-3 mx-3">
          <div class="flex-grow-1 d-flex">
            <CButtonGroup class="ml-2">
              <CButton color="primary" variant="outline" @click="zoom(0.1)">
                <i class="fa fa-search-plus mr-1" />
              </CButton>
              <CButton color="primary" variant="outline" @click="zoom(-0.1)">
                <i class="fa fa-search-minus mr-1" />
              </CButton>
            </CButtonGroup>
          </div>
          <div class="d-flex">
            <CButton
              type="button"
              color="info"
              class="px-4 mx-2"
              @click="cropImage"
              >{{ "Crop" }}</CButton
            >
            <CButton
              type="button"
              color="success"
              class="px-4 mx-2"
              @click="apply"
              :disabled="!isCroped"
              >{{ "Apply" }}</CButton
            >
          </div>
        </div>
      </template>
    </CModal>
  </div>
</template>


<script>
import Vue from "vue";
import ProfileSection from "./ProfileSection";
import { mapActions, mapGetters } from "vuex";
import Select from "@/components/reusable/Fields/Select.vue";
import moment from "moment";

export default {
  mixins: [ProfileSection],
  props: ["fileData", "documentType", "isProfilePic"],
  data() {
    return {
      payload: {},
      imageSrc: "",
      croppedImageSrc: "",
      darkModal: false,
      data: {},
      isCroped: false,
      fileName: "",
    };
  },
  computed: {
    ...mapGetters(["uploaded"]),
    aspectRation() {
      return this.isProfilePic ? 1 / 1 : 0;
    },
  },
  watch: {
    uploaded() {
      if (this.uploaded) {
        this.payload = {};
        this.imageSrc = "";
        this.croppedImageSrc = "";
        this.darkModal = false;
        this.data = {};
        this.isCroped = false;
      }
    },
  },

  mounted() {
    this.setImage();
  },
  methods: {
    setImage() {
      const file = this.fileData;
      this.fileName = file.name;
      if (!file.type.includes("image/")) {
        this.$store.commit("SET_UPLOADED");
        alert("Please select an image file");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.imageSrc = event.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
      this.darkModal = true;
    },
    cropImage() {
      this.isCroped = true;
      const fileType = this.fileData.type;
      // Get image data for post processing, e.g. upload or setting image src
      this.croppedImageSrc = this.$refs.cropper
        .getCroppedCanvas()
        .toDataURL(`'${fileType}'`);
    },
    closeModal() {
      this.payload = {};
      this.imageSrc = "";
      this.croppedImageSrc = "";
      this.darkModal = false;
      this.data = {};
      this.isCroped = false;
      this.$emit("closeModal");
    },
    apply() {
      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        this.data = this.$refs.cropper;
        this.data.croppedImageSrc = this.croppedImageSrc;
        this.payload = {
          name: ["file"],
          value: this.data,
          document_type: this.isProfilePic
            ? 2
            : this.documentType
            ? this.documentType.code
            : 1,
          document_name: this.fileName ? this.fileName : "avatar.png",
          isPhotoUpload: true,
          comments: this.doc_comment ? this.doc_comment : null,
          blobData: blob,
        };
        this.darkModal = false;
        this.$emit("upload", this.payload);
      })
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },
  },
};
</script>



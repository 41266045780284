<template>
  <div class="candidate-profile container-md col-lg-8 col-md-8 responsiveipad">
    <LoadingOverlay v-if="!isfetch" />
    <div v-else>
      <div
        class="d-flex align-items-center justify-content-between responsiveTitle"
      >
        <h3 class="pb-2 main-title">
          <span class="h5 d-block mb-1">Hello {{ profile.first_name }},</span>
          <span class="d-block">Your Profile</span>
        </h3>
        <div class="w-100 d-flex justify-content-start sticky-header">
          <Stepper
            :options="stepperOptions"
            :activeBlock="block"
            ref="step"
            :goTo="goTo"
          ></Stepper>
        </div>
      </div>
      <div v-if="candidateProfile">
        <span v-if="block === 0">
          <ProfessionalInfo
            ref="professionalInfo"
            @tooltipHide="tooltipHide"
          ></ProfessionalInfo>
          <div
            class="row-sm border-top pt-4 text-center my-3 action-group pb-5 mb-4"
          >
            <div v-if="isMobile">
              <button
                @click="gotoStep(1)"
                name="next_page"
                class="col-sm-1 btn rounded-0 btn-primary btn-lg mt-2 ml-2"
              >
                Next
              </button>
            </div>

            <button
              class="col-sm-1 btn rounded-0 btn-outline-primary btn-lg float-left mt-2 ml-2"
              @click="navigateToHome()"
            >
              Home
            </button>
            <div v-if="!isMobile">
              <button
                @click="gotoStep(1)"
                name="next_page"
                class="col-sm-1 btn rounded-0 btn-primary btn-lg mt-2 ml-2"
              >
                Next
              </button>
            </div>
          </div>
        </span>
        <span v-if="block === 1">
          <NonMedicalInfoTalentFind @tooltipHide="tooltipHide" />
          <LanguageInfo @tooltipHide="tooltipHide" />
          <div
            class="row-sm border-top pt-4 text-center my-3 action-group pb-5 mb-4"
          >
            <div v-if="isMobile">
              <button
                name="next_page"
                @click="gotoStep(2)"
                class="col-sm-1 btn rounded-0 btn-primary btn-lg mt-2 ml-2"
              >
                Next
              </button>
              <button
                class="col-sm-1 btn rounded-0 btn-outline-primary btn-lg mt-2 ml-2"
                @click="gotoStep(0)"
              >
                Back
              </button>
            </div>
            <button
              class="col-sm-1 btn rounded-0 btn-outline-primary btn-lg float-left mt-2 ml-2"
              @click="navigateToHome()"
            >
              Home
            </button>
            <div v-if="!isMobile">
              <button
                class="col-sm-1 btn rounded-0 btn-outline-primary btn-lg mt-2 ml-2"
                @click="gotoStep(0)"
              >
                Back
              </button>
              <button
                name="next_page"
                @click="gotoStep(2)"
                class="col-sm-1 btn rounded-0 btn-primary btn-lg mt-2 ml-2"
              >
                Next
              </button>
            </div>
          </div>
        </span>
        <span v-if="block === 2">
          <MultipleFileUpload
            ref="multipleFileUpload"
            :profile="profile"
            @upload="upload"
          ></MultipleFileUpload>
          <div
            class="row-sm border-top pt-4 text-center my-3 action-group pb-5 mb-4"
          >
            <div v-if="isMobile">
              <button
                @click="gotoStep(3)"
                name="next_page"
                class="col-sm-1 btn rounded-0 btn-primary btn-lg mt-2 ml-2"
              >
                Next
              </button>
              <button
                class="col-sm-1 btn rounded-0 btn-outline-primary btn-lg mt-2 ml-2"
                @click="gotoStep(1)"
              >
                Back
              </button>
            </div>
            <button
              class="col-sm-1 btn rounded-0 btn-outline-primary btn-lg float-left mt-2 ml-2"
              @click="navigateToHome()"
            >
              Home
            </button>
            <div v-if="!isMobile">
              <button
                class="col-sm-1 btn rounded-0 btn-outline-primary btn-lg mt-2 ml-2"
                @click="gotoStep(1)"
              >
                Back
              </button>
              <button
                @click="gotoStep(3)"
                name="next_page"
                class="col-sm-1 btn rounded-0 btn-primary btn-lg mt-2 ml-2"
              >
                Next
              </button>
            </div>
          </div>
        </span>
        <span v-if="block === 3">
          <AvailabilityInfo
            ref="availabilityInfo"
            :profile="profile"
            @tooltipHide="tooltipHide"
          ></AvailabilityInfo>
          <!-- <AvailabilityWeeklyInfo :profile="profile" @change="update"></AvailabilityWeeklyInfo> -->
          <div
            class="row-sm border-top pt-4 text-center my-3 action-group pb-5 mb-4"
          >
            <div v-if="isMobile">
              <button
                @click="gotoStep(4)"
                name="next_page"
                class="col-sm-1 btn rounded-0 btn-primary btn-lg mt-2 ml-2"
              >
                Next
              </button>
              <button
                class="col-sm-1 btn rounded-0 btn-outline-primary btn-lg mt-2 ml-2"
                @click="gotoStep(2)"
              >
                Back
              </button>
            </div>
            <button
              class="col-sm-1 btn rounded-0 btn-outline-primary btn-lg float-left mt-2 ml-2"
              @click="navigateToHome()"
            >
              Home
            </button>
            <div v-if="!isMobile">
              <button
                class="col-sm-1 btn rounded-0 btn-outline-primary btn-lg mt-2 ml-2"
                @click="gotoStep(2)"
              >
                Back
              </button>
              <button
                @click="gotoStep(4)"
                name="next_page"
                class="col-sm-1 btn rounded-0 btn-primary btn-lg mt-2 ml-2"
              >
                Next
              </button>
            </div>
          </div>
        </span>
        <span v-if="block === 4">
          <ContactInfo
            ref="contactInfo"
            @tooltipHide="tooltipHide"
          ></ContactInfo>
          <div
            class="row-sm border-top pt-4 text-center my-3 action-group pb-5 mb-4"
          >
            <div v-if="isMobile">
              <button
                name="next_page"
                class="col-sm-1 btn rounded-0 btn-primary btn-lg ml-2 mt-2"
                @click="gotoStep(5)"
              >
                Next
              </button>
              <button
                @click="gotoStep(3)"
                class="col-sm-1 btn rounded-0 btn-outline-primary btn-lg ml-2 mt-2"
              >
                Back
              </button>
            </div>

            <button
              class="col-sm-1 btn rounded-0 btn-outline-primary btn-lg float-left ml-2 mt-2"
              @click="navigateToHome()"
            >
              Home
            </button>
            <div v-if="!isMobile">
              <button
                @click="gotoStep(3)"
                class="col-sm-1 btn rounded-0 btn-outline-primary btn-lg ml-2 mt-2"
              >
                Back
              </button>
              <button
                name="next_page"
                class="col-sm-1 btn rounded-0 btn-primary btn-lg ml-2 mt-2"
                @click="gotoStep(5)"
              >
                Next
              </button>
            </div>
          </div>
        </span>
        <span v-if="block === 5">
          <PersonalInfo
            :profilePhoto="profilePhoto"
            ref="personalInfo"
            @upload="photoUpload"
            @tooltipHide="tooltipHide"
          ></PersonalInfo>
          <div
            class="row-sm border-top pt-4 text-center my-3 mr-3 action-group pb-5 mb-4"
          >
            <div v-if="isMobile">
              <button
                class="col-sm-1 btn rounded-0 btn-primary btn-lg mt-2 ml-2"
                @click="gotoStep(6)"
              >
                Next
              </button>
              <button
                class="col-sm-1 btn rounded-0 btn-outline-primary btn-lg mt-2 ml-2"
                @click="gotoStep(4)"
              >
                Back
              </button>
            </div>
            <button
              class="col-sm-1 btn rounded-0 btn-outline-primary btn-lg float-left ml-2 mt-2"
              @click="navigateToHome()"
            >
              Home
            </button>
            <div v-if="!isMobile">
              <button
                class="col-sm-1 btn rounded-0 btn-outline-primary btn-lg mt-2 ml-2"
                @click="gotoStep(4)"
              >
                Back
              </button>
              <button
                class="col-sm-1 btn rounded-0 btn-primary btn-lg mt-2 ml-2"
                @click="gotoStep(6)"
              >
                Next
              </button>
            </div>
          </div>
        </span>
        <span v-if="block === 6">
          <GDPRStatement
            ref="gdprStatement"
            @tooltipHide="tooltipHide"
          ></GDPRStatement>
          <div
            class="row-sm border-top pt-4 text-center my-3 action-group pb-5 mb-4"
          >
            <div v-if="isMobile">
              <button
                @click="navigateToHome()"
                class="col-sm-1 btn rounded-0 btn-primary btn-lg mt-2 ml-2"
                name="done_page"
              >
                Done
              </button>
              <button
                class="col-sm-1 btn rounded-0 btn-outline-primary btn-lg mt-2 ml-2"
                @click="gotoStep(5)"
              >
                Back
              </button>
            </div>
            <button
              class="col-sm-1 btn rounded-0 btn-outline-primary btn-lg float-left mt-2 ml-2"
              @click="navigateToHome()"
            >
              Home
            </button>
            <div v-if="!isMobile">
              <button
                class="col-sm-1 btn rounded-0 btn-outline-primary btn-lg mt-2 ml-2"
                @click="gotoStep(5)"
              >
                Back
              </button>
              <button
                class="col-sm-1 btn rounded-0 btn-primary btn-lg mt-2 ml-2"
                name="done_page"
                @click="navigateToHome()"
              >
                Done
              </button>
            </div>
          </div>
        </span>
      </div>
      <CToaster v-if="toast" :autohide="toastHide" position="bottom-right">
        <CToast :class="toastClass" :show="true">{{ toastMessage }}</CToast>
      </CToaster>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Stepper from "@/components/reusable/bars/stepper.vue";
import PersonalInfo from "@/containers/CandidateProfile/PersonalInfoTalentFind";
import ResumeUpload from "@/containers/CandidateProfile/ResumeUpload";
import MultipleFileUpload from "@/containers/CandidateProfile/MultipleFileUploadTalentFind";
import ContactInfo from "@/containers/CandidateProfile/ContactInfoTalentFind";
import ProfessionalInfo from "@/containers/CandidateProfile/ProfessionalInfoTalentFind";
import AvailabilityInfo from "@/containers/CandidateProfile/AvailabilityInfoTalentFind";
import LanguageInfo from "@/containers/CandidateProfile/LanguageInfoTalentFind";
import NonMedicalInfoTalentFind from "@/containers/CandidateProfile/NonMedicalInfoTalentFind";
import SkillInfo from "@/containers/CandidateProfile/SkillInfoTalentfind";
import PQRMatchInfo from "@/containers/CandidateProfile/PQRMatch";
import GDPRStatement from "@/containers/CandidateProfile/T&CandGDPRStatement";
import { BASE_URL } from "@/service_urls";

export default {
  components: {
    Stepper,
    PersonalInfo,
    ResumeUpload,
    MultipleFileUpload,
    ContactInfo,
    ProfessionalInfo,
    AvailabilityInfo,
    SkillInfo,
    PQRMatchInfo,
    GDPRStatement,
    LanguageInfo,
    NonMedicalInfoTalentFind,
  },
  data() {
    return {
      isMobile: false,
      block: this.$store.state.candidate.block,
      steps: null,
      profilePhoto: {},
      stepperOptions: {
        headers: [
          {
            title: "",
            tooltipTitle: "Professional",
            tooltipPlacement: "bottom-start",
          },
          {
            title: "",
            tooltipTitle: "Non Medical Qualifications",
            tooltipPlacement: "bottom-start",
          },
          {
            title: "",
            tooltipTitle: "Documents",
            tooltipPlacement: "bottom-start",
          },
          {
            title: "",
            tooltipTitle: "Availability",
            tooltipPlacement: "bottom-start",
          },
          {
            title: "",
            tooltipTitle: "Contacts",
            tooltipPlacement: "bottom-start",
          },
          {
            title: "",
            tooltipTitle: "Personal",
            tooltipPlacement: "bottom-start",
          },
          {
            title: "",
            tooltipTitle: "Privacy & Terms",
            tooltipPlacement: "bottom-start",
          },
        ],
      },
      profile: {},
    };
  },
  computed: {
    ...mapGetters([
      "toast",
      "toastMessage",
      "toastClass",
      "toastHide",
      "candidateProfile",
      "getUploadedDocument",
      "getMultiFormEdited",
      "getCustomerId",
    ]),
    document() {
      return (
        this.getUploadedDocument &&
        this.getUploadedDocument.length &&
        this.getUploadedDocument.map(
          ({ document_type_id, uuid_link_to_doc, document_name, document_type }) => {
            return {
              link: uuid_link_to_doc,
              type: document_type_id,
              name: document_name,
            };
          }
        )
      );
    },
    getProfilePhoto() {
      const profilePic =
        this.document &&
        this.document.length &&
        this.document
          .filter((e) => e.type === 2)
          .map((data) => {
            return data.link.replace("/api/v1/", "");
          });
      return profilePic && profilePic.length && `${BASE_URL}/${profilePic}`;
    },

    isfetch() {
      const profile = this.candidateProfile;
      if (this.candidateProfile != null) {
        this.profile = {
          ...profile,
          gender:
            profile.gender != null
              ? { code: profile.gender.gender_id, label: profile.gender.gender }
              : null,
          nationality: profile.nationality
            ? {
                nationality: profile.nationality.nationality,
                label: profile.nationality.country_name,
                code: profile.nationality.country_id,
              }
            : null,
          dob: profile.dob ? new Date(profile.dob) : null,
          location: profile.location
            ? {
                label: profile.location.comments,
                code: profile.location.location_id,
                country: profile.location.country
                  ? profile.location.country.country_name
                  : null,
              }
            : null,
          candidate_type: profile.candidate_type
            ? {
                label: profile.candidate_type.candidate_type,
                code: profile.candidate_type.candidate_type_id,
              }
            : null,
          speciality: profile.speciality
            ? {
                label: profile.speciality.speciality,
                code: profile.speciality.speciality_id,
              }
            : null,
          sub_speciality: profile.sub_speciality
            ? {
                label: profile.sub_speciality.sub_speciality,
                code: profile.sub_speciality.sub_speciality_id,
              }
            : null,
          current_level: profile.sub_speciality
            ? {
                label: profile.sub_speciality.sub_speciality,
                code: profile.sub_speciality.sub_speciality_id,
              }
            : null,
          main_qualification: profile.main_qualification
            ? {
                label: profile.main_qualification.qualification,
                code: profile.main_qualification.qualification_id,
              }
            : [],
          candidate_level: profile.candidate_level
            ? {
                label: profile.candidate_level.candidate_level,
                code: profile.candidate_level.level_id,
              }
            : null,
          preferred_locations: profile.preferred_locations
            ? profile.preferred_locations.map((l) => {
                return {
                  label: l.preferred_location.location,
                  code: l.preferred_location_id,
                };
              })
            : null,
        };
        return Object.keys(this.profile).length > 0;
      }
      return false;
    },
    isNurse() {
      return this.profile?.candidate_type_id === 2 || false;
    },
  },
  mounted() {
    if (this.$el.getElementsByClassName("d-block")[0]) {
      let el = this.$el.getElementsByClassName("d-block")[0];
      el.scrollIntoView({block: 'nearest'})
    }
    let appendAction;
    const id = this.$route.query ? this.$route.query.id : null;
    if (id) {
      appendAction = this.getCandidate({ customer: this.getCustomerId, id });
    } else {
      appendAction = this.getUserMe({ customer: this.getCustomerId });
    }
    Promise.all([appendAction]).then((res) => {
      this.initRegisterFetchOptions();
      this.initFetchOptions();
      this.fetchUploadedDocument();
      this.fetchSkills();
      this.fetchCandidateLanguage();
      this.fetchQualification();
      this.fetchspecialInterest();
      this.fetchPQRLevels();
      this.fetchCandidatePreferredRegionList();
      this.fetchCandidatePreferredCountryList();
      this.fetchCandidateNonMedicalQuals();
      if (this.isNurse) {
        this.fetchDepartments(this.profile);
        this.fetchPreferredDepartments();
      }
      this.fetchNurseRegistrationType({ customer_id: this.getCustomerId });
    });
    let isMobile = window.matchMedia("only screen and (max-width: 400px)")
      .matches;
    this.isMobile = isMobile;
  },
  updated() {
    return this.$store.state.candidate.block
  },
  methods: {
    ...mapActions([
      "initRegisterFetchOptions",
      "initFetchOptions",
      "fetchUploadedDocument",
      "getCandidate",
      "getUserMe",
      "uploadPhoto",
      "uploadResume",
      "showToast",
      "fetchSkills",
      "fetchCandidateLanguage",
      "fetchQualification",
      "fetchspecialInterest",
      "fetchPQRLevels",
      "fetchCandidatePreferredRegionList",
      "fetchCandidatePreferredCountryList",
      "multiFormEdited",
      "fetchCandidateNonMedicalQuals",
      "fetchPreferredDepartments",
      "fetchDepartments",
      "fetchNurseRegistrationType"
    ]),
    gotoStep(step) {
      this.multiFormEdited({ showError: false });
      let showError = false;
      for (const [key, value] of Object.entries(this.getMultiFormEdited)) {
        value ? (showError = true) : null;
      }
      if (showError) {
        if (this.getMultiFormEdited.fieldValidateError) {
          this.multiFormEdited({ personalInformation: true });
        }
        this.multiFormEdited({ showError: true });
      } else {
        this.block = step;
        this.$store.dispatch("scrollTop");
      }
    },
    navigateToHome() {
      this.multiFormEdited({ showError: false });
      let showError = false;
      for (const [key, value] of Object.entries(this.getMultiFormEdited)) {
        value ? (showError = true) : null;
      }
      if (showError) {
        if (this.getMultiFormEdited.fieldValidateError) {
          this.multiFormEdited({ personalInformation: true });
        }
        this.multiFormEdited({ showError: true });
      } else {
        this.$router.push("/home");
      }
    },
    reset() {
      this.block = 0;
    },
    goTo(event, obj) {
      this.multiFormEdited({ showError: false });
      let currentBlock = this.block;
      let showError = false;
      for (const [key, value] of Object.entries(this.getMultiFormEdited)) {
        value ? (showError = true) : null;
      }
      if (showError) {
        if (this.getMultiFormEdited.fieldValidateError) {
          this.multiFormEdited({ personalInformation: true });
        }
        this.multiFormEdited({ showError: true });
        setTimeout(() => {
          obj.currentPosition = this.block;
        }, 200);
      } else {
        this.slideTo(event, obj);
      }
    },
    slideTo(event, obj) {
      this.block = event;
    },
    goToProfile() {
      this.$router.push({ path: "/home" });
    },
    photoUpload(payload) {
      this.profilePhoto = payload;
      this.$store.commit("SET_UPLOADED");
      this.upload(payload);
    },
    upload(payload) {
      const document_type = payload.document_type || 1;
      const { customer_uid, candidate_uuid, user_id } = this.candidateProfile;
      if (payload && payload.name) {
        if (payload.isPhotoUpload) {
          this.uploadPhoto({
            customer_uid,
            candidate_uuid,
            user_id,
            [payload.name]: payload.value,
            document_type,
            ["fileName"]: payload.document_name,
          });
        } else
          this.uploadResume({
            customer_uid,
            candidate_uuid,
            user_id,
            [payload.name]: payload.value,
            document_type,
          });
      }
    },
    tooltipHide() {
      setTimeout(() => {
        let x = document.querySelectorAll(".tooltip");
        for (var i = 0; i < x.length; i++) {
          x[i].style.visibility = "hidden";
        }
      }, 100);
    },
  },
  created() {
    this.$store.commit("RESET_MULTI_FORM_EDITED");
  },
};
</script>
<style lang="scss" scoped>
.tooltip-inner {
  color: #fff !important;
}
.btn {
  min-width: 100px;
}
</style>

<template>
  <div class="star-rating">
    <label
      class="star-rating__star"
      v-for="(rating, index) in customRating(ratings)"
      :key="index"
      :class="{
        'is-selected': temp_value >= rating && temp_value != null,
        'is-disabled': disabled,
        'position-absolute half':checkHalfStar(rating),
      }"
      @click="setStar(rating)"
      v-on:mouseover="star_over(rating)"
      v-on:mouseout="star_out"
    >
      <input
        class="star-rating star-rating__checkbox"
        type="radio"
        :value="rating"
        :name="name"
        v-model="value"
        :disabled="disabled"
      />
      <i class="fas fa-star" v-if="!checkHalfStar(rating)"></i>
      <i class="fas fa-star-half" v-else></i>
      <!-- ★ -->
    </label>
  </div>
</template>
<script>
export default {
  props: {
    name: String,
    value: null,
    disabled: {
      type: Boolean,
      default: () => false,
    },
    isHalfstar: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      ratings: [1, 2, 3, 4, 5],
      temp_value: null,
    };
  },
  mounted() {
    this.temp_value = this.value;
  },
  methods: {
    star_over(index) {
      if (!this.disabled) {
        this.temp_value = index;
      }
    },
    star_out() {
      if (!this.disabled) {
        return (this.temp_value = this.value);
      }
    },
    setStar(value) {
      if (!this.disabled) {
        this.temp_value = value;
        this.$emit("change", this.name, this.temp_value);
        this.$emit("input", this.name, this.temp_value);
      }
    },
    customRating(data) {
      if (this.isHalfstar) {
        let halfStarRatings = [];
        data.forEach((element) => {
          halfStarRatings.push(element - 0.5);
          halfStarRatings.push(element);
        });
        return halfStarRatings;
      }
      return data;
    },
    checkHalfStar(star) {
      if ((star / 0.5) % 2 === 0) {
        return false;
      }
      return true;
    },
  },
};
</script>
<style lang="scss" scoped>
.star-rating__checkbox {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
.half{
  width: 12px;
}

.fa-star-half{
 width: 12px;
 overflow:hidden;
}

.star-rating__star {
  display: inline-block;
  padding: 3px;
  vertical-align: middle;
  line-height: 1;
  font-size: 1.5em;
  color: #ababab;
  -webkit-transition: color 0.2s ease-out;
  transition: color 0.2s ease-out;
}
.star-rating__star:hover {
  cursor: pointer;
}
.star-rating__star.is-selected {
  color: #ffd700;
}
.star-rating__star.is-disabled:hover {
  cursor: default;
}
</style>
<template>
<div class="resume-upload">
  <h5 class="py-3 px-1 mb-0 d-flex align-items-center">
    <span>Resume</span>
    <a v-if="document && document.link_to_doc" class="btn btn-light mx-2" @click="fileDownload">Download</a>
  </h5>
  <CCardGroup class="has-loading-overlay">
    <CCard class="p-2">
      <CCardBody>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmitStep1)">
            <CRow class="row mb-3">
              <label v-if="document && document.link_to_doc" class="required col-lg-3 col-md-12">Upload New Resume</label>
              <label v-else class="required col-lg-3 col-md-12">Upload Resume</label>
              <div class="col-lg-4 col-md-6 col-sm-12">
                <input type="file" ref="fileref" @change="handleFileUpload"
                        accept="image/jpg, image/png, image/jpeg, .DOCX, .docx, .PDF, .pdf"/>
              </div>
            </CRow>
          </form>
        </ValidationObserver>
      </CCardBody>
    </CCard>
  </CCardGroup>
</div>
</template>

<script>
import ProfileSection from './ProfileSection'
import { mapActions, mapGetters } from 'vuex'

export default {
  mixins: [ProfileSection],
  computed: {
    ...mapGetters(['uploaded']),
    document(){
      if(this.profile && this.profile.documents && this.profile.documents.length){
        return this.profile.documents[this.profile.documents.length - 1]
      }
      return {}
    },
  },
  watch: {
    uploaded() {
      if (this.uploaded) {
        this.$refs.fileref.value = ''
      }
    }
  },
  methods: {
    ...mapActions(['downloadDocument']),
    fileDownload(){
      let { link_to_doc, document_name } = this.document;
      let url = link_to_doc.replace('/api/v1/','');
      this.downloadDocument({url, document_name})
      return false
    }
  }
}
</script>
<template>
  <div class="candidate-profile">
    <LoadingOverlay v-if="!isLicenseInfo" />
    <div v-else class="license-info">
      <LoadingOverlay v-if="isLoading" />
      <div v-if="cardError"></div>
      <div class="pb-1 cardError" :class="cardHasError ? 'card-error-msg' : ''">
        {{ cardHasError }}
      </div>
      <CCard
        class="mt-2 has-loading-overlay"
        :class="cardHasError ? 'card-has-error' : ''"
      >
        <CCardBody>
          <CRow>
            <div class="col-md-12">
              <div class="d-flex justify-content-between">
                <div class="mt-2">
                  <h5 class="main-title pb-3">License Information</h5>
                </div>
                <div class="d-flex justify-content-end">
                  <button
                    name="save"
                    class="hand-icon action-button"
                    v-c-tooltip="'Save'"
                    @click="updateLicenseInfo"
                  >
                    <CIcon
                      name="cil-save"
                      class="active"
                      style="margin-right: 20px"
                    />
                  </button>
                  <button
                    name="cancel"
                    class="hand-icon action-button ml-2"
                    v-if="getMultiFormEdited['licenseInformation']"
                    v-c-tooltip="'Cancel'"
                    @click="resetLicenseData(), clearFields()"
                  >
                    <CIcon name="cil-x-circle" style="margin-right: 20px" />
                  </button>
                </div>
              </div>
              <ValidationObserver ref="license" v-slot="{ handleSubmit }">
                <form
                  id="license"
                  @submit.prevent="handleSubmit(onSubmitStep1)"
                >
                  <CRow>
                    <CCol md="6">
                      <CRow class="row mb-3">
                        <label
                          name="lbl_local_license"
                          class="required col-lg-12 col-md-12"
                          >Local License / Eligibility
                        </label>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <RadioButton
                            name="local_license"
                            :value="profile.local_license"
                            :options="
                              options && options['boolean']
                                ? options['boolean']
                                : []
                            "
                            @change="handleChangeRadio"
                            :error="errors[0]"
                          />
                        </div>
                      </CRow>
                    </CCol>
                    <CCol md="6">
                      <CRow class="mb-3">
                        <label
                          name="lbl_relocate_family"
                          class="col-lg-12 col-md-12 required"
                          >Dataflow Complete?</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <RadioButton
                              name="dataflow_complete"
                              :value="profile.dataflow_complete"
                              :options="
                                options && options['boolean']
                                  ? options['boolean']
                                  : []
                              "
                              @change="handleChangeRadio"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                    <CCol md="6" v-if="profile.local_license">
                      <CRow class="mb-3">
                        <label
                          name="lbl_eligibility_letter_available_for"
                          class="required col-lg-12 col-md-12"
                          >License Authority</label
                        >
                        <div class="col infoicon-multi-select">
                          <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <Select
                              name="eligibility_letter_available_for"
                              @input="handleChangeMultiSelectAsString"
                              :value="profile.eligibility_letter_available_for"
                              :options="
                                options && options['eligibility']
                                  ? options['eligibility']
                                  : []
                              "
                              :taggable="false"
                              :multiple="true"
                              :clearable="true"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                        <div class="position-relative multi-select-icon-div">
                          <CIcon
                            name="cil-info"
                            class="hinticon-multi-select"
                            v-c-tooltip="'Select one or more'"
                          />
                        </div>
                      </CRow>
                    </CCol>
                    <CCol md="6" v-if="profile.local_license && isDoHAuthority">
                      <CRow class="row mb-3" ref="eligiblity">
                        <label
                          name="lbl_license_number"
                          class="col-lg-12 col-md-12"
                          >Local License / Eligibility Number</label
                        >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <ValidationProvider
                            ref="license_number"
                            v-slot="{ errors }"
                          >
                            <TextInput
                              name="license_number"
                              :value="profile.license_number"
                              @change="handleInputCustom"
                              @input="handleInputUpper"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                        </div>
                      </CRow>
                    </CCol>
                  </CRow>
                </form>
              </ValidationObserver>
            </div>
          </CRow>
        </CCardBody>
      </CCard>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import RadioButton from "@/components/reusable/Fields/RadioButton";
import TextInput from "@/components/reusable/Fields/TextInput";
import ProfileSection from "./ProfileSection";
import { mapActions, mapGetters } from "vuex";
import { extend } from "vee-validate";
import { required, regex } from "vee-validate/dist/rules";
import Vue from "vue";
extend("required", { ...required, message: "This field is required" });
export default {
  name: "DohLicenseInfoTalentFind",
  mixins: [ProfileSection],
  components: {
    RadioButton,
    TextInput,
  },
  data() {
    return {
      payload: {},
      profile: {},
      errors: [],
      cardHasError: "",
      cancel: false,
      licenseValidation: {
        is_license_valid: null,
        isValidating: false,
        isValid: null,
      },
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(["candidateProfile"]),
    options() {
      return {
        boolean: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
        eligibility: [
          { code: "DHA", label: "DHA" },
          { code: "MoH", label: "MoH" },
          { code: "DOH", label: "DOH" },
          { code: "DHCA", label: "DHCA" },
        ],
      };
    },
    isLicenseInfo() {
      if (Object.keys(this.candidateProfile).length) {
        return this.setLicenseData(this.candidateProfile);
      }
      return false;
    },
    cardError() {
      if (
        this.getMultiFormEdited.showError &&
        this.getMultiFormEdited.licenseInformation
      ) {
        this.cardHasError = "Please Save or Delete";
        if (this.getMultiFormEdited.fieldValidateError) {
          this.cardHasError = "Please fill mandatory fields";
        }
        let el = this.$el.getElementsByClassName("cardError")[0];
        el.scrollIntoView(true);
        el.scrollIntoView({ block: "center" });
        window.scrollBy(0, -200);
        return true;
      }
      return false;
    },
    isDoHAuthority() {
      return (
        this.profile?.eligibility_letter_available_for?.filter(
          (v) => v.code === "DOH"
        ).length || false
      );
    },
  },
  methods: {
    ...mapActions(["updateCandidate", "showToast", "checkLicenseValidation"]),
    handleInputUpper(name, value) {
      const upperCase = _.toUpper(value);
      Vue.set(this.profile, name, upperCase);
      Vue.set(this.payload, name, upperCase);
    },
    handleInputCustom(name, value) {
      const upperCase = _.toUpper(value);
      Vue.set(this.profile, name, value);
      Vue.set(this.payload, name, value);
      this.formEdited(name);
      if (name === "license_number" && value) {
        setTimeout(() => {
          const isValid = this.validateLicense(value);
        }, 10);
      }
    },
    validateLicense(LicenseNumber) {
      this.licenseValidation.is_license_valid = null;
      const isParternError =
        this.$refs.license_number?.failedRules?.license_rule || false;
      if (LicenseNumber && isParternError === false) {
        let licencePayload = {};
        const {
          dob: DateOfBirth,
          emirates_id: EmiratesID,
          passport_number: PassportNumber,
        } = this.candidateProfile;
        if (LicenseNumber)
          licencePayload = { ...licencePayload, LicenseNumber };
        if (DateOfBirth) licencePayload = { ...licencePayload, DateOfBirth };
        if (EmiratesID) licencePayload = { ...licencePayload, EmiratesID };
        if (PassportNumber)
          licencePayload = { ...licencePayload, PassportNumber };
        this.licenseValidation.isValidating = true;
      }
    },
    setLicenseData(profileData) {
      this.profile = {
        customer_uid: profileData.customer_uid,
        candidate_uid: profileData.candidate_uid,
        local_license: profileData.local_license || false,
        license_number: profileData.license_number,
        dataflow_complete: profileData?.dataflow_complete,
        eligibility_letter_available_for:
          profileData?.eligibility_letter_available_for
            ? profileData?.eligibility_letter_available_for
                .split(",")
                ?.map((val) => {
                  return {
                    label: val,
                    code: val,
                  };
                })
            : [],
      };
      return this.profile;
    },
    tooltipHide() {
      setTimeout(() => {
        let x = document.querySelectorAll(".tooltip");
        for (var i = 0; i < x.length; i++) {
          x[i].style.visibility = "hidden";
        }
      }, 100);
    },
    async updateLicenseInfo() {
      this.$emit("tooltipHide");
      const isValid = await this.$refs.license.validate();
      const errors = Object.values(this.$refs.license.errors).flat();
      if (!isValid) {
        this.cardHasError = errors[0] || "Please fill mandatory fields";
        return;
      } else {
        this.isLoading = true;
        this.submitCandidate();
      }
    },
    waitFor() {
      this.licenseValidation.is_license_valid = this.licenseValidation.isValid;
      if (this.licenseValidation.isValidating) {
        setTimeout(() => {
          this.waitFor();
        }, 50);
      } else {
        this.isLoading = false;
        if (
          this.profile?.local_license === false ||
          this.licenseValidation.is_license_valid ||
          (this.isDoHAuthority && this.profile.license_number
            ? this.profile.license_number.length === 0
            : true)
        ) {
          this.submitCandidate();
        } else {
          this.showToast({
            class: "bg-danger text-white",
            message:
              "The given license is not valid; please input a valid license or select license as NO!",
          });
          window.scrollTo({ top: 0, behavior: "smooth" });
          const el = document.getElementsByName("license_number");
          if (el && el[0]) {
            el[0].focus();
          }
        }
      }
    },
    submitCandidate() {
      this.payload = {
        candidate_uid: this.profile?.candidate_uid,
        local_license: this.profile?.local_license,
        license_number:
          this.profile?.local_license && this.isDoHAuthority
            ? this.profile?.license_number
            : null,
        dataflow_complete: this.profile?.dataflow_complete,
        eligibility_letter_available_for: this.profile?.local_license
          ? this.profile?.eligibility_letter_available_for
              ?.map((v) => {
                return v.id || v.code || v;
              })
              .join(",")
          : "",
      };

      const { customer_uid } = this.profile;

      if (this.payload?.license_number) {
        const { is_license_valid } = this.licenseValidation;
        this.payload = { ...this.payload, is_license_valid };
      }
      this.updateCandidate({
        data: this.payload,
        customer_uid,
      }).then((res) => {
        this.clearFields();
        this.resetLicenseData();
        this.isLoading = false;
      });

      // const { customer_uid } = this.profile;
      // let appendAction = this.updateCandidate({
      //   data: this.payload,
      //   customer_uid,
      // });
      // Promise.all([appendAction]).then((res) => {
      //   this.clearFields();
      // });
    },
    resetLicenseData() {
      this.cancel = true;
      this.setLicenseData(this.candidateProfile);
      this.licenseValidation.is_license_valid =
        this.candidateProfile.is_license_valid;
    },
    clearFields() {
      this.cardHasError = "";
      this.multiFormEdited({ licenseInformation: false });
      this.multiFormEdited({ fieldValidateError: false });
      this.multiFormEdited({ showError: false });
      this.$emit("tooltipHide");
    },
  },
  mounted() {
    this.licenseValidation.isValid = this.candidateProfile.is_license_valid;
  },
};
</script>
<style lang="scss" scoped>
.fs-12 {
  font-size: 12px;
}
</style>
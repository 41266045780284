<template>
  <div class="candidate-profile">
    <div class="licenseInfo">
      <div v-if="cardError"></div>
      <div class="pb-1 cardError" :class="cardHasError ? 'card-error-msg' : ''">
        {{ cardHasError }}
      </div>
      <CCard class="mt-2" :class="cardHasError ? 'card-has-error' : ''">
        <CCardBody>
          <CRow>
            <div class="col-md-12">
              <div class="d-flex align-items-center justify-content-between">
                <div class="mt-2 mr-2">
                  <h5 class="pb-3 main-title">
                    License / Registration Information
                    <span class="h6"
                      >[{{ licenses.length || "0" }} record{{
                        licenses.length > 1 ? "s" : ""
                      }}]</span
                    >
                  </h5>
                </div>
              </div>
              <ValidationObserver
                ref="licenseInformation"
                v-slot="{ handleSubmit }"
              >
                <form id="licenseInformation" @submit.prevent="handleSubmit()">
                  <fieldset>
                    <CRow>
                      <CCol md="6">
                        <CRow class="row mb-3">
                          <label
                            name="lbl_country_id"
                            class="required col-lg-12 col-md-12"
                            >License Valid In</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <Select
                                name="license_country_id"
                                :value="profile.license_country_id"
                                :options="
                                  options && options.countryInfo
                                    ? options.countryInfo
                                    : []
                                "
                                @input="handleChangeSelect"
                                :taggable="false"
                                :multiple="false"
                                :clearable="false"
                                :error="errors[0]"
                              />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                      <CCol md="6">
                        <CRow class="row mb-3">
                          <label
                            name="lbl_license_authority"
                            class="required col-lg-12 col-md-12"
                            >Medical License Board</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <Select
                                name="issue_authority_id"
                                :value="profile.issue_authority_id"
                                :options="
                                  options && options['issue_authority_id']
                                    ? options['issue_authority_id']
                                    : []
                                "
                                @input="handleChangeSelect"
                                :taggable="false"
                                :multiple="false"
                                :clearable="true"
                                :error="errors[0]"
                              />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol md="6">
                        <CRow class="d-flex align-items-center mb-3">
                          <ValidationProvider name="dob" v-slot="{ errors }">
                            <TextInput
                              hidden
                              name="dob"
                              :value="profile.dob"
                              :error="errors[0]"
                            />
                          </ValidationProvider>
                          <label
                            name="lbl_issue_date"
                            class="col-lg-12 col-md-12"
                            >Issue / Registration Date</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              name="issue_date"
                              rules="IssueDate:@dob|date_validate"
                              v-slot="{ errors }"
                            >
                              <DateInput
                                :onlyMonthAndYear="true"
                                name="License_issue_date"
                                :value="profile.License_issue_date"
                                @change="handleDatePickerChange"
                                ref="license_issueDate"
                                :error="errors[0]"
                              />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                      <CCol md="6">
                        <CRow class="d-flex align-items-center mb-3">
                          <label
                            name="lbl_license_number"
                            class="col-lg-12 col-md-12"
                            >License Number</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <TextInput
                              name="license_number"
                              :value="profile.license_number"
                              @change="handleInput"
                            />
                          </div>
                        </CRow>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol md="6">
                        <CRow class="d-flex align-items-center mb-3">
                          <label
                            name="lbl_local_license"
                            class="col-lg-12 col-md-12"
                            >Is License Active?</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <RadioButton
                              name="local_license"
                              :value="profile.local_license"
                              :options="
                                options && options['local_license']
                                  ? options['local_license']
                                  : []
                              "
                              @change="handleChangeRadio"
                            />
                          </div>
                        </CRow>
                      </CCol>
                      <CCol md="6" class="p-0" v-if="!(isNurse || isAHP)">
                        <CRow class="d-flex align-items-center mb-3">
                          <CCol md="6">
                            <label
                              name="lbl_specialist_registration"
                              class="col-lg-12 col-md-12"
                              >Specialist Registration</label
                            >
                            <div class="col-lg-12 col-md-12 col-sm-12">
                              <RadioButton
                                name="specialist_registration"
                                :value="profile.specialist_registration"
                                :options="
                                  options && options['specialist_registration']
                                    ? options['specialist_registration']
                                    : []
                                "
                                @change="handleChangeRadio"
                                :error="errors[0]"
                              />
                            </div>
                          </CCol>
                          <CCol md="6" v-if="profile.specialist_registration">
                            <CRow class="d-flex align-items-center mb-3">
                              <label
                                name="lbl_Date_acquired"
                                class="required col-lg-12 col-md-12"
                                >Month / Year</label
                              >
                              <div class="col-lg-12 col-md-12 col-sm-12">
                                <ValidationProvider
                                  name="issue_date"
                                  rules="IssueDate:@dob|date_validate|required"
                                  v-slot="{ errors }"
                                >
                                  <DateInput
                                    :onlyMonthAndYear="true"
                                    name="date_acquired"
                                    :value="profile.date_acquired"
                                    @change="handleDatePickerChange"
                                    :error="errors[0]"
                                  />
                                </ValidationProvider>
                              </div>
                            </CRow>
                          </CCol>
                        </CRow>
                      </CCol>
                    </CRow>
                    <!-- <CButton
                      type="button"
                      v-if="profile.issue_authority_id"
                      class="btn-primary rounded-0 small"
                      @click="showLinkDocument()"
                    >
                      Link Document
                    </CButton> -->
                    <CRow>
                      <CCol>
                        <div class="float-right mb-2">
                          <CButton
                            v-if="
                              !edit_license_id &&
                              getMultiFormEdited['licenseInformation']
                            "
                            class="mr-2 btn-primary small"
                            style="height: 28px"
                            @click="uploadLicenseInfo"
                            v-c-tooltip="
                              'Click to Add multiple records!'
                            "
                            >Add</CButton
                          >
                          <CButton
                            class="mr-2 btn-primary small"
                            style="height: 28px"
                            @click="uploadLicenseInfo"
                            v-if="edit_license_id"
                            v-c-tooltip="
                              'Click to update the record!'
                            "
                            >Update</CButton
                          >
                          <button
                            name="cancel"
                            class="hand-icon action-button"
                            v-if="
                              edit_license_id ||
                              getMultiFormEdited['licenseInformation']
                            "
                            v-c-tooltip="'Cancel'"
                            @click="resetLicense(), clearFields()"
                          >
                            <CIcon
                              name="cil-x-circle"
                              style="margin-right: 20px"
                            />
                          </button>
                        </div>
                      </CCol>
                    </CRow>
                    <div v-if="licenses" class="multi-records">
                      <div
                        class="top-border d-flex justify-content-between"
                        v-for="(data, index) in licenses"
                        :key="index"
                      >
                        <div class="ml-2 mt-1 record">
                          <div class="widget-content-actions hide-large-screen">
                            <CButton
                              class="icon icon-edit mr-2"
                              v-if="data && data.candidate_license_id"
                              @click="editLicense(data)"
                            >
                            </CButton>
                            <CButton
                              class="icon mr-1"
                              v-if="data && data.candidate_license_id"
                              @click="deleteInfo(data)"
                            >
                              <i class="fas fa-trash-alt" />
                            </CButton>
                          </div>
                          <CRow
                            class="d-flex"
                            style="
                              justify-content: space-around;
                              margin-left: 2px;
                            "
                          >
                            <div class="table col col-4">
                              <p class="head">License Valid In</p>
                              <p class="data">{{ data.country_name }}</p>
                            </div>
                            <div class="table col col-4">
                              <p class="head">Medical License Board</p>
                              <p class="data">
                                {{ data.issue_authority_name }}
                              </p>
                            </div>
                            <div class="table col col-4">
                              <p class="head">Issue/Registration Date</p>
                              <p class="data">{{ data.issue_date }}</p>
                            </div>
                            <div class="table col col-4">
                              <p class="head">License Number</p>
                              <p class="data">{{ data.license_number }}</p>
                            </div>
                            <div class="table col col-4">
                              <p class="head">Is License Active</p>
                              <p class="data">{{ data.current_license }}</p>
                            </div>
                            <div
                              class="table col col-4"
                              v-if="!(isNurse || isAHP)"
                            >
                              <p class="head">Specialist Registration</p>
                              <p class="data">
                                {{ data.specialist_registration }}
                                <span
                                  v-if="data.specialist_registration === 'Yes'"
                                >
                                  ({{ formatDate(data.date_acquired) }})
                                </span>
                              </p>
                            </div>
                            <!-- <div class="table col col-4">
                              <p class="head">
                                Linked Documents
                                <a
                                  v-if="!edit_license_id"
                                  class="data text-primary"
                                  :style="{
                                    'font-size': '14px',
                                    cursor: 'pointer',
                                  }"
                                  @click="showDocumentModal(data)"
                                  >(Click here to Link)</a
                                >
                              </p>
                              <p
                                class="data"
                                v-if="
                                  data.document_ids && data.document_ids.length
                                "
                              >
                                <span
                                  ><a
                                    class="text-primary"
                                    style="cursor: pointer"
                                    v-for="(i, index) in data.documents"
                                    :key="index"
                                    @click="preview(i)"
                                    >{{
                                      index == 0
                                        ? i.document_name
                                        : `, ${i.document_name}`
                                    }}</a
                                  ></span
                                >
                              </p>
                              <p class="data" v-else>No Documents Linked</p>
                            </div> -->
                            <div class="table col col-4"></div>
                            <div class="table col col-4"></div>
                          </CRow>
                        </div>
                        <div class="hide-small-screen">
                          <div
                            class="widget-content-actions d-flex flex-column justify-content-center"
                          >
                            <CButton
                              class="icon icon-edit mb-2"
                              v-if="data && data.candidate_license_id"
                              @click="editLicense(data)"
                            >
                            </CButton>
                            <CButton
                              class="icon"
                              v-if="data && data.candidate_license_id"
                              @click="deleteInfo(data)"
                            >
                              <i class="fas fa-trash-alt" />
                            </CButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </ValidationObserver>
            </div>
          </CRow>
        </CCardBody>
      </CCard>
      <Modal
        :modalTitle="deleteConfirmModal.modalTitle"
        :modalColor="deleteConfirmModal.modalColor"
        :modalContent="deleteConfirmModal.modalContent"
        :isShowPopup="deleteConfirmModal.isShowPopup"
        :buttons="deleteConfirmModal.buttons"
        :modalCallBack="deletemodalCallBack"
        :closeOnBackdrop="false"
      />
      <DocumentMappingModal
        :isShowPopup="linkDocument.isShowPopup"
        :document_type_id="9"
        :label="linkDocument.label"
        :candidate_license_id="linkDocument.candidate_license_id"
        :document_ids="linkDocument.document_ids"
        @modalCallBack="linkModalCallBack"
      />
      <PreviewModal
        modalTitle="Document Preview"
        modalColor="primary"
        :buttons="['Ok']"
        size="lg"
        :isShowPopup="previewModal.isShowPopup"
        :modalCallBack="previewModalCallBack"
        :isPreview="previewModal.isPreview"
        :iframe="iframe"
        :closeOnBackdrop="false"
      />
    </div>
  </div>
</template>

<script>
import pdf from "vue-pdf";
import DocumentMappingModal from "./DocumentMappingModal.vue";
import PreviewModal from "../../components/reusable/PreviewModal.vue";
import ProfileSection from "./ProfileSection";
import Modal from "@/components/reusable/Modal";
import m from "moment";
import { mapActions, mapGetters } from "vuex";
import { extend, validate } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", { ...required, message: "This field is required" });
extend("IssueDate", {
  params: ["dob"],
  validate(value, { dob }) {
    let birth_date = new Date(dob);
    let input_date = new Date(value);
    let today_date = new Date();
    if (input_date > birth_date && input_date < today_date) {
      return true;
    }
  },
  message: "Issue date should be between birthdate & today!",
});
extend("date_validate", (value) => {
  if (m(value).isValid()) {
    return true;
  }
  return "Invalid date! Enter a valid date";
});

export default {
  name: "LicenseInfoTalentfind",
  mixins: [ProfileSection],
  components: {
    Modal,
    DocumentMappingModal,
    PreviewModal,
  },
  data() {
    return {
      payload: {},
      profile: {},
      errors: [],
      LicenseInfo: {},
      edit_license_id: null,
      candidate_license_id: null,
      deleteConfirmModal: {
        modalColor: "danger",
        modalTitle: "Delete Document",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
      },
      delete_license: false,
      licenseUpload: false,
      cardHasError: "",
      linkDocument: {
        isShowPopup: false,
        label: "",
        candidate_license_id: null,
        document_ids: [],
      },
      previewModal: {
        isShowPopup: false,
        isPreview: false,
      },
      iframe: {
        src: "",
        loaded: false,
        isImg: false,
        pdf: {
          ispdf: false,
          src: "",
          numPages: undefined,
          addContentClasses: "",
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      "getLicense",
      "uploaded",
      "licenseIssuedByCountry",
      "candidateProfile",
      "specialityByTypeAndCountry",
    ]),
    options() {
      return {
        issue_authority_id:
          this.licenseIssuedByCountry({
            country_id: this.profile?.license_country_id?.code,
          }) || [],
        countryInfo: this.locationCountryInfo || [],
        local_license: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
        specialist_registration: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
        licence:
          this.specialityByTypeAndCountry({
            candidateType: this.candidateProfile.candidate_type_id,
            country_id: this.payload.license_country_id,
          }) || [],
      };
    },
    licenses() {
      if (Object.keys(this.candidateProfile).length) {
        this.profile = {
          ...this.profile,
          candidate_uid: this.candidateProfile.candidate_uid,
          dob: this.candidateProfile.dob,
        };
      }
      return (
        this.getLicense?.length &&
        this.getLicense.map(
          ({
            candidate_license_id,
            license_number,
            country_id,
            country_name,
            issue_date,
            current_license,
            specialist_registration,
            speciality,
            issue_authority_id,
            issue_authority_name,
            specialist_registration_year,
            specialist_registration_month,
            document_ids,
            documents,
          }) => {
            return {
              candidate_license_id:
                candidate_license_id !== null ? candidate_license_id : "--",
              license_number: license_number !== null ? license_number : "--",
              license_country_id: country_id !== null ? country_id : "--",
              country_name: country_name !== null ? country_name : "--",
              issue_date: issue_date !== null ? issue_date : "--",
              current_license:
                current_license !== null
                  ? current_license
                    ? "Yes"
                    : "No"
                  : "--",
              specialist_registration:
                specialist_registration !== null
                  ? specialist_registration
                    ? "Yes"
                    : "No"
                  : "--",
              speciality_type_id:
                speciality !== null
                  ? speciality?.speciality_type?.speciality_type_id
                  : "--",
              speciality_type:
                speciality !== null
                  ? speciality?.speciality_type?.speciality_type
                  : "--",
              issue_authority_id:
                issue_authority_id !== null ? issue_authority_id : "--",
              issue_authority_name:
                issue_authority_name !== null ? issue_authority_name : "--",
              date_acquired:
                specialist_registration_month && specialist_registration_year
                  ? `${specialist_registration_year}-${specialist_registration_month}`
                  : null,
              document_ids: document_ids,
              documents: documents,
            };
          }
        )
      );
    },
    cardError() {
      if (
        this.getMultiFormEdited.showError &&
        this.getMultiFormEdited.licenseInformation
      ) {
        this.cardHasError = "Please Save or Delete";
        let el = this.$el.getElementsByClassName("cardError")[0];
        el.scrollIntoView(true);
        el.scrollIntoView({ block: "center" });
        window.scrollBy(0, -200);
        return true;
      }
      return false;
    },
    isNurse() {
      return this.candidateProfile?.candidate_type_id === 2 || false;
    },
    isAHP() {
      return this.candidateProfile?.candidate_type_id === 3 || false;
    },
  },
  watch: {
    uploaded() {
      if (this.uploaded) {
        if (this.licenseUpload) {
          this.fetchLicense();
        }
      }
    },
  },
  methods: {
    ...mapActions([
      "fetchLicense",
      "uploadLicense",
      "deleteCandidateLicense",
      "updateLicense",
      "fetchUploadedDocument",
    ]),
    async uploadLicenseInfo() {
      this.tooltipHide();
      const isValid = await this.$refs.licenseInformation.validate();
      this.errors = Object.values(this.$refs.licenseInformation.errors).flat();
      if (!isValid) {
        let el = this.$el.getElementsByClassName("has-error")[0];
        el.scrollIntoView(true);
        window.scrollBy(0, -60);
        this.cardHasError = this.errors[0] || "please fill mandatory fields";
        return;
      } else {
        let issueAuthorityExist = 0;
        let issue_auth_code = this.profile?.issue_authority_id?.code;
        this.getLicense?.forEach((val) => {
          val?.issue_authority_id == issue_auth_code
            ? (issueAuthorityExist = issueAuthorityExist + 1)
            : null;
          if (val.candidate_license_id == this.edit_license_id) {
            if (val?.issue_authority_id == issue_auth_code)
              issueAuthorityExist
                ? (issueAuthorityExist = issueAuthorityExist - 1)
                : null;
          }
        });
        if (issueAuthorityExist) {
          this.cardHasError = "Requested Medical License Board already exist!";
          return;
        }
        this.saveLicenseInfo();
      }
    },
    clearFields() {
      this.licenseUpload = false;
      this.$nextTick(() => {
        this.$refs.licenseInformation?.errors.clear;
        this.$refs.licenseInformation.reset();
      });
      this.cardHasError = "";
      this.multiFormEdited({ showError: false });
    },
    deleteInfo(data) {
      if (this.getLicense && this.getLicense.length === 1) {
        this.tooltipHide();
        (this.deleteConfirmModal.modalTitle = "Can't be Deleted"),
          (this.deleteConfirmModal.buttons = ["OK"]),
          (this.deleteConfirmModal.modalContent =
            "Please add another License to delete the selected entry!");
        this.deleteConfirmModal.isShowPopup = true;
      } else {
        this.tooltipHide();
        this.delete_license = true;
        (this.deleteConfirmModal.modalTitle = "Delete License"),
          (this.deleteConfirmModal.buttons = ["Cancel", "Confirm"]);
        this.deleteConfirmModal.modalContent = `Do you want to delete this License Info  - ${data.country_name} - ${data.issue_authority_name} ?`;
        this.candidate_license_id = data.candidate_license_id;
        this.deleteConfirmModal.isShowPopup = true;
      }
    },
    deletemodalCallBack(action) {
      if (action === "Confirm") {
        this.deleteCandidateLicense(this.candidate_license_id);
      }
      this.candidate_license_id = null;
      this.resetLicense();
      this.clearFields();
      this.deleteConfirmModal.isShowPopup = false;
    },
    editLicense(data) {
      let el = this.$el.getElementsByClassName("licenseInfo")[0];
      el.scrollIntoView(true);
      window.scrollBy(0, -200);
      this.profile = {
        ...this.profile,
        license_number:
          data.license_number !== "--" ? data.license_number : null,
        license_country_id: {
          code: data.license_country_id,
          label: data.country_name,
        },
        License_issue_date: data.issue_date !== "--" ? data.issue_date : null,
        issue_authority_id: {
          code:
            data.issue_authority_id !== "--" ? data.issue_authority_id : null,
          label:
            data.issue_authority_name !== "--"
              ? data.issue_authority_name
              : null,
        },
        local_license:
          data.current_license !== "--"
            ? data.current_license === "Yes"
              ? true
              : false
            : null,
        specialist_registration:
          data.specialist_registration !== "--"
            ? data.specialist_registration === "Yes"
              ? true
              : false
            : null,
        date_acquired: data.date_acquired !== "--" ? data.date_acquired : null,
        document_ids: data.document_ids,
      };
      !this.delete_license
        ? (this.edit_license_id = data.candidate_license_id)
        : null;
    },
    showLinkDocument() {
      this.linkDocument.candidate_license_id = this.edit_license_id
        ? this.edit_license_id
        : null;
      this.linkDocument.document_ids = this.profile.document_ids || [];
      this.linkDocument.label = this.profile.issue_authority_id.label;
      this.linkDocument.isShowPopup = true;
    },
    showDocumentModal(data) {
      this.linkDocument.candidate_license_id = data.candidate_license_id
        ? data.candidate_license_id
        : null;
      this.linkDocument.document_ids = data.document_ids || [];
      this.linkDocument.label = data.issue_authority_name;
      this.linkDocument.isShowPopup = true;
    },
    linkModalCallBack(action, value, candidate_license_id) {
      if (action == true) {
        if (!candidate_license_id) {
          this.profile.document_ids = value;
        } else {
          this.profile.document_ids = value;
          let payload = {
            candidate_uid: this.profile.candidate_uid,
            candidate_license_id: candidate_license_id,
            document_ids: value?.length ? value : null,
          };
          this.updateLicense(payload).then((res) => {
            this.fetchLicense();
          });
        }
      }
      this.linkDocument.candidate_license_id = null;
      this.linkDocument.isShowPopup = false;
    },
    preview(data) {
      let document_ext = data.document_ext?.document_ext;
      if (
        document_ext === "PNG" ||
        document_ext === "JPG" ||
        document_ext === "JPEG" ||
        document_ext === "GIF"
      ) {
        this.iframe.loaded = false;
        this.iframe.ispdf = false;
        this.iframe.isImg = true;
        this.iframe.src = "https://" + window.location.host + data.link_to_doc;
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (document_ext === "PDF") {
        this.iframe.isImg = false;
        this.iframe.loaded = false;
        this.iframe.pdf.ispdf = true;
        this.iframe.pdf.src = pdf.createLoadingTask(
          "https://" + window.location.host + data.link_to_doc
        );
        setTimeout(() => {
          this.iframe.pdf.src.promise.then((pdf) => {
            this.iframe.pdf.numPages = pdf.numPages;
          });
        }, 100);
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (
        document_ext === "TXT" ||
        document_ext === "EPS" ||
        document_ext === "TIF" ||
        document_ext === "TIFF" ||
        document_ext === "RAW"
      ) {
        this.showToast({
          class: "bg-danger text-white",
          message: "The selected file type is not available for Preview.",
        });
        this.iframe.src = "";
        this.iframe.loaded = false;
        this.iframe.isImg = false;
        this.iframe.pdf.ispdf = false;
        this.previewModal.isPreview = false;
        this.previewModal.isShowPopup = false;
      } else {
        this.iframe.isImg = false;
        this.iframe.ispdf = false;
        this.iframe.loaded = true;
        this.iframe.src =
          "https://view.officeapps.live.com/op/embed.aspx?src=https://" +
          window.location.host +
          data.link_to_doc +
          "";
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      }
    },
    previewModalCallBack(action) {
      this.previewModal.isPreview = false;
      this.previewModal.isShowPopup = false;
      this.iframe.src = "";
      this.iframe.loaded = false;
      this.iframe.isImg = false;
      this.iframe.pdf.ispdf = false;
    },
    saveLicenseInfo() {
      this.licenseUpload = true;
      let specialist_registration_year = null;
      let specialist_registration_month = null;
      if (this.profile.date_acquired) {
        let date_acquired = this.profile.date_acquired.split("-");
        specialist_registration_year = date_acquired[0];
        specialist_registration_month = date_acquired[1];
      }
      this.LicenseInfo = {
        candidate_uid: this.profile?.candidate_uid,
        license_number: this.profile?.license_number,
        country_id:
          this.profile?.license_country_id !== null
            ? this.profile?.license_country_id?.code
            : null,
        issue_date: this.profile?.License_issue_date
          ? this.profile?.License_issue_date
          : null,
        speciality_id: this.candidateProfile.speciality_id,
        sub_speciality_id: this.candidateProfile.sub_speciality_id,
        issue_authority_id: this.profile.issue_authority_id
          ? this.profile?.issue_authority_id?.code
          : null,
        current_license:
          this.profile.local_license !== null
            ? this.profile.local_license
            : null,
        specialist_registration:
          this.profile.specialist_registration !== null
            ? this.profile.specialist_registration
            : null,
        specialist_registration_year,
        specialist_registration_month,
        document_ids: this.profile.document_ids?.length
          ? this.profile.document_ids
          : null,
      };
      let appendAction;
      if (this.LicenseInfo && this.edit_license_id === null) {
        appendAction = this.uploadLicense(this.LicenseInfo);
      } else {
        this.LicenseInfo = {
          ...this.LicenseInfo,
          candidate_license_id: this.edit_license_id,
        };
        appendAction = this.updateLicense(this.LicenseInfo);
      }
      Promise.all([appendAction]).then((res) => {
        if (res[0]?.response?.status == 409) {
          this.cardHasError =
            "Requested Candidate License already exist! Please edit the existing record.";
          return;
        }
        this.edit_license_id = null;
        this.resetLicense();
        this.clearFields();
      });
    },
    resetLicense() {
      this.multiFormEdited({ licenseInformation: false });
      this.profile.license_number = null;
      this.profile.license_country_id = null;
      this.profile.License_issue_date = null;
      this.$refs.license_issueDate.resetDate();
      this.profile.local_license = null;
      this.profile.specialist_registration = null;
      this.profile.issue_authority_id = null;
      (this.profile.document_ids = null), (this.edit_license_id = null);
      this.delete_license = false;
      this.profile.date_acquired = null;
      this.profile.speciality_id = null;
      this.tooltipHide();
    },
    tooltipHide() {
      setTimeout(() => {
        let x = document.querySelectorAll(".tooltip");
        for (var i = 0; i < x.length; i++) {
          x[i].style.visibility = "hidden";
        }
      }, 100);
    },

    formatDate(date) {
      if (date) {
        return m(date).format("MMM-YYYY");
      }
      return "--";
    },
  },
};
</script>

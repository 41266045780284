<template>
  <LoadingOverlay v-if="isPQRInfo" />
  <div v-else class="professional-info">
    <CCard class="mt-2 has-loading-overlay">
      <CCardBody>
        <CRow>
          <CCol>
            <CRow class="row mb-3 justify-content-center">
              <button
                class="btn btn-primary btn-lg mr-2 float-left m-2 pr-48"
                @click="calculatePQR()"
              >
                Check PQR Level
              </button>
            </CRow>
          </CCol>
        </CRow>
        <div v-if="isMatching != null">
          <p
            class="h5 align-center text-center p-3"
            :class="isMatching ? 'text-primary' : 'text-gray'"
          >
            {{ pqrMessage }}
          </p>
        </div>
        <div class="d-none d-lg-block">
          <div class="d-flex align-items-center justify-content-between pb-3">
            <h4 class="text-primary">PQR Matches</h4>
            <div
              class="d-flex justify-content-start sticky-header"
              v-if="PQRLevels.length"
            >
              <CButton
                class="p-0"
                @click="pqr_step -= 1"
                :disabled="pqr_step == 0"
              >
                <CIcon name="cil-caret-left" />
              </CButton>
              <CButton
                class="p-0"
                @click="pqr_step += 1"
                :disabled="pqr_step + 1 == PQRLevels.length"
              >
                <CIcon name="cil-caret-right" />
              </CButton>
            </div>
          </div>
          <div v-if="PQRLevels.length">
            <div class="text-black">
              <CRow class="ml-3">
                <CCol>
                  <PQRDetails :pqrLevel="stepwisePQRLevel" />
                </CCol>
              </CRow>
            </div>
            <div class="text-center mt-4">
              <span
                class="dot"
                :class="{ active: index === pqr_step }"
                v-for="(data, index) in PQRLevels"
                :key="index"
                @click="pqr_step = index"
              ></span>
            </div>
          </div>
          <div v-else>
            <p class="text-center text-gray h5">No PQR Matches Found</p>
          </div>
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import ProfileSection from "./ProfileSection";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import PQRDetails from "@/containers/CandidateDetailsTalentFind/ProfileSection/PQRInfo/PQRInfoCard";

export default {
  mixins: [ProfileSection],
  components: {
    PQRDetails,
  },
  data() {
    return {
      profile: {},
      qualificationInfo: {},
      pqr_step: 0,
    };
  },
  created() {
    this.getUserMe({ customer: 2 });
  },
  computed: {
    ...mapGetters([
      "getCandidatePQRLevel",
      "candidateProfile",
      "getCandidatePQRLevels",
    ]),
    pqrMessage() {
      if (this.getCandidatePQRLevel != null)
        return this.getCandidatePQRLevel.message;
      return null;
    },
    isMatching() {
      if (this.getCandidatePQRLevel != null)
        return this.getCandidatePQRLevel.is_matching;
      return null;
    },
    profileData() {
      const profile = this.candidateProfile;
      return {
        ...profile,
        main_qualification: profile.main_qualification
          ? {
              label: profile.main_qualification.qualification,
              code: profile.main_qualification.qualification_id,
            }
          : [],
      };
    },
    isPQRInfo() {
      if (this.profileData.main_qualification != null) {
        this.qualificationInfo = this.profileData.qualifications.filter(
          ({ main }) => main === true
        )[0];

        this.profile = {
          main_qualification_id: this.qualificationInfo?.qualification
            ?.qualification_with_acronym,
          country_id: this.qualificationInfo?.country_name,
          type_id: this.qualificationInfo?.qualification?.qualification_type
            ?.qualification_type,
        };
        return false;
      }
      return true;
    },
    PQRLevels() {
      return (
        this.getCandidatePQRLevels.map(
          ({
            pqr_detail: {
              level: { candidate_level },
              pqr_header: { country_name, licensing_authority_name },
            },
            description,
            comments,
            created_on,
            candidate_qualification,
            candidate_license,
          }) => {
            return {
              country_name: country_name !== null ? country_name : "--",
              licensing_authority_name:
                licensing_authority_name !== null
                  ? licensing_authority_name
                  : "--",
              candidate_level:
                candidate_level !== null ? candidate_level : "--",
              description,
              comments,
              created_on,
              candidate_qualification,
              candidate_license,
            };
          }
        ) || []
      );
    },
    stepwisePQRLevel() {
      if (this.PQRLevels.length) {
        return this.PQRLevels[this.pqr_step];
      }
      return [];
    },
  },
  methods: {
    ...mapActions(["checkPQRLevel", "fetchPQRLevels", "getUserMe"]),
    calculatePQR() {
      let appendAction;
      appendAction = this.checkPQRLevel();
      Promise.all([appendAction]).then((res) => {
        this.fetchPQRLevels();
      });
    },
  },
  filters: {
    moment: function (date) {
      if (date) return moment(date).format("DD-MMM-YYYY");
      return "--";
    },
  },
};
</script>
<style lang="scss" scoped>
button:disabled {
  cursor: not-allowed;
}
.c-icon:not(.c-icon-c-s):not(.c-icon-custom-size) {
  height: 1.5rem;
}
</style>

<template>
  <div>
    <CModal
      color="primary"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      size="lg"
    >
      <template #header>
        <h6 class="modal-title">Document Mapping</h6>
        <CButtonClose @click="onCancel()" class="text-black" />
      </template>
      <template #footer>
        <CButton class="btn px-4 btn-outline-primary" @click="onCancel()"
          >Close</CButton
        >
        <CButton class="px-4 btn-primary" @click="onSave()">Save</CButton>
      </template>
      <div class="p-2">
        <h6 class="mb-3">
          Select the Document to be mapped with the
          {{ document_type_id == 12 ? "Qualification" : "License" }}:
          <strong>{{ label }}</strong>
        </h6>
        <div
          class="text-primary pt-1"
          style="font-weight: 600; background-color: whitesmoke"
        >
          <span class="ml-3">
            Documents
          </span>
        </div>
        <div
          v-for="i in filteredDocuments"
          :key="i.document_id"
          style="background-color: whitesmoke"
          class="mt-1 border-bottom"
          :style="
            `${
              selected.includes(i.document_id)
                ? 'background-color: #D0F0C0'
                : 'background-color: whitesmoke'
            }`
          "
        >
          <CRow class="ml-2">
            <div class="doc-table col col-1">
              <p class="head mt-3">
                <input
                  type="checkbox"
                  :value="i.document_id"
                  v-model="selected"
                />
              </p>
            </div>
            <div class="doc-table col col-4">
              <p class="head">Document Type</p>
              <p class="data">{{ i.label }}</p>
            </div>
            <div class="doc-table col col-3">
              <p class="head">Document Name</p>
              <p class="data">{{ i.name }}</p>
            </div>
            <div class="doc-table col col-3">
              <p class="head">Uploaded Date</p>
              <p class="data">{{ i.updatedDate }}</p>
            </div>
          </CRow>
        </div>
        <div
          v-if="!filteredDocuments.length"
          :style="isMobile ? 'background-color: whitesmoke; height: 60px' : 'background-color: whitesmoke; height: 40px'"
        >
          <h5 v-if="isCustomerAdmin" class="text-center mt-2 p-2" :style=" isMobile ? 'font-size:13px' : 'font-size:15px' ">No document found - you may upload and link this document in Document Information section</h5>
          <h5 v-else class="text-center mt-2 p-2" :style="isMobile ? 'font-size: 13px' : 'font-size: 15px'">No document found - you may upload and link this document in Screen 3</h5> 
        </div>
      </div>
    </CModal>
  </div>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { Role, getScope } from "@/helpers/helper";
export default {
  name: "DocumentMappingModal",
  props: {
    isShowPopup: {
      type: Boolean,
      default: false,
    },
    document_type_id: {
      type: Number,
      default: null,
    },
    document_ids: {
      type: Array,
      default: [],
    },
    label: {
      type: String,
      default: "",
    },
    candidate_qualification_id: {
      type: Number,
      default: null,
    },
    candidate_license_id: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    selected: [],
    Role,
    currentUserRole: getScope(),
    isMobile: false
  }),
  watch: {
    document_ids() {
      this.selected = this.document_ids || [];
    },
  },
  computed: {
    ...mapGetters(["getUploadedDocument"]),
    isCustomerAdmin() {
      return this.currentUserRole === this.Role.customerAdmin;
    },
    documents() {
      return (
        this.getUploadedDocument?.length &&
        this.getUploadedDocument
          .filter((val) => val.document_type_id == this.document_type_id)
          .map(
            ({
              document_name,
              document_type,
              created_on,
              candidate_document_uuid,
              candidate_qualification_id,
              candidate_license_id,
              candidate_qualification_uuid,
            }) => ({
              label: document_type.comments,
              name: document_name,
              updatedDate: moment(created_on).format("DD-MM-YYYY"),
              document_id: candidate_document_uuid,
              candidate_qualification_id: candidate_qualification_id,
              candidate_license_id: candidate_license_id,
              candidate_qualification_uuid,
            })
          )
      );
    },
    filteredDocuments() {
      if (this.documents?.length) {
        if (this.candidate_qualification_id)
          return this.documents?.filter(
            (val) =>
              val.candidate_qualification_id ==
                this.candidate_qualification_id ||
              val.candidate_qualification_id == null
          );
        if (this.candidate_license_id)
          return this.documents?.filter(
            (val) =>
              val.candidate_license_id == this.candidate_license_id ||
              val.candidate_license_id == null
          );
        return this.documents?.filter(
          (val) =>
            val.candidate_license_id == null &&
            val.candidate_qualification_id == null
        );
      }
      return [];
    },
  },
  mounted() {
    let isMobile = window.matchMedia("only screen and (max-width: 780px)")
      .matches;
    this.isMobile = isMobile;
  },
  methods: {
    onSave() {
      if(this.document_type_id === 9) {
        this.$emit("modalCallBack", true, this.selected, this.candidate_license_id);
        this.selected = [];
      } else if(this.document_type_id === 12) {
        this.$emit("modalCallBack", true, this.selected, this.candidate_qualification_id);
        this.selected = [];
      }
    },
    onCancel() {
      this.$emit("modalCallBack", false);
      this.selected = this.document_ids;
    },
  },
};
</script>

<style lang="scss" scoped>
.doc-table {
  padding: 5px;
  .head {
    font-size: 12px;
    color: gray;
    margin: 6px 0;
  }
  .data {
    margin-bottom: 2px;
    font-size: 12px;
    color: black;
    font-weight: 400;
  }
}
</style>

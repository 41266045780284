<template>
  <div class="candidate-profile">
    <LoadingOverlay v-if="!isSpecializationInfo" />
    <div v-else class="Specialization-info">
      <div v-if="cardError"></div>
      <div class="pb-1" :class="cardHasError ? 'card-error-msg' : ''">
        {{ cardHasError }}
      </div>
      <CCard
        class="mt-2 has-loading-overlay"
        :class="cardHasError ? 'card-has-error' : ''"
      >
        <CCardBody>
          <CRow>
            <div class="col-md-12">
              <div class="d-flex justify-content-between">
                <div class="mt-2">
                  <h5 class="main-title pb-3">Specialization</h5>
                </div>
                <div class="d-flex justify-content-end">
                  <button
                    name="save"
                    class="hand-icon action-button"
                    v-c-tooltip="'Save'"
                    @click="updateSpecializationInfo"
                  >
                    <CIcon
                      name="cil-save"
                      class="active"
                      style="margin-right: 20px"
                    />
                  </button>
                  <button
                    name="cancel"
                    class="hand-icon action-button ml-2"
                    v-if="getMultiFormEdited['specializationInformation']"
                    v-c-tooltip="'Cancel'"
                    @click="resetSpecializationData(), clearFields()"
                  >
                    <CIcon name="cil-x-circle" style="margin-right: 20px" />
                  </button>
                </div>
              </div>
              <ValidationObserver
                ref="specialization"
                v-slot="{ handleSubmit }"
              >
                <form
                  id="specialization"
                  @submit.prevent="handleSubmit(onSubmitStep1)"
                >
                  <fieldset :disabled="isDisabled">
                    <CRow>
                      <CCol md="6">
                        <CRow class="row mb-3">
                          <label
                            name="lbl_speciality_id"
                            class="required col-lg-12 col-md-12"
                            >Speciality</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <Select
                                name="speciality_id"
                                :value="profile.speciality_id"
                                @input="handleChangeSelect"
                                :options="
                                  options && options['speciality_id']
                                    ? options['speciality_id']
                                    : []
                                "
                                :taggable="false"
                                :multiple="false"
                                :clearable="false"
                                :disabled="isDisabled"
                                :error="errors[0]"
                              />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                      <CCol md="6">
                        <CRow
                          class="row mb-3"
                          v-if="options['sub_speciality_id'].length"
                        >
                          <label
                            name="lbl_sub_speciality_id"
                            class="col-lg-12 col-md-12"
                            >{{
                              isNurse ? `Area of Expertise` : `Sub Speciality`
                            }}</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <Select
                              name="sub_speciality_id"
                              :value="profile.sub_speciality_id"
                              @change="handleChangeSelect"
                              :options="
                                options && options['sub_speciality_id']
                                  ? options['sub_speciality_id']
                                  : []
                              "
                              :taggable="false"
                              :multiple="false"
                              :clearable="true"
                              :disabled="isDisabled"
                            />
                          </div>
                        </CRow>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol md="6">
                        <CRow
                          class="row mb-3"
                          v-if="
                            profile.speciality_id
                              ? profile.speciality_id.label ==
                                'Other - not listed'
                                ? true
                                : false
                              : false
                          "
                        >
                          <label
                            name="lbl_other_speciality"
                            class="col-lg-12 col-md-12"
                            >Name Speciality</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <TextInput
                              name="other_speciality"
                              :value="profile.other_speciality"
                              @change="handleInput"
                            />
                          </div>
                        </CRow>
                      </CCol>
                    </CRow>
                  </fieldset>
                </form>
              </ValidationObserver>
            </div>
          </CRow>
        </CCardBody>
      </CCard>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import ProfileSection from "./ProfileSection";
import { mapActions, mapGetters } from "vuex";
import { extend, validate } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", { ...required, message: "This field is required" });
const debounce = require("lodash.debounce");
import m from "moment";
extend("date_validate", (value) => {
  if (m(value).isValid()) {
    return true;
  }
  return "Invalid date! Enter a valid date";
});
extend("no_future_date", (value) => {
  let input_date = new Date(value);
  let today_date = new Date();
  if (input_date > today_date) {
    return "Given date should not be greater than today!";
  }
  return true;
});
export default {
  mixins: [ProfileSection],
  props: {
    candidateTypeId: {
      type: Number,
      default: null,
    },
    cancel: {
      type: Boolean,
      default: false,
    },
    currentJob: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isDisabled: false,
      payload: {},
      profile: {},
      errors: [],
      cardHasError: "",
    };
  },
  computed: {
    ...mapGetters([
      "uploaded",
      "candidateProfile",
      "specialityByTypeAndCountry",
      "getCandidateSpecialInterest",
    ]),
    options() {
      return {
        candidateType: this.candidateTypeReOrder || [],
        speciality_id:
          this.specialityByTypeAndCountry({
            candidateType: this.candidateTypeId,
            country_id:
              this.candidateProfile.highest_qualification_country_id || null,
          }) || [],
        sub_speciality_id:
          this.subSpecialityFilterBySpeciality({
            speciality: this.profile?.speciality_id?.code,
          }) || [],
        boolean: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
      };
    },
    candidateTypeReOrder() {
      let sortBy = [1, 2, 8].reverse();
      return (
        this.candidateType &&
        this.candidateType.length &&
        this.candidateType.sort((a, b) => {
          return -sortBy.indexOf(a.code) - -sortBy.indexOf(b.code);
        })
      );
    },
    isSpecializationInfo() {
      if (
        Object.keys(this.candidateProfile).length 
        // &&  this.getCandidateSpecialInterest.length
      ) {
        return Object.keys(this.profile).length === 0
          ? this.setSpecializationData(this.candidateProfile)
          : true;
      }
      return false;
    },
    cardError() {
      if (
        this.getMultiFormEdited.showError &&
        this.getMultiFormEdited.specializationInformation
      ) {
        this.cardHasError = "Please Save or Delete";
        if (this.getMultiFormEdited.fieldValidateError) {
          this.cardHasError = "Please fill mandatory fields";
        }
        let el = this.$el.getElementsByClassName("Specialization-info")[0];
        el.scrollIntoView(true);
        el.scrollIntoView({ block: "center" });
        window.scrollBy(0, -200);
        return true;
      }
      return false;
    },
    isNurse() {
      return this.profile?.candidate_type_id?.code
        ? this.profile.candidate_type_id.code === 2
        : false;
    },
    nurseSplInterest() {
      return (
        (this.getCandidateSpecialInterest &&
          this.getCandidateSpecialInterest.filter(
            (val) =>
              val.special_interest_id === null &&
              val.other_special_interest === null
          )) ||
        []
      );
    },
  },
  watch: {
    "profile.speciality_id"() {
      if (!this.profile.speciality_id) {
        this.cardHasError = "Please Add Speciality and Save";
        this.$parent.cancel = false;
        return;
      }
    },
    cancel() {
      if (this.cancel) {
        this.resetSpecializationData(this.candidateProfile);
        this.clearFields();
      }
    },
  },
  mounted() {
    // const isValid = this.$refs.specialization.validate().then((res) => {
    //   res ? null : this.multiFormEdited({ fieldValidateError: true });
    // });
  },
  methods: {
    ...mapActions(["updateCandidate", "showToast", "fetchspecialInterest"]),
    setSpecializationData(profileData) {
      this.profile = {
        customer_uid: profileData.customer_uid,
        candidate_uid: profileData.candidate_uid,
        speciality_id:
          profileData.speciality !== null
            ? {
                code: profileData.speciality.speciality_id,
                label: profileData.speciality.speciality,
              }
            : null,
        sub_speciality_id:
          profileData.sub_speciality_id !== null
            ? {
                code: profileData.sub_speciality.sub_speciality_id
                  ? profileData.sub_speciality.sub_speciality_id
                  : null,
                label: profileData.sub_speciality.sub_speciality
                  ? profileData.sub_speciality.sub_speciality
                  : null,
              }
            : null,
        other_speciality: profileData?.other_speciality,
      };
      return this.profile;
    },
    async updateSpecializationInfo() {
      this.$emit("tooltipHide");
      const isValid = await this.$refs.specialization.validate();
      if (!isValid) {
        this.cardHasError = "Please fill mandatory fields";
        return;
      }
      this.payload = {
        candidate_uid: this.profile?.candidate_uid,
        candidate_type_id: this.candidateTypeId,
        speciality_id:
          this.profile?.speciality_id?.code !== 0
            ? this.profile?.speciality_id?.code
            : null,
        sub_speciality_id: this.profile?.sub_speciality_id?.code || null,
        current_job: this.currentJob,
        other_speciality:
          this.profile?.other_speciality &&
          this.profile?.speciality_id?.label === "Other - not listed"
            ? this.profile?.other_speciality
            : null,
      };
      this.saveSpecializationInfo(this.payload);
    },
    saveSpecializationInfo(data) {
      const { customer_uid } = this.profile;
      let appendAction = this.updateCandidate({ data, customer_uid });
      Promise.all([appendAction]).then((res) => {
        this.clearFields();
        this.$parent.clearFields();
      });
    },
    resetSpecializationData() {
      this.setSpecializationData(this.candidateProfile);
    },
    clearFields() {
      this.cardHasError = "";
      this.multiFormEdited({ specializationInformation: false });
      this.multiFormEdited({ fieldValidateError: false });
      this.multiFormEdited({ showError: false });
      this.$emit("tooltipHide");
    },
    handleMonthYearPickerChange(name, value) {
      Vue.set(this.profile, name, m(value).format("YYYY-MM"));
      Vue.set(this.payload, name, value ? m(value).format("YYYY-MM") : null);
    },
    handleSliderYrsExp: debounce(function (value) {
      Vue.set(this.profile, "experience_in_years", value);
      Vue.set(this.payload, "experience_in_years", value);
    }, 500),
  },
};
</script>
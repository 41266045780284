<template>
  <div>
    <div class="languageInformation">
      <div v-if="cardError"></div>
      <div class="pb-1 cardError" :class="cardHasError ? 'card-error-msg' : ''">
        {{ cardHasError }}
      </div>
      <CCard class="mt-2" :class="cardHasError ? 'card-has-error' : ''">
        <CCardBody>
          <CRow>
            <div class="col-md-12">
              <div class="d-flex align-items-center justify-content-between">
                <div class="mt-2 mr-2">
                  <h5 class="pb-3 main-title">
                    Language Information<span class="h6">
                      [{{ languagesSpokenInfo.length || "0" }} record{{
                        languagesSpokenInfo.length > 1 ? "s" : ""
                      }}]</span
                    >
                  </h5>
                </div>
              </div>
              <ValidationObserver ref="langInfo" v-slot="{ handleSubmit }">
                <form id="langInfo" @submit.prevent="handleSubmit()">
                  <fieldset :disabled="isDisabled">
                    <CRow>
                      <CCol md="4">
                        <CRow class="d-flex align-items-center mb-3">
                          <label
                            name="lbl_languages_spoken"
                            class="col-lg-12 col-md-12"
                            >Languages Spoken</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <Select
                                name="languages_spoken"
                                :value="profile.languages_spoken"
                                @input="handleChangeSelect"
                                :options="
                                  options && options['languages']
                                    ? options['languages']
                                    : []
                                "
                                :taggable="false"
                                :multiple="false"
                                :clearable="false"
                                :disabled="isDisabled"
                                :error="errors[0]"
                              />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                      <CCol md="4">
                        <CRow class="row mb-3">
                          <label
                            name="lbl_language_proficiency"
                            class="col-lg-12 col-md-12"
                            >Language Proficiency</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <Select
                              name="language_proficiency"
                              :value="profile.language_proficiency"
                              @input="handleChangeSelect"
                              :options="
                                options && options['proficiency']
                                  ? options['proficiency']
                                  : []
                              "
                              :taggable="false"
                              :multiple="false"
                              :clearable="clearable"
                              :disabled="isDisabled"
                            />
                          </div>
                        </CRow>
                      </CCol>
                      <CCol md="4" class="mb-2 p-0">
                        <label class="col-lg-12 col-md-12"></label>
                        <div class="mr-2 d-flex justify-content-end">
                          <CButton
                            v-if="
                              !edit_Candidate_lang_id &&
                              getMultiFormEdited['langInfo']
                            "
                            class="mr-2 btn-primary small"
                            style="height: 28px"
                            @click="uploadLanguage"
                            v-c-tooltip="
                              'Click to Add multiple records!'
                            "
                            >Add</CButton
                          >
                          <CButton
                            class="mr-2 btn-primary small"
                            style="height: 28px"
                            @click="uploadLanguage"
                            v-if="edit_Candidate_lang_id"
                            v-c-tooltip="
                              'Click to update the record!'
                            "
                            >Update</CButton
                          >
                          <button
                            name="cancel"
                            class="hand-icon action-button"
                            v-if="
                              edit_Candidate_lang_id ||
                              getMultiFormEdited['langInfo']
                            "
                            v-c-tooltip="'Cancel'"
                            @click="resetFields(), clearFields()"
                          >
                            <CIcon
                              name="cil-x-circle"
                              style="margin-right: 20px"
                            />
                          </button>
                        </div>
                      </CCol>
                    </CRow>
                    <div v-if="languagesSpokenInfo" class="multi-records">
                      <div
                        class="top-border d-flex"
                        style="justify-content: space-between"
                        v-for="(data, index) in languagesSpokenInfo"
                        :key="index"
                      >
                        <div style="min-width: 75%">
                          <CRow>
                            <div class="table d-flex">
                              <p class="text-black pl-4 m-0 p-2 w-50">
                                {{ data.language }}
                              </p>

                              <p class="text-black m-0 w-50 p-2">
                                {{ data.proficiency || "--" }}
                              </p>
                            </div>
                          </CRow>
                        </div>
                        <div
                          class="widget-content-actions d-flex justify-content-center"
                        >
                          <CButton
                            class="icon icon-edit mr-2"
                            v-if="data && data.candidate_language_id"
                            @click="editLanguage(data)"
                          >
                          </CButton>
                          <CButton
                            class="icon"
                            v-if="data && data.candidate_language_id"
                            @click="deleteLanguage(data)"
                          >
                            <i class="fas fa-trash-alt" />
                          </CButton>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </ValidationObserver>
            </div>
          </CRow>
        </CCardBody>
      </CCard>
    </div>
    <Modal
      :modalTitle="deleteConfirmModal.modalTitle"
      :modalColor="deleteConfirmModal.modalColor"
      :modalContent="deleteConfirmModal.modalContent"
      :isShowPopup="deleteConfirmModal.isShowPopup"
      :buttons="deleteConfirmModal.buttons"
      :modalCallBack="deletemodalCallBack"
      :closeOnBackdrop="false"
    />
  </div>
</template>

<script>
import ProfileSection from "./ProfileSection";
import { mapActions, mapGetters } from "vuex";
import Modal from "@/components/reusable/Modal";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", { ...required, message: "This field is required" });

export default {
  name: "LanguagesInfoTalentFind",
  mixins: [ProfileSection],
  components: {
    Modal,
  },
  data() {
    return {
      isDisabled: false,
      profile: {},
      LanguageInfo: {},
      languageupload: false,
      candidate_language_id: 0,
      edit_Candidate_lang_id: null,
      deleteConfirmModal: {
        modalColor: "danger",
        modalTitle: "Delete Document",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
      },
      cardHasError: "",
    };
  },
  computed: {
    ...mapGetters(["getCandidateLanguage", "uploaded"]),
    options() {
      return {
        languages: this.locationLanguageReOrder || [],
        proficiency: this.languageProficiency || [],
      };
    },
    locationLanguageReOrder() {
      return (
        this.locationLanguage &&
        this.locationLanguage.length &&
        this.locationLanguage.sort((a, b) => {
          return a.code === 1 ? -1 : 1;
        })
      );
    },
    languagesSpokenInfo() {
      return (
        this.getCandidateLanguage &&
        this.getCandidateLanguage.length &&
        this.getCandidateLanguage.map(
          ({
            language_id,
            candidate_language_id,
            language,
            language_proficiency,
          }) => {
            return {
              language_id: language !== null ? language?.language_id : "--",
              candidate_language_id:
                candidate_language_id !== null ? candidate_language_id : "--",
              language: language !== null ? language?.language : "--",
              proficiency_id:
                language_proficiency !== null
                  ? language_proficiency?.lang_prof_id
                  : null,
              proficiency:
                language_proficiency !== null
                  ? language_proficiency?.proficiency
                  : null,
            };
          }
        )
      );
    },
    cardError() {
      if (
        this.getMultiFormEdited.showError &&
        this.getMultiFormEdited.langInfo
      ) {
        this.cardHasError = "Please Save or Delete";
        let el = this.$el.getElementsByClassName("cardError")[0];
        el.scrollIntoView(true);
        el.scrollIntoView({block: 'center'});
        window.scrollBy(0, -200);
        return true;
      }
      return false;
    },
    clearable() {
      if (this.edit_Candidate_lang_id) {
        return this.profile?.language_proficiency?.label && this.profile?.language_proficiency?.code != null 
          ? true
          : false
      }
      return true;
    }
  },
  watch: {
    uploaded() {
      if (this.uploaded) {
        if (this.languageupload) {
          this.fetchCandidateLanguage();
          this.languageupload = false;
        }
      }
    },
  },
  methods: {
    ...mapActions([
      "fetchCandidateLanguage",
      "uploadCandidateLanguage",
      "deleteCandidateLanguage",
      "showToast",
      "updateLanguage",
    ]),
    async uploadLanguage() {
      const isValid = await this.$refs.langInfo.validate();
      this.errors = Object.values(this.$refs.langInfo.errors).flat();
      if (!isValid) {
        let el = this.$el.getElementsByClassName("has-error")[0];
        el.scrollIntoView(true);
        window.scrollBy(0, -60);
        this.cardHasError = this.errors[0] || "please fill mandatory fields!";
        return;
      } else {
        if (this.edit_Candidate_lang_id !== null) {
          let _count = 0;
          let _index;
          for (var i = 0; i < this.getCandidateLanguage.length; i++) {
            if (
              this.getCandidateLanguage[i].language.language_id ===
              this.profile.languages_spoken.code
            ) {
              _index = i;
              _count = _count + 1;
            }
          }
          if (
            _count >= 1 &&
            this.getCandidateLanguage[_index].candidate_language_id !==
              this.edit_Candidate_lang_id
          ) {
            this.cardHasError = "Language Already Exists!!";
          } else {
            this.saveLanguagesSpoken();
          }
        } else {
          if (
            this.getCandidateLanguage &&
            this.getCandidateLanguage.length &&
            this.getCandidateLanguage.findIndex(
              (e) =>
                e.language.language_id === this.profile.languages_spoken.code
            ) >= 0
          ) {
            this.cardHasError = "Language Already Exists!!";
          } else {
            this.saveLanguagesSpoken();
          }
        }
      }
    },
    saveLanguagesSpoken() {
      this.languageupload = true;
      this.LanguageInfo = {
        language_id: this.profile.languages_spoken
          ? this.profile.languages_spoken.code
          : null,
        lang_prof_id: this.profile.language_proficiency
          ? this.profile.language_proficiency.code
          : null,
      };
      let appendAction;
      if (this.LanguageInfo && this.edit_Candidate_lang_id === null) {
        appendAction = this.uploadCandidateLanguage(this.LanguageInfo);
      } else {
        let candidate_lang_id = this.edit_Candidate_lang_id;
        let langPayload = this.LanguageInfo;
        appendAction = this.updateLanguage({ candidate_lang_id, langPayload });
        this.edit_Candidate_lang_id = null;
      }
      Promise.all([appendAction]).then((res) => {
        this.resetFields();
        this.clearFields();
      });
    },
    resetFields() {
      this.profile.languages_spoken = null;
      this.profile.language_proficiency = null;
      this.edit_Candidate_lang_id = null;
      this.$emit("tooltipHide");
    },
    clearFields() {
      this.$nextTick(() => {
        this.$refs.langInfo.errors.clear;
        this.$refs.langInfo.reset();
      });
      this.cardHasError = "";
      this.multiFormEdited({ langInfo: false });
      this.multiFormEdited({ showError: false });
    },
    deleteLanguage(data) {
      this.$emit("tooltipHide");
      this.profile = {
        ...this.profile,
        languages_spoken: {
          code: data.language_id,
          label: data.language,
        },
        language_proficiency: {
          code: data.proficiency_id,
          label: data.proficiency,
        },
      };
      this.deleteConfirmModal.modalContent = `Do you want to delete Language - ${data.language} ?`;
      this.candidate_language_id = data.candidate_language_id;
      this.deleteConfirmModal.isShowPopup = true;
    },
    editLanguage(data) {
      let el = this.$el.getElementsByClassName("languageInformation")[0];
      el.scrollIntoView(true);
      window.scrollBy(0, -200);
      this.profile = {
        ...this.profile,
        languages_spoken: {
          code: data.language_id,
          label: data.language,
        },
        language_proficiency: {
          code: data.proficiency_id,
          label: data.proficiency,
        },
      };
      this.edit_Candidate_lang_id = data.candidate_language_id;
    },
    deletemodalCallBack(action) {
      if (action === "Confirm") {
        this.deleteCandidateLanguage(this.candidate_language_id);
      }
      this.candidate_language_id = "";
      this.resetFields();
      this.clearFields();
      this.deleteConfirmModal.isShowPopup = false;
    },
  },
};
</script>

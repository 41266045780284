<template>
  <div>
    <CModal color="primary" :show.sync="isShowPopup" :close-on-backdrop="false">
      <template #header>
        <h6 class="modal-title">Document Information Edit</h6>
        <CButtonClose @click="modalCallBack(false)" class="text-black" />
      </template>
      <template #footer>
        <CButton
          type="button"
          variant="outline"
          color="primary"
          class="px-4"
          @click="modalCallBack(false)"
          >Close</CButton
        >
        <CButton type="button" color="primary" class="px-4" @click="onSubmit()"
          >Save</CButton
        >
      </template>
      <LoadingOverlay v-if="!isFetching" />
      <div v-else>
        <ValidationObserver ref="editDocumentInfo" v-slot="{ handleSubmit }">
          <form
            @submit.prevent="handleSubmit(onSubmit)"
            name="editDocumentInfo"
          >
            <CRow class="d-flex align-items-center mb-3">
              <label name="lbl_description" class="col-lg-4"
                >Description:</label
              >
              <div class="col-lg-8">
                <TextInput
                  name="comments"
                  :value="profile.comments"
                  @change="handleInput"
                />
              </div>
            </CRow>
          </form>
        </ValidationObserver>
      </div>
    </CModal>
  </div>
</template>
<script>
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";
import EditSection from "./EditSection";
import { mapActions, mapGetters } from "vuex";
import { extend, validate } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import m from "moment";
extend("required", { ...required, message: "This field is required" });

export default {
  mixins: [EditSection],
  props: {
    documentInfo: {
      type: Object,
      default: null,
    },
    isShowPopup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      profile: {},
      errors: [],
    };
  },
  computed: {
    ...mapGetters(["locationCountryInfo", "licenseIssuedByCountry"]),
    isFetching() {
      if (
        isObject(this.documentInfo) &&
        !isEmptyObjectCheck(this.documentInfo) &&
        !this.isFetchingFilter
      ) {
        let data = this.documentInfo;
        this.profile = {
          ...data,
          comments: data.comments,
        };
      }
      return true;
    },
  },
  methods: {
    ...mapActions(["showToast"]),
    async onSubmit() {
      const isValid = await this.$refs.editDocumentInfo.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      let payload = this.profile.comments || '';
      this.modalCallBack(true, payload);
    },
    modalCallBack(action, payload) {
      this.$emit("modalCallBack", action, payload);
    },
  },
};
</script>
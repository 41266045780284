import { mapActions, mapGetters } from "vuex";
import Vue from "vue";
import m from "moment";
import Select from "@/components/reusable/Fields/Select.vue";
import TextInput from "@/components/reusable/Fields/TextInput";
import PhoneInput from "@/components/reusable/Fields/PhoneInput";
import TextareaInput from "@/components/reusable/Fields/TextareaInput";
import DatePicker from "@/components/reusable/Fields/DatePicker";
import DateInput from "@/components/reusable/Fields/DateInput";
import TimePicker from "@/components/reusable/Fields/TimePicker";
import RadioButton from "@/components/reusable/Fields/RadioButton";
import CheckBox from "@/components/reusable/Fields/CheckBox";
import PhoneInputwithCountry from "@/components/reusable/Fields/PhoneInputwithCountry";
import CVPreview from "./CVPreview";
export default {
  components: {
    Select,
    TextInput,
    PhoneInput,
    TextareaInput,
    DatePicker,
    DateInput,
    RadioButton,
    CheckBox,
    TimePicker,
    PhoneInputwithCountry,
    CVPreview,
  },
  data() {
    return {
      payload: {},
      activeTab: 1,
    };
  },
  computed: {
    ...mapGetters(["getFilteredIssueAuthority"])
  },
  methods: {
    ...mapActions(["fetchIssueAuthorityByQualification"]),
    handleInput(name, value) {
      Vue.set(this.profile, name, value);
      Vue.set(this.payload, name, value);
      let hasError = {
        phone: "phone",
        whatsapp: "whatsapp",
        alternate_phone: "alternate_phone",
      };
      if (
        (name == hasError.phone ||
          name == hasError.whatsapp ||
          name == hasError.alternate_phone) &&
        value === ""
      ) {
        this.clearFieldErrors(name, hasError);
      }
    },
    handleDatePickerChange(name, value) {
      Vue.set(this.profile, name, m(value).format("YYYY-MM-DD"));
      Vue.set(this.payload, name, value ? m(value).format("YYYY-MM-DD") : null);
    },
    handleChangeSelect(name, value) {
      if (name === "candidate_type_id" || name === "speciality_id") {
        this.profile["sub_speciality_id"] = null;
        if (name === "candidate_type_id") this.profile["speciality_id"] = null;
      }
      Vue.set(this.profile, name, value);
      let preferred_contact_method = "preferred_contact_method";
      if (name == preferred_contact_method) {
        let temp = "";
        value.forEach((ele) => {
          temp = temp ? temp + ", " + ele.id : ele.id;
        });
        value = temp;
      }
      this.payload = {
        ...this.payload,
        [name]: value ? value.id || value.code || value : null,
      };
      if (name === "main_qualification_id") {
        this.handleChangeRadio("by_exam", value?.by_exam ? true : null);
        this.fetchIssueAuthorityByQualification({
          qualification_id: value ? value.id || value.code || value : null,
        }).then(res => {
          if (this.getFilteredIssueAuthority?.length) {
            this.handleChangeSelect("issued_by", this.getFilteredIssueAuthority[0])
          } else {
            this.handleChangeSelect("issued_by", null);
          }
        })
      }
    },
    handleChangeMultiSelectAsArray(name, value) {
      Vue.set(this.profile, name, value);
      this.payload = {
        ...this.payload,
        [name]: value
          ? value.map((v) => {
            return v.id || v.code || v;
          })
          : null,
      };
      let preferred_region = "preferred_regions";
      if (name === preferred_region) {
        this.profile.preferred_country_id =
          this.profile.preferred_country_id &&
          this.profile.preferred_country_id.filter(({ region_id }) =>
            this.payload[name].includes(region_id)
          );
        this.payload.preferred_country_id = this.profile.preferred_country_id
          ? this.profile.preferred_country_id.map((v) => {
            return v.id || v.code || v;
          })
          : null;
      }
    },
    handleChangeMultiSelectAsString(name, value) {
      Vue.set(this.profile, name, value);
      this.payload = {
        ...this.payload,
        [name]: value
          ? value
            .map((v) => {
              return v.id || v.code || v;
            })
            .join()
          : null,
      };
    },
    handleChangeRadio(name, value) {
      let val = null;
      if (value === null) {
        val = null;
      } else if (value.id === false || value.code === false) {
        val = false;
      } else if (value.id === null || value.code === null) {
        val = null;
      } else {
        val = value.id || value.code || value;
      }

      Vue.set(this.profile, name, val);
      this.payload = {
        ...this.payload,
        [name]: val,
      };
    },

    handleFileUpload(event) {
      const size = event.target.files[0].size;
      if (Math.round(size / (1024 * 1024)) <= 2) {
        this.isFileError = false;
        this.$emit("upload", { name: ["file"], value: event.target.files[0] });
      } else {
        this.$refs.fileref.value = "";
        this.isFileError = true;
      }
    },
  },
};

<template>
  <div class="candidate-profile">
    <LoadingOverlay v-if="!isAvailabilityInfo" />
    <div v-else class="availability-info">
      <div v-if="cardError"></div>
      <div class="pb-1 cardError" :class="cardHasError ? 'card-error-msg' : ''">
        {{ cardHasError }}
      </div>
      <CCard
        class="mt-2 has-loading-overlay"
        :class="cardHasError ? 'card-has-error' : ''"
      >
        <CCardBody>
          <CRow>
            <div class="col-md-12">
              <div class="d-flex justify-content-between">
                <div class="mt-2 ml-2">
                  <h5 class="main-title pb-3">Work Preferences</h5>
                </div>
                <div class="d-flex justify-content-end">
                  <button
                    name="save"
                    class="hand-icon action-button"
                    v-c-tooltip="'Save'"
                    @click="updateAvailabilityInfo"
                  >
                    <CIcon
                      name="cil-save"
                      class="active"
                      style="margin-right: 20px"
                    />
                  </button>
                  <button
                    name="cancel"
                    class="hand-icon action-button ml-2"
                    v-if="getMultiFormEdited['personalInformation']"
                    v-c-tooltip="'Cancel'"
                    @click="resetAvailabilityData(), clearFields()"
                  >
                    <CIcon name="cil-x-circle" style="margin-right: 20px" />
                  </button>
                </div>
              </div>
              <ValidationObserver ref="availableInfo" v-slot="{ handleSubmit }">
                <form
                  id="availableInfo"
                  @submit.prevent="handleSubmit(onSubmitStep1)"
                >
                  <fieldset :disabled="isDisabled">
                    <CRow>
                      <CCol md="6">
                        <CRow class="row mb-3">
                          <label
                            name="lbl_available"
                            class="col-lg-12 col-md-12"
                            >Actively Seeking Employment</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <RadioButton
                              name="available"
                              :value="profile.available"
                              :options="
                                options && options['available']
                                  ? options['available']
                                  : []
                              "
                              @change="handleChangeRadio"
                            />
                          </div>
                        </CRow>
                      </CCol>
                      <CCol md="6">
                        <CRow class="row mb-3">
                          <label
                            name="lbl_notice_period_days"
                            class="col-lg-12 col-md-12"
                            v-if="profile.notice_period_days > 0"
                            >Notice Period ({{
                              profile.notice_period_days
                            }}
                            days)</label
                          >
                          <label class="col-lg-12 col-md-12" v-else
                            >Notice Period (in days)</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <v-slider
                              name="notice_period_days"
                              :min="minDependants"
                              :max="maxDependants"
                              :value="profile.notice_period_days"
                              @change="onChange"
                              :disabled="isDisabled"
                            />
                          </div>
                        </CRow>
                      </CCol>
                    </CRow>
                    <CRow md="6">
                      <CCol md="6">
                        <div v-if="profile.available == true">
                          <CRow class="row mb-3">
                            <label
                              name="lbl_expected_join_date"
                              class="col-lg-12 col-md-12"
                              >Available From</label
                            >
                            <div class="col-lg-12 col-md-12 col-sm-12">
                              <ValidationProvider
                                rules="date_validate|dateBetweenCurrentMth"
                                v-slot="{ errors }"
                              >
                                <DateInput
                                  :futureYear="true"
                                  :onlyMonthAndYear="true"
                                  :disabled="isDisabled"
                                  name="expected_join_date"
                                  :value="profile.expected_join_date"
                                  @change="handleDatePickerChange"
                                  :error="errors[0]"
                                />
                              </ValidationProvider>
                            </div>
                          </CRow>
                        </div>
                        <div v-if="profile.available == false">
                          <CRow class="row mb-3">
                            <label
                              name="lbl_available"
                              class="col-lg-12 col-md-12"
                              >Available From</label
                            >
                            <div class="col-lg-12 col-md-12 col-sm-12">
                              <ValidationProvider
                                rules="date_validate|dateBetween"
                                v-slot="{ errors }"
                              >
                                <DateInput
                                  :futureYear="true"
                                  :onlyMonthAndYear="true"
                                  :disabled="isDisabled"
                                  name="available_from"
                                  :value="profile.available_from"
                                  @change="handleDatePickerChange"
                                  :error="errors[0]"
                                />
                              </ValidationProvider>
                            </div>
                          </CRow>
                        </div>
                      </CCol>
                    </CRow>
                    
                    <CRow>
                      <CCol md="6">
                        <CRow class="row mb-3">
                          <label
                            name="lbl_current_salary"
                            class="col-lg-12 col-md-12"
                            >Current Salary</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <CRow>
                              <CCol class="pr-0" style="max-width: 50%">
                                <ValidationProvider
                                  rules="numeric"
                                  v-slot="{ errors }"
                                >
                                  <TextInput
                                    name="current_salary"
                                    :value="profile.current_salary"
                                    :min="0"
                                    type="Number"
                                    CSS_Style="max-height: 31px"
                                    @change="handleInput"
                                    :error="errors[0]"
                                    :onkeydown="restrictedKeys"
                                  />
                                </ValidationProvider>
                              </CCol>
                              <CCol class="p-0 ml-1" style="max-width: 25%">
                                <Select
                                  name="current_salary_currency_id"
                                  :value="profile.current_salary_currency_id"
                                  @change="handleChangeSelect"
                                  :options="
                                    options && options['currency']
                                      ? options['currency']
                                      : []
                                  "
                                  :taggable="false"
                                  :clearable="false"
                                  :disabled="isDisabled"
                                />
                              </CCol>
                            </CRow>
                          </div>
                        </CRow>
                      </CCol>
                      <CCol md="6">
                        <CRow class="row mb-3">
                          <label
                            name="lbl_salary_expectation"
                            class="col-lg-12 col-md-12"
                            >Salary Expectation(Min - Max)</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <CRow>
                              <CCol class="pr-0" style="max-width: 30%">
                                <ValidationProvider
                                  name="salary_expectation_min"
                                  rules="numeric"
                                  v-slot="{ errors }"
                                >
                                  <TextInput
                                    name="salary_expectation_min"
                                    :value="profile.salary_expectation_min"
                                    placeholder="Min"
                                    :min="0"
                                    type="Number"
                                    CSS_Style="max-height: 31px"
                                    @change="handleInput"
                                    :error="errors[0]"
                                    :onkeydown="restrictedKeys"
                                  />
                                </ValidationProvider>
                              </CCol>
                              _
                              <CCol class="p-0" style="max-width: 30%">
                                <ValidationProvider
                                  name="salary_expectation_max"
                                  rules="numeric|minMaxCheck:@salary_expectation_min"
                                  v-slot="{ errors }"
                                >
                                  <TextInput
                                    name="salary_expectation_max"
                                    :value="profile.salary_expectation_max"
                                    placeholder="Max"
                                    :min="0"
                                    type="Number"
                                    CSS_Style="max-height: 31px"
                                    @change="handleInput"
                                    :error="errors[0]"
                                    :onkeydown="restrictedKeys"
                                  />
                                </ValidationProvider>
                              </CCol>
                              <CCol class="ml-1 p-0" style="max-width: 25%">
                                <Select
                                  name="salary_expectation_currency_id"
                                  :value="
                                    profile.salary_expectation_currency_id
                                  "
                                  @change="handleChangeSelect"
                                  :options="
                                    options && options['currency']
                                      ? options['currency']
                                      : []
                                  "
                                  :taggable="false"
                                  :clearable="false"
                                  :disabled="isDisabled"
                                />
                              </CCol>
                            </CRow>
                          </div>
                        </CRow>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol md="6">
                        <CRow class="row mb-3">
                          <label class="col-lg-12 col-md-12"
                            >Contract Type</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="d-flex field-align">
                              <div class="flex-fill mr-1">
                                <Select
                                  name="job_type"
                                  :value="profile.job_type"
                                  @change="handleChangeSelect"
                                  :options="
                                    options && options['jobType']
                                      ? options['jobType']
                                      : []
                                  "
                                  :taggable="false"
                                  :multiple="false"
                                  :clearable="true"
                                  :disabled="isDisabled"
                                />
                              </div>
                              <div class="flex-fill mr-1">
                                <Select
                                  name="job_time"
                                  :value="profile.job_time"
                                  @change="handleChangeSelect"
                                  :options="
                                    options && options['jobTime']
                                      ? options['jobTime']
                                      : []
                                  "
                                  :taggable="false"
                                  :multiple="false"
                                  :clearable="true"
                                  :disabled="isDisabled"
                                />
                              </div>
                            </div>
                          </div>
                        </CRow>
                      </CCol>
                      <CCol md="6">
                        <CRow class="row mb-3">
                          <label
                            name="lbl_additional_information"
                            class="col-lg-12 col-md-12"
                            >Additional information</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <h6 class="text-primary">
                              If you have any additional information,please
                              upload as a cover letter under Document's in
                              section 3
                            </h6>
                          </div>
                        </CRow>
                      </CCol>
                    </CRow>
                  </fieldset>
                </form>
              </ValidationObserver>
            </div>
          </CRow>
        </CCardBody>
      </CCard>
    </div>
  </div>
</template>
<script>
const debounce = require("lodash.debounce");
import Vue from "vue";
import ProfileSection from "./ProfileSection";
import { mapActions, mapGetters } from "vuex";
import m from "moment";
import { extend } from "vee-validate";
import { required, numeric } from "vee-validate/dist/rules";
extend("numeric", { ...numeric, message: "Only numbers are allowed" });
extend("required", { ...required, message: "This field is required" });
extend("dateBetween", (value) => {
  let date = m(value).format("MM/DD/YYYY");
  let fromDate = m().format("MM/DD/YYYY");
  let toDate = m(fromDate).add(3, "y").format("MM/DD/YYYY");
  if (m(date).isBetween(fromDate, toDate, undefined, [])) {
    return true;
  }
  return "Date should be within 3 years from today";
});
extend("dateBetweenCurrentMth", (value) => {
  let date = m(value).format("MM/DD/YYYY");
  let fromDate = m().format("MM/DD/YYYY");
  let toDate = m(fromDate).add(3, "y").format("MM/DD/YYYY");
  if (m(date).isBetween(fromDate, toDate, undefined, [])) {
    return true;
  }
  return "Date should be within 3 years from today";
});
extend("date_validate", (value) => {
  if (m(value).isValid()) {
    return true;
  }
  return "Invalid date! Enter a valid date";
});
extend("minMaxCheck", {
  params: ["salary_expectation_min"],
  validate(value, { salary_expectation_min }) {
    if (value - salary_expectation_min > 0) {
      return true;
    }
  },
  message: "Should be greater than min salary",
});

export default {
  mixins: [ProfileSection],
  data() {
    return {
      isDisabled: false,
      payload: {},
      profile: {},
      errors: [],
      minDependants: 0,
      maxDependants: 180,
      cardHasError: "",
      restrictedKeys:
        "javascript: return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))",
    };
  },
  computed: {
    ...mapGetters([
      "candidateProfile",
      "locationRegion",
      "PreferredCountryFilterbyRegion",
      "getCurrency",
    ]),
    fromDate() {
      return m().format("dd/MM/yyyy");
    },
    toDate() {
      return m().add(1, "year").format("dd/MM/yyyy");
    },
    options() {
      return {
        available: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
        extraHours: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
        flexible: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
        jobType: [
          { code: "Full Time", label: "Full Time" },
          { code: "Part Time", label: "Part Time" },
          { code: "Flexible", label: "Flexible" },
        ],
        jobTime: [
          { code: "Permanent", label: "Permanent" },
          { code: "Temporary", label: "Temporary" },
          { code: "Visiting/locum", label: "Visiting/locum" },
          { code: "Flexible", label: "Flexible" },
        ],
        preferred_country: this.preferred_country || [],
        locationRegion: this.preferred_regionOption || [],
        currency: this.getCurrency || [],
      };
    },
    isAvailabilityInfo() {
      if (Object.keys(this.candidateProfile).length) {
        return this.setAvailabilityData(this.candidateProfile);
      }
      return false;
    },
    cardError() {
      if (
        this.getMultiFormEdited.showError &&
        this.getMultiFormEdited.personalInformation
      ) {
        this.cardHasError = "Please Save or Delete";
        let el = this.$el.getElementsByClassName("cardError")[0];
        // el.scrollIntoView(true);
        el.scrollIntoView({ block: "center" });
        window.scrollBy(0, -200);
        return true;
      }
      return false;
    },
  },
  watch: {
    "profile.available"() {
      this.profile.available
        ? (this.profile.available_from = null)
        : (this.profile.expected_join_date = null);
    },
  },
  methods: {
    ...mapActions([
      "updateCandidate",
      "createPreferredRegion",
      "createPreferredCountry",
      "fetchCandidatePreferredRegionList",
      "fetchCandidatePreferredCountryList",
      "deletePreferredRegion",
      "deletePreferredCountry",
    ]),
    setAvailabilityData(profileData) {
      this.profile = {
        customer_uid: profileData.customer_uid,
        candidate_uid: profileData.candidate_uid,
        available: profileData?.available,
        preferred_locations: null,
        notice_period_days: profileData?.notice_period_days
          ? profileData?.notice_period_days
          : profileData?.candidate_type_id == 1
          ? 90
          : profileData?.candidate_type_id == 2 ||
            profileData?.candidate_type_id == 3
          ? 30
          : null,
        expected_join_date: profileData.expected_join_date
          ? profileData.expected_join_date
          : null,
        available_from: profileData.available_from
          ? profileData.available_from
          : null,
        salary_expectation_min: profileData?.salary_expectation_min,
        salary_expectation_max: profileData?.salary_expectation_max,
        salary_expectation_currency_id: profileData.salary_expectation_currency
          ? profileData?.salary_expectation_currency?.currency_code
          : {
              label: "USD",
              code: 229,
            },
        current_salary: profileData?.current_salary,
        current_salary_currency_id: profileData.current_salary_currency
          ? profileData?.current_salary_currency?.currency_code
          : {
              label: "USD",
              code: 229,
            },
        job_type: profileData.job_type
          ? { code: profileData.job_type, label: profileData.job_type }
          : null,
        job_time: profileData.job_time
          ? { code: profileData.job_time, label: profileData.job_time }
          : null,
        additional_information: profileData?.additional_information,
      };
      if (
        this.candidateProfile &&
        this.candidateProfile.notice_period_days === null &&
        this.candidateProfile.candidate_type &&
        this.candidateProfile.candidate_type.candidate_type_id === 1
      ) {
        this.profile["notice_period_days"] = 90;
      }
      return this.profile;
    },
    async updateAvailabilityInfo() {
      this.$emit("tooltipHide");
      const isValid = await this.$refs.availableInfo.validate();
      this.errors = Object.values(this.$refs.availableInfo.errors).flat();
      if (!isValid) {
        this.cardHasError = this.errors[0] || "please check the fields!";
        return;
      }
      let availability_payload = {
        ...this.payload,
        candidate_uid: this.profile.candidate_uid,
        available: this.profile.available,
        available_from: this.profile.available_from,
        notice_period_days: this.profile.notice_period_days
          ? this.profile.notice_period_days
          : null,
        expected_join_date: this.profile.expected_join_date,
        salary_expectation_min: this.profile.salary_expectation_max
          ? this.profile.salary_expectation_min || null
          : null,
        salary_expectation_max: this.profile.salary_expectation_min
          ? this.profile.salary_expectation_max || null
          : null,
        salary_expectation_currency_id:
          this.profile.salary_expectation_min &&
          this.profile.salary_expectation_max
            ? this.profile?.salary_expectation_currency_id?.code
            : null,
        current_salary: /^\d+$/.test(this.profile.current_salary)
          ? this.profile.current_salary
          : null,
        current_salary_currency_id: this.profile.current_salary
          ? this.profile?.current_salary_currency_id?.code
          : null,
        job_type: this.profile.job_type?.code,
        job_time: this.profile.job_time?.code,
        additional_information: this.profile.additional_information,
      };
      delete availability_payload.preferred_regions;
      delete availability_payload.preferred_country_id;
      this.saveAvailabilityInfo(
        availability_payload,
      );
    },
    saveAvailabilityInfo(candidate_data) {
      const { customer_uid } = this.profile;
      let appendAction = [];
      appendAction = [
        ...appendAction,
        this.updateCandidate({ data: candidate_data, customer_uid }),
      ];
      Promise.all([appendAction]).then((res) => {
        setTimeout(() => {
        }, 500);
        this.clearFields();
        let el = this.$parent.$el.getElementsByClassName("border-top")[0];
        el.scrollIntoView({ iniline: "end" });
      });
    },
    clearFields() {
      this.$nextTick(() => {
        this.$refs.availableInfo.errors.clear;
        this.$refs.availableInfo.reset();
      });
      this.$emit("tooltipHide");
      this.multiFormEdited({ personalInformation: false });
      this.multiFormEdited({ showError: false });
      this.cardHasError = "";
    },
    resetAvailabilityData() {
      this.setAvailabilityData(this.candidateProfile);
    },
    onChange: debounce(function (value) {
      Vue.set(this.profile, "notice_period_days", value);
      Vue.set(this.payload, "notice_period_days", value);
      this.multiFormEdited({ personalInformation: true });
    }, 500),
  },
};
</script>
<style lang="scss">
.vs__search {
  padding-left: 0%;
  padding-right: 0%;
}
</style>
